import { CSSProperties } from 'react';
import { COPILOT_TYPOGRAPHY, COPILOT_COLORS, COPILOT_LAYOUT } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES, REDS } } = COPILOT_COLORS;
const { SPACING } = COPILOT_LAYOUT;

export const PIXEL_PICKER_STYLES: { [key: string]: CSSProperties } = {
  displayFlex: {
    display: 'flex',
  },
  modal: {
    borderRadius: SPACING[4],
  },
  closeIcon: {
    position: 'absolute',
    right: SPACING[16],
    top: SPACING[12],
    fontSize: 24,
    fontWeight: 'normal',
    cursor: 'pointer',
  },
  modalHeader: {
    ...COPILOT_TYPOGRAPHY.HEADING.H4,
    color: NEUTRALS.N800_MATTER,
    padding: '10px 24px',
    borderBottomColor: NEUTRALS.N200_LINEN,
  },
  modalContent: {
    padding: 0,
    position: 'relative',
  },
  loadingSegment: {
    minHeight: 500,
  },
  loaderStyle: {
    alignSelf: 'center',
  },
  configurationContainer: {
    padding: SPACING[24],
  },
  description: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
    margin: 0,
    marginBottom: SPACING[8],
  },
  search: {
    width: '100%',
    borderRadius: '500rem',
    color: NEUTRALS.N400_GRANITE,
    marginBottom: SPACING[16],
  },
  searchIcon: {
    pointerEvents: 'unset',
  },
  categorySwitcherContainer: {
    backgroundColor: NEUTRALS.N200_LINEN,
    padding: SPACING[4],
    borderRadius: SPACING[40],
    width: 300,
  },
  categoryBtnGroup: {
    borderRadius: SPACING[40],
    width: 292,
  },
  categoryBtn: {
    borderRadius: SPACING[32],
    color: NEUTRALS.N400_GRANITE,
    textTransform: 'initial',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    fontWeight: 600,
    width: '50%',
  },
  activeCategoryBtn: {
    backgroundColor: NEUTRALS.N0_WHITE,
    color: BLUES.B500_WAVE,
  },
  pixelTableContainer: {
    borderRadius: SPACING[4],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    margin: 0,
    marginTop: SPACING[8],
  },
  pixelTableHeader: {
    padding: '8px 12px',
    backgroundColor: NEUTRALS.N50_ALABASTER,
    fontWeight: 600,
    fontSize: SPACING[12],
    color: NEUTRALS.N800_MATTER,
    display: 'flex',
    justifyContent: 'space-between',
  },
  baseColumnStyles: {
    padding: 0,
    width: 'auto',
    display: 'flex',
  },
  pixelOptionsContainer: {
    padding: 0,
    margin: SPACING[12],
    width: '100%',
    minHeight: 300,
    maxHeight: 300,
    overflowY: 'scroll',
  },
  pixelCheckbox: {
    marginRight: SPACING[8],
  },
  pixelWeightsColumn: {
    padding: 0,
    width: 128,
    marginLeft: SPACING[8],
  },
  pixelDescription: {
    padding: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  dspPixelOptionsContainer: {
    position: 'relative',
  },
  dspHeader: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    fontSize: SPACING[12],
    fontWeight: 600,
    borderRadius: SPACING[4],
    padding: '4px 8px',
    height: 20,
    zIndex: 10,
  },
  dspAccordionIcon: {
    lineHeight: '14px',
    fontSize: 18,
  },
  dspDisplayName: {
    lineHeight: '15px',
  },
  pixelNameDivider: {
    color: NEUTRALS.N200_LINEN,
    margin: '0px 8px',
  },
  pixelOptionRow: {
    display: 'flex',
    padding: '10px 0px',
  },
  pixelRowError: {
    padding: 10,
  },
  noResultRow: {
    height: 300,
    width: 900,
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  noResultColumn: {
    width: 400,
    margin: 'auto',
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  pixelWeightInput: {
    width: 128,
    border: `.5px solid ${NEUTRALS.N200_LINEN}`,
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: `1px solid ${NEUTRALS.N200_LINEN}`,
    padding: '16px 24px',
  },
  errorMessage: {
    ...COPILOT_TYPOGRAPHY.BODY.SMALL,
    color: REDS.R500_WATERMELON,
  },
  cancelBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    width: 100,
    padding: '4px 0px',
    textTransform: 'capitalize',
    border: `1px solid ${NEUTRALS.N300_ALTO}`,
    borderRadius: SPACING[4],
    backgroundColor: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N800_MATTER,
  },
  confirmBtn: {
    ...COPILOT_TYPOGRAPHY.HEADING.H6,
    width: 100,
    padding: '4px 0px',
    textTransform: 'capitalize',
    borderRadius: SPACING[4],
    backgroundColor: BLUES.B500_WAVE,
    color: NEUTRALS.N0_WHITE,
    marginLeft: SPACING[24],
  },
};
