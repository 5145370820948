import React from 'react';
import { Button } from 'buildingBlocks';
import { ButtonClickHandler } from 'utils/types';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  onAdd: ButtonClickHandler,
  onRemove: ButtonClickHandler,
};

type Style = {
  marginTop: string,
};

const style: Style = { marginTop: '-10px' };

const AddRemoveLeafNames = ({ onAdd, onRemove }: Props) => (
  <div style={style} id="leaf-name-toggle">
    Leaf Names:&nbsp;
    <Button primary onClick={onAdd} style={strategyWizardStyles.nextButtonStyle}>
      Fill All
    </Button>&nbsp;
    <Button primary onClick={onRemove} style={strategyWizardStyles.nextButtonStyle}>
      Remove
    </Button>
  </div>
);

export default AddRemoveLeafNames;
