import MaxOrderedMap from 'utils/maxOrderedMap';
import {
  SUCCESSFULLY_PARSED_CSV_DATA,
  PARSE_CSV_DATA_FAILED,
  RESET_CSV_VALIDATION,
  MAX_SIZE,
  PARSE_JSON_FAILED,
  RESET_PROPS,
  INITIALIZE_EXISTING_STRATEGY,
  CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS,
  VALIDATION_ERROR,
  PageStatus,
  BidListStatus,
  PageStatusType,
  BidListStringObject,
  BidListErrorArray,
  NO_BID_LIST_GENERATED_COPY,
  NO_VC_BID_LIST_GENERATED_COPY,
  VolumeControlStatus,
  VALIDATE_BID_LISTS_VOLUME_CONTROL,
  GENERATE_BID_LISTS_VOLUME_CONTROL,
  RESET_BID_LISTS_VOLUME_CONTROL_STATUS,
  BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED,
} from './constants';

type ValidityObject = {
  pageStatus: PageStatusType,
  bidListErrors: BidListErrorArray,
  volumeControlErrors: BidListErrorArray,
};

export type TranslationErrorsByField = { [key: string]: string };

export type State = ValidityObject & {
  validationError: string | null,
  cachedCsvInputsAndRelatedBidLists: MaxOrderedMap<string, BidListStringObject>,
  cachedValidityValues: MaxOrderedMap<string, ValidityObject>,
  csvValidationError: string | null,
  csvValidationMsg: string | null,
  noBidListGeneratedWarning: string | null,
  noVcBidListGeneratedWarning: string | null,
  translationErrorsByField: TranslationErrorsByField,
  pageStatus: PageStatusType,
};

const NO_ERRORS = {
  bidListErrors: [],
  volumeControlErrors: [],
};

const DEFAULT_VALIDITY_VALUES = {
  ...NO_ERRORS,
  pageStatus: { bidListStatus: BidListStatus.NotYetValidated, volumeControlStatus: VolumeControlStatus.NotYetValidated },
};

export const INITIAL_STATE: State = {
  ...NO_ERRORS,
  validationError: null,
  cachedCsvInputsAndRelatedBidLists: new MaxOrderedMap(MAX_SIZE),
  cachedValidityValues: new MaxOrderedMap(MAX_SIZE),
  csvValidationError: null,
  csvValidationMsg: null,
  pageStatus: { bidListStatus: PageStatus.InitialPageStatus, volumeControlStatus: PageStatus.InitialPageStatus },
  noBidListGeneratedWarning: null,
  noVcBidListGeneratedWarning: null,
  translationErrorsByField: {},
};

const updateValidity = (state: State, bidListString: string, volumeControlBidListString: string): ValidityObject => {
  const concatenatedStrings = bidListString + volumeControlBidListString;

  return state.cachedValidityValues.get(concatenatedStrings) || DEFAULT_VALIDITY_VALUES;
};

export const reducer = (state = INITIAL_STATE, action): State => {
  switch (action.type) {
    case GENERATE_BID_LISTS_VOLUME_CONTROL: {
      return {
        ...state,
        pageStatus: { bidListStatus: PageStatus.BidListsGenerating, volumeControlStatus: PageStatus.VolumeControlGenerating },
        csvValidationError: null,
      };
    }
    case PARSE_JSON_FAILED: {
      const { bidListJsonParseError, volumeControlJsonParseError } = action.payload;
      const pageStatus = {
        bidListStatus: bidListJsonParseError ? BidListStatus.BidListsError : BidListStatus.BidListsAreValid,
        volumeControlStatus: volumeControlJsonParseError ? VolumeControlStatus.VolumeControlError : VolumeControlStatus.VolumeControlAreValid,
      };
      return {
        ...state,
        pageStatus,
        bidListErrors: bidListJsonParseError ? [bidListJsonParseError] : state.bidListErrors,
        volumeControlErrors: volumeControlJsonParseError ? [volumeControlJsonParseError] : state.volumeControlErrors,
      };
    }
    case VALIDATE_BID_LISTS_VOLUME_CONTROL: {
      const pageStatus = { bidListStatus: BidListStatus.BidListsValidating, volumeControlStatus: VolumeControlStatus.VolumeControlValidating };
      return {
        ...state,
        pageStatus,
        bidListErrors: INITIAL_STATE.bidListErrors,
        volumeControlErrors: INITIAL_STATE.volumeControlErrors,
      };
    }
    case RESET_BID_LISTS_VOLUME_CONTROL_STATUS: {
      const { CsvHasError, InitialPageStatus } = PageStatus;
      return {
        ...state,
        pageStatus: {
          bidListStatus: state.pageStatus.bidListStatus === CsvHasError ? CsvHasError : InitialPageStatus,
          volumeControlStatus: state.pageStatus.volumeControlStatus === CsvHasError ? CsvHasError : InitialPageStatus,
        },
        bidListErrors: INITIAL_STATE.bidListErrors,
        volumeControlErrors: INITIAL_STATE.volumeControlErrors,
        noBidListGeneratedWarning: INITIAL_STATE.noBidListGeneratedWarning,
        noVcBidListGeneratedWarning: INITIAL_STATE.noVcBidListGeneratedWarning,
      };
    }
    case CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS: {
      const { bidListString, volumeControlBidListString } = action.payload;
      const {
        pageStatus,
        bidListErrors,
        volumeControlErrors,
      } = updateValidity(state, bidListString, volumeControlBidListString);
      return {
        ...state,
        pageStatus,
        bidListErrors,
        volumeControlErrors,
        noBidListGeneratedWarning: INITIAL_STATE.noBidListGeneratedWarning,
        noVcBidListGeneratedWarning: INITIAL_STATE.noVcBidListGeneratedWarning,
      };
    }
    case BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED: {
      const {
        bidListString,
        volumeControlBidListString,
        pageStatus,
        bidListErrors,
        volumeControlBidListErrors: volumeControlErrors,
      } = action.payload;
      const concatenatedStrings = bidListString + volumeControlBidListString;
      return {
        ...state,
        pageStatus,
        bidListErrors,
        volumeControlErrors,
        cachedValidityValues: state.cachedValidityValues.set(
          concatenatedStrings,
          { pageStatus, bidListErrors, volumeControlErrors },
        ),
      };
    }
    case INITIALIZE_EXISTING_STRATEGY: {
      const { bidListString, volumeControlBidListString, trimmedCsvInput } = action.payload;
      const stringsToCache = { bidListString, volumeControlBidListString };
      const concatenatedStrings = bidListString + volumeControlBidListString;
      const validityDataToCache = {
        pageStatus: { bidListStatus: BidListStatus.BidListsAreValid, volumeControlStatus: VolumeControlStatus.VolumeControlAreValid },
        bidListErrors: [],
        volumeControlErrors: [],
      };
      return {
        ...state,
        pageStatus: { bidListStatus: BidListStatus.BidListsAreValid, volumeControlStatus: VolumeControlStatus.VolumeControlAreValid },
        cachedCsvInputsAndRelatedBidLists: state.cachedCsvInputsAndRelatedBidLists.set(trimmedCsvInput, stringsToCache),
        cachedValidityValues: state.cachedValidityValues.set(concatenatedStrings, validityDataToCache),
      };
    }
    case VALIDATION_ERROR: {
      return {
        ...state,
        pageStatus: { bidListStatus: BidListStatus.BidListsError, volumeControlStatus: VolumeControlStatus.VolumeControlError },
        bidListErrors: [action.payload.bidListValidationError],
        volumeControlErrors: [action.payload.bidListValidationError],
      };
    }
    case SUCCESSFULLY_PARSED_CSV_DATA: {
      const { trimmedCsvInput, bidListString, volumeControlBidListString, translationErrorsByField } = action.payload;
      const stringsToCache = { bidListString, volumeControlBidListString };
      return {
        ...state,
        pageStatus: { bidListStatus: BidListStatus.NotYetValidated, volumeControlStatus: VolumeControlStatus.NotYetValidated },
        cachedCsvInputsAndRelatedBidLists: state.cachedCsvInputsAndRelatedBidLists.set(trimmedCsvInput, stringsToCache),
        noBidListGeneratedWarning: bidListString ? NO_BID_LIST_GENERATED_COPY : null,
        noVcBidListGeneratedWarning: volumeControlBidListString ? NO_VC_BID_LIST_GENERATED_COPY : null,
        translationErrorsByField,
      };
    }
    case PARSE_CSV_DATA_FAILED: {
      return {
        ...state,
        csvValidationError: action.payload.csvValidationError,
        pageStatus: { bidListStatus: PageStatus.CsvHasError, volumeControlStatus: PageStatus.CsvHasError },
      };
    }
    case RESET_CSV_VALIDATION: {
      return {
        ...state,
        csvValidationError: null,
      };
    }
    case RESET_PROPS:
      return {
        ...INITIAL_STATE,
        cachedCsvInputsAndRelatedBidLists: state.cachedCsvInputsAndRelatedBidLists.clear(),
        cachedValidityValues: state.cachedValidityValues.clear(),
      };
    default:
      return state;
  }
};
