/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Button, Grid } from 'buildingBlocks';
import { DEFAULT_MIN_ALLOCATION, DEFAULT_MAX_ALLOCATION, DEFAULT_GROUP_CONFIG, DEFAULT_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationResponse, BudgetGroup, GroupSettings as GroupSettingsType, TooltipsDescriptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { isCrossPlatformStrategyType } from 'containers/StrategyWizard/utils';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { useAsyncEffect } from 'utils/functionHelpers';
import BudgetGroups from './BudgetGroups';
import { getFlightStates, getAllNonAttachedLineItems, getBudgetParentData, filterForValidFlights, useBudgetGroupOptionsFetcher, getAttachedLineItems, getAllBudgetGroupLineItems } from './utils';
import { BUDGET_MANAGEMENT_STYLES } from './style';

const { budgetGroup } = BUDGET_MANAGEMENT_STYLES;

type GroupSettingsProps = {
  budgetAllocationState: BudgetAllocationResponse
  tooltips: TooltipsDescriptions
  hasChildFlightsData: boolean
};

const GroupSettings = ({
  budgetAllocationState,
  tooltips,
  hasChildFlightsData,
}: GroupSettingsProps) => {
  const {
    attachFlightsStep,
    goalSelectionStep,
    strategyTypeSelectionStep: {
      strategyType: {
        id: strategyTypeId,
      },
    },
    strategyConfigurationStep,
    strategyConfirmationStep,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const { setValue, trigger } = useFormContext<StrategyConfigurationStep>();
  const formValues = _.get(strategyConfigurationStep, 'groupSettings', {});
  const budgetGroups = useWatch({ name: 'groupSettings', defaultValue: formValues }) as GroupSettingsType;
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });

  let updatedBudgetGroupsState = { ...budgetGroups };
  const budgetData = _.get(budgetAllocationState, 'data');
  const attachedFlights = _.get(attachFlightsStep, 'attachedFlights');
  const flightStates = getFlightStates(attachFlightsStep);
  const { validFlights: flightsToPopulate, invalidFlights } = flightStates;
  // groupedOptions to render FormOptions grouped by DSP
  const {
    groupedOptions,
    allActiveChildOptions,
  } = useBudgetGroupOptionsFetcher(budgetData, flightsToPopulate, flightStates);

  const isCrossPlatformOptimization = isCrossPlatformStrategyType(strategyTypeId);
  const defaultCurrency = _.get(attachFlightsStep, 'defaultCurrency');
  const {
    lifeTimeBudget,
    requiredDailyValue,
    interval,
    remainingSpendDays,
    flightExtType,
    flightTimezone,
  } = getBudgetParentData(isCrossPlatformOptimization, goalSelectionStep, budgetData, attachedFlights);

  const filteredGroupSettings = _.omit(updatedBudgetGroupsState, DEFAULT_GROUP_ID);
  const budget = _.get(goalSelectionStep, 'budget');

  const attachedLineItemsCount = _.reduce(filteredGroupSettings, (sum: number, group: BudgetGroup) => {
    // using intersection to handle possible clones for singleDSP flow
    // eslint-disable-next-line no-param-reassign
    sum += _.size(_.intersection(group.childExtIds, _.map(allActiveChildOptions, 'extId')));
    return sum;
  }, 0);

  const nonAttachedLineItemsCount = _.size(allActiveChildOptions) - attachedLineItemsCount >= 0
    ? _.size(allActiveChildOptions) - attachedLineItemsCount
    : 0;

  const addBudgetGroup = () => {
    // handles edge case of when user goes to step 5 and goes back to step 4 to add/remove budgetGroups
    const budgetGroupId = _.size(filteredGroupSettings)
    // eslint-disable-next-line newline-per-chained-call
      ? _.chain(filteredGroupSettings).keys().last().toNumber().value() + 1
      : _.size(filteredGroupSettings) + 1;
    const newBudgetGroup = {
      groupName: `Untitled Group ${budgetGroupId}`,
      min: DEFAULT_MIN_ALLOCATION,
      max: DEFAULT_MAX_ALLOCATION,
      childExtIds: [],
    };
    setValue('groupSettings', { ...budgetGroups, [budgetGroupId]: { ...newBudgetGroup } });
  };

  useAsyncEffect(async () => {
    // remove invalid flights on initial load
    const cleanUpForm = async () => {
      const budgetGroupsClone = { ...updatedBudgetGroupsState };
      const nonAttachedLineItemExtIds = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);
      _.forEach(budgetGroupsClone, (group: BudgetGroup, groupId: string) => {
        const validFlights = groupId === DEFAULT_GROUP_ID
          ? nonAttachedLineItemExtIds
          : filterForValidFlights(group.childExtIds, invalidFlights, allActiveChildOptions);
        group.childExtIds = validFlights;
        // re/calculate aggDelivery if it doesn't exist initially, to turn event pacing aggDelivery to currency if revType enabled,
        // or include clone spend if intelligentChildObjects is enabled
        if ((!group.aggDelivery || !!_.size(budget) || intelligentChildObjectsEnabled) && groupId !== DEFAULT_GROUP_ID) {
          const groupLineItems = getAttachedLineItems(group.childExtIds, allActiveChildOptions);
          const groupLIsToCalculateDelivery = intelligentChildObjectsEnabled
            ? getAllBudgetGroupLineItems(attachedFlights, groupLineItems, budgetData)
            : groupLineItems;
          const deliveryObj = getTotalDelivered(budgetData, [interval], budget, groupLIsToCalculateDelivery);
          const delivery = _.head(_.values(deliveryObj)) ?? 0;
          group.aggDelivery = delivery;
        }
      });

      const newGroupSettings = _.pickBy(budgetGroupsClone, (group) => _.size(group.childExtIds));
      // update form with defaultGroup when user doesn't create a budget group and syncs defaultGroup when flights change
      const updatedGroupSettings = {
        ...newGroupSettings,
        ...(_.size(nonAttachedLineItemExtIds) && {
          defaultGroup: {
            ...DEFAULT_GROUP_CONFIG,
            childExtIds: nonAttachedLineItemExtIds,
          },
        }),
      };
      // handle issue with budgetGroups not being in sync with updatedGroupSettings
      updatedBudgetGroupsState = updatedGroupSettings;
      await setValue('groupSettings', updatedGroupSettings);
    };
    await cleanUpForm();
  }, [budgetAllocationState]);

  useEffect(() => {
    if (nonAttachedLineItemsCount) {
      // creates defaultGroup as long as there are line items that haven't been attached to a budget group
      const nonAttachedExtIds = getAllNonAttachedLineItems(filteredGroupSettings, allActiveChildOptions);
      const updatedGroupSettings = {
        ...updatedBudgetGroupsState,
        defaultGroup: { ...DEFAULT_GROUP_CONFIG, childExtIds: nonAttachedExtIds },
      };
      setValue('groupSettings', updatedGroupSettings);
    } else {
      // removes defaultGroup when there are NO stray line items
      setValue('groupSettings', _.omit(updatedBudgetGroupsState, DEFAULT_GROUP_ID));
    }
  }, [nonAttachedLineItemsCount, attachFlightsStep]);

  useEffect(() => {
    // handles validation for when user removes budget group
    trigger('groupSettings');
  }, [budgetGroups]);

  return (
    <Grid>
      <BudgetGroups
        groupedOptions={groupedOptions}
        lifeTimeBudget={lifeTimeBudget}
        requiredDailyValue={requiredDailyValue}
        tooltips={tooltips}
        nonAttachedLineItemsCount={nonAttachedLineItemsCount}
        interval={interval}
        strategyName={strategyConfirmationStep?.name}
        isCrossPlatformOptimization={isCrossPlatformOptimization}
        defaultCurrency={defaultCurrency}
        remainingSpendDays={remainingSpendDays}
        allActiveChildOptions={allActiveChildOptions}
        budgetGroups={budgetGroups}
        flightExtType={flightExtType}
        flightTimezone={flightTimezone}
      />
      <Grid.Row>
        <Grid.Column width={12}>
          {(!hasChildFlightsData)
            ? (<ErrorMessage errorText="No Active Line Items" />)
            : (
              <Button
                primary
                content="Add Budget Allocation Group"
                icon="plus"
                onClick={addBudgetGroup}
                style={budgetGroup.budgetAllocationBtn}
              />
            )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default GroupSettings;
