import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'buildingBlocks';
import { createLinkWithQS, isNumeric } from 'utils/functionHelpers';
import { STRATEGY_WIZARD_BUTTON_TAG } from 'cssTagConstants';
import strategyWizardStyles from '../styles';

type Props = {
  onNextClick: () => void
  showBackButton?: boolean
  backButtonId?: number
  backButtonDisabled?: boolean
  showNextButton?: boolean
  nextButtonDisabled?: boolean
  strategyId?: number
  nextButtonText?: string
  backButtonText?: string
  style?: {}
};

const NavFooter = ({
  onNextClick,
  showBackButton = true,
  backButtonId,
  backButtonDisabled,
  showNextButton = true,
  nextButtonDisabled,
  strategyId,
  nextButtonText = 'Next',
  backButtonText = 'Back',
  style = {},
}: Props) => {
  const prefixLink = `/strategies/wizard${isNumeric(strategyId) ? `/${strategyId}` : ''}`;
  return (
    <div style={{ ...strategyWizardStyles.navButtonContainerStyle, ...style }}>
      { showBackButton && (
        <Link to={createLinkWithQS(`${prefixLink}/${backButtonId}`)}>
          <Icon size="big" name="chevron left" style={strategyWizardStyles.backArrowStyle} />
          <Button
            type="submit"
            style={strategyWizardStyles.backButtonStyle}
            id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.backButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.backButtonForNewStrategy}
            disabled={backButtonDisabled}
            className="wizardNavBack"
          >
            {backButtonText}
          </Button>
        </Link>
      )}
      { showNextButton && (
        <Button
          primary
          type="submit"
          id={strategyId ? STRATEGY_WIZARD_BUTTON_TAG.nextButtonForEditStrategy : STRATEGY_WIZARD_BUTTON_TAG.nextButtonForNewStrategy}
          disabled={nextButtonDisabled}
          onClick={onNextClick}
          style={nextButtonDisabled ? { ...strategyWizardStyles.nextButtonStyle, pointerEvents: 'none' } : strategyWizardStyles.nextButtonStyle}
        >
          {nextButtonText}
        </Button>
      )}
    </div>
  );
};

export default NavFooter;
