import _ from 'lodash';
import {
  applyAllValidators, isGreater, crossValidate, required, isLess,
  percentageValidation, nonZeroPercentageValidation, isGreaterOrEqual,
} from 'utils/reactHookFormValidators';
import {
  ALL_MIN_VALUES_EXCEEDED_COPY,
  MIN_AGGREGATE_LIMIT,
  MAX_AGGREGATE_LIMIT,
  ALL_MAX_VALUES_EXCEEDED_COPY,
  SINGLE_BUDGET_GROUP_MAX_EXCEEDED_COPY,
  SINGLE_BUDGET_GROUP_MIN_EXCEEDED_COPY,
} from './constants';
import { BudgetGroup, GroupSettings, MinMaxType } from './types';

const minMaxArray = (values: Array<MinMaxType>, minOrMax: string): Array<number> => (
  _.map(values, (input) => {
    const v = _.values(input);
    return _.toNumber(v[0][minOrMax]);
  })
);

const getMinMaxValues = (input: Array<MinMaxType>): { [key: string]: Array<number> } => {
  const minValues = minMaxArray(input, 'min');
  const maxValues = minMaxArray(input, 'max');
  return { minValues, maxValues };
};

const invalidValues = (fn: (x: number) => boolean, xs: Array<number>) => (!_.some(xs, isNaN) && fn(_.sum(xs)));
const invalidMinValues = _.partial(invalidValues, (x) => x > MIN_AGGREGATE_LIMIT);
const invalidMaxValues = _.partial(invalidValues, (x) => x < MAX_AGGREGATE_LIMIT);
const invalidGroupSettingsMaxValues = _.partial(invalidValues, (x) => x < MAX_AGGREGATE_LIMIT);

const getGroupSettingsAggregateErrorValidation = (values: Array<MinMaxType>, groupSettings: GroupSettings) => {
  const singleBudgetGroup = _.size(groupSettings) === 1;

  const hasFlightsAttached = _.some(groupSettings, (setting) => !_.isEmpty(_.get(setting, 'childExtIds')));

  if (values && values.length > 0) {
    const { minValues, maxValues } = getMinMaxValues(values);
    if (singleBudgetGroup && invalidMinValues(minValues)) {
      return SINGLE_BUDGET_GROUP_MIN_EXCEEDED_COPY;
    }
    // if we only have a single groupSetting, max aggregate limit is 100
    if (singleBudgetGroup && invalidMaxValues(maxValues) && hasFlightsAttached) {
      return SINGLE_BUDGET_GROUP_MAX_EXCEEDED_COPY;
    }
    if (invalidMinValues(minValues)) {
      return ALL_MIN_VALUES_EXCEEDED_COPY;
    }
    // multiple budgetGroups, max aggregate limit is 120
    if (!singleBudgetGroup && invalidGroupSettingsMaxValues(maxValues)) {
      return ALL_MAX_VALUES_EXCEEDED_COPY;
    }
  }
  return null;
};

const minMaxAggregateValidation = (formValues, fieldName: string) => {
  const groupSettings = formValues[fieldName];
  const allocationRanges = _.flatMap(_.mapValues(groupSettings, ({ min, max }: BudgetGroup, groupKey: string) => ({ [groupKey]: { min, max } })));
  const error = getGroupSettingsAggregateErrorValidation(allocationRanges, groupSettings);
  return error ? { [fieldName]: { minMaxAggregateValidation: { message: error } } } : {};
};

export const viewabilityValidation = (values: { viewability: { enabled: boolean, target?: string | number } }, fieldName: string) => {
  if (_.get(values, 'viewability.enabled')) {
    return nonZeroPercentageValidation(values, fieldName);
  }
  return {};
};

const checkChildExtIds = (values: {}, fieldName: string) => {
  const childExtIds = values[fieldName];
  if (!childExtIds || childExtIds.length === 0) {
    return { [fieldName]: { message: 'Please Attach Objects' } };
  }

  return {};
};

const groupSettingsValidation = (validator) => (formValues, fieldName: string) => {
  const groupSettings = _.get(formValues, fieldName);
  const errors = _.reduce(groupSettings, (acc, group, groupKey) => {
    const groupErrors = applyAllValidators(group, validator);
    if (_.size(groupErrors)) {
      acc[groupKey] = { ...groupErrors };
    }
    return acc;
  }, {});

  return _.size(errors) ? { [fieldName]: errors } : {};
};

const nameValidation = (formValues, fieldName: string) => {
  const groupSettingsName = _.get(formValues, fieldName);
  if (!_.size(groupSettingsName)) {
    return { [fieldName]: { message: 'Required' } };
  }
  if (_.size(groupSettingsName) >= 255) {
    return { [fieldName]: { message: 'Name has a maximum of 255 characters' } };
  }
  if (!groupSettingsName.match(/\w/)) {
    return { [fieldName]: { message: 'Name must contain at least one character' } };
  }
  return {};
};

export const validate = (values) => {
  const groupSettingsValidators = {
    groupName: [nameValidation],
    min: [isGreaterOrEqual(0), percentageValidation, crossValidate(isLess, 'max'), required],
    max: [isGreater(0), percentageValidation, crossValidate(isGreater, 'min'), required],
    childExtIds: [checkChildExtIds],
  };

  const validators = {
    'viewability.target': [viewabilityValidation],
    // addresses issue with form modifying groupSettings to an array before form loads
    // only want to validate groupSettings when it is NOT an array
    ...(!_.isArray(_.get(values, 'groupSettings')) && {
      groupSettings: [groupSettingsValidation(groupSettingsValidators), minMaxAggregateValidation],
    }),
  };

  return { values, errors: applyAllValidators(values, validators) };
};

export default validate;
