import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Grid, Header, RHFPercentageInput } from 'buildingBlocks';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';
import Toggle from 'components/Toggle';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import Tooltip from 'containers/Tooltip';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';
import strategyWizardStyles from '../styles';

const { column, row } = strategyWizardStyles;
const { NEW_DESIGN_SYSTEM: { NEUTRALS } } = COPILOT_COLORS;
const { budgetParent } = BUDGET_MANAGEMENT_STYLES;
const ValidatedPctInput = validatedFieldWrapper(RHFPercentageInput);

export const ViewabilityToggle = () => {
  const { control, setValue } = useFormContext<StrategyConfigurationStep>();
  const viewability = useSelector<GlobalState>((rootState) => rootState.strategyWizard.strategyConfigurationStep?.viewability);
  const viewabilityTarget = _.get(viewability, 'target');

  return (
    <Controller
      name="viewability.enabled"
      control={control}
      render={({ field: { value, onChange } }) => (
        <Toggle
          checked={value}
          onChange={(v) => {
            onChange(v);
            if (!v) {
              setValue('viewability', { enabled: v, target: viewabilityTarget });
            }
          }}
        />
      )}
    />
  );
};

const Viewability = () => {
  const viewabilityEnabled = useWatch<StrategyConfigurationStep>({ name: 'viewability.enabled' });
  const viewabilityTarget = useWatch<StrategyConfigurationStep>({ name: 'viewability.target' });
  const { control, setError, clearErrors } = useFormContext();
  const toolTipContent = 'Copilot will automatically adjust which inventory is bought to meet the viewability target.';

  useEffect(() => {
    if (viewabilityEnabled) {
      if (!viewabilityTarget) {
        setError('viewability.target', { message: 'Viewability is required' });
      }
    } else {
      clearErrors('viewability.target');
    }
  }, [viewabilityEnabled, viewabilityTarget, setError, clearErrors]);

  return (
    <Grid.Row style={row}>
      <Grid.Column style={column} width={12}>
        <div style={{ display: 'flex', justifyContent: 'space-between', color: NEUTRALS.N600_ABBEY }}>
          <div style={{ display: 'flex' }}>
            <Header
              as="h5"
              style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, marginBottom: 0, color: NEUTRALS.N600_ABBEY }}
              content="Viewability"
            />
            <Tooltip
              content={toolTipContent}
              tooltipstyling={budgetParent.toolTip}
            />
          </div>
          <ViewabilityToggle />
        </div>
        <div style={{ ...COPILOT_TYPOGRAPHY.BODY.SMALL, color: NEUTRALS.N600_ABBEY }}>
          <span>Set the viewability goal for Copilot to achieve.</span>
        </div>
        {viewabilityEnabled && (
          <div style={{ marginTop: COPILOT_LAYOUT.SPACING[8] }}>
            <Controller
              name="viewability.target"
              control={control}
              render={({ field, fieldState }) => (
                <ValidatedPctInput
                  type="number"
                  onChange={field.onChange}
                  style={{ width: 200 }}
                  field={field}
                  fieldState={fieldState}
                />
              )}
            />
          </div>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

export default Viewability;
