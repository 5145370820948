/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Grid } from 'buildingBlocks';
import { BudgetSetting, StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { DEFAULT_GROUP_ID } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import {
  BudgetGroupOptions,
  ChildOptions,
  BudgetGroup as BudgetGroupType,
  TooltipsDescriptions,
  BudgetInterval,
  BudgetGroupLineItemMapping,
  GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import { displayCurrencyOrImps } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { Currency } from 'utils/types';
import BudgetGroup from './BudgetGroup/BudgetGroup';
import BudgetBanner from './BudgetGroup/BudgetBanner';
import BudgetParentObject from './BudgetParent/BudgetParentObject';
import { getAttachedLineItems } from './utils';
import { BUDGET_MANAGEMENT_STYLES } from './style';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupsProps = {
  groupedOptions: BudgetGroupOptions
  lifeTimeBudget: number
  requiredDailyValue: number
  tooltips: TooltipsDescriptions
  nonAttachedLineItemsCount: number
  interval: BudgetInterval | BudgetSetting
  strategyName: string
  isCrossPlatformOptimization: boolean
  defaultCurrency: Currency
  remainingSpendDays: number
  allActiveChildOptions: ChildOptions
  budgetGroups: GroupSettings
  flightExtType: number
  flightTimezone: string
};

const BudgetGroups = ({
  groupedOptions,
  lifeTimeBudget,
  requiredDailyValue,
  tooltips,
  nonAttachedLineItemsCount,
  interval,
  strategyName,
  isCrossPlatformOptimization,
  defaultCurrency,
  remainingSpendDays,
  allActiveChildOptions,
  budgetGroups,
  flightExtType,
  flightTimezone,
}: BudgetGroupsProps) => {
  const [budgetGroupToLineItems, setBudgetGroupToLineItems] = useState<BudgetGroupLineItemMapping>({});

  const strategyConfigurationStep = useSelector<GlobalState>((state) => state.strategyWizard.strategyConfigurationStep) as StrategyConfigurationStep;
  const reduxGroupSettings = _.get(strategyConfigurationStep, 'groupSettings');
  const { formState: { errors } } = useFormContext<StrategyConfigurationStep>();

  const filteredGroupSettings = _.omit(budgetGroups, DEFAULT_GROUP_ID);
  const generalAllocationRangeError = _.get(errors, 'groupSettings.minMaxAggregateValidation.message');
  const budgetMetric = isCrossPlatformOptimization
    ? defaultCurrency.code
    : displayCurrencyOrImps((interval as BudgetInterval));

  useEffect(() => {
    // only want this running in edit flow to populate previous budgetGroupToLineItem mapping
    if (_.size(allActiveChildOptions) && _.size(reduxGroupSettings)) {
      const prevBudgetGroup = {};
      _.forEach(filteredGroupSettings, (budgetGroup: BudgetGroupType, groupKey: string) => {
        const groupsAttachedLineItem = getAttachedLineItems(budgetGroup.childExtIds, allActiveChildOptions);
        prevBudgetGroup[groupKey] = groupsAttachedLineItem;
      });
      setBudgetGroupToLineItems(prevBudgetGroup);
    }
  }, [budgetGroups, allActiveChildOptions, reduxGroupSettings]);

  return (
    <>
      <BudgetParentObject
        interval={interval}
        tooltips={tooltips}
        strategyName={strategyName}
        lifeTimeBudget={lifeTimeBudget}
        requiredDailyValue={requiredDailyValue}
        budgetMetric={budgetMetric}
        flightExtType={flightExtType}
        flightTimezone={flightTimezone}
      />
      {!!_.size(filteredGroupSettings) && (
        <>
          <Grid.Row>
            <Grid.Column width={12} style={budgetGroupStyle.budgetGroupsContainer}>
              {_.map(filteredGroupSettings, (budgetGroup: BudgetGroupType, key: string) => (
                <BudgetGroup
                  key={key}
                  groupedOptions={groupedOptions}
                  lifeTimeBudget={lifeTimeBudget}
                  budgetGroup={budgetGroup}
                  budgetGroupKey={key}
                  budgetGroupToLineItems={budgetGroupToLineItems}
                  setBudgetGroupToLineItems={setBudgetGroupToLineItems}
                  requiredDailyValue={requiredDailyValue}
                  budgetMetric={budgetMetric}
                  generalAllocationRangeError={generalAllocationRangeError}
                  remainingSpendDays={remainingSpendDays}
                  interval={interval}
                  allActiveChildOptions={allActiveChildOptions}
                  budgetGroups={budgetGroups}
                />
              ))}
            </Grid.Column>
          </Grid.Row>
          {generalAllocationRangeError && (
            <Grid.Row>
              <Grid.Column width={12}>
                <ErrorMessage errorText={generalAllocationRangeError} />
              </Grid.Column>
            </Grid.Row>
          )}
          {!!nonAttachedLineItemsCount && (
            <BudgetBanner nonAttachedLineItemsCount={nonAttachedLineItemsCount} />
          )}
        </>
      )}
    </>
  );
};

export default BudgetGroups;
