import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { STRATEGY_TYPE, DSP, apiEnums, RESULTS_LIMIT, GOAL_TYPES, CROSS_PLATFORM_ROAS_GOAL_NAME } from 'constantsBase';
import { getActiveStrategyGoalsInPriorityOrder } from 'utils';
import { isEngScoreGoalType, mapConfigFromDbToUi, transformBudgetSettingsDB } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { getInitialValuesByStrategyType } from 'containers/StrategyWizard/steps/StrategyConfiguration/utils';
import {
  CurrencyExchange,
  StratDefaultWizardConfig,
  Strategy,
  Currency,
  Member,
  FlightCandidate,
  StrategyRecommendation,
  ViewFlight,
  Advertiser,
  StrategyType,
  AMZNOrder,
  Brand,
  AdvertiserBrand,
  StrategyRevenueType,
  Goal,
  GoalWeight,
} from 'utils/copilotAPI';
import { toCamelCase } from 'utils/formattingUtils';
import { Advertiser as AdvertiserType, assertUnreachable, StrategyType as STType, Member as MemberType, Flight, StrategyGoalDB, GoalType } from 'utils/types';
import useFetcher, { State as FetcherState, PossibleStates } from 'utils/hooks/useFetcher';
import { strategyPopulateCols, INITIAL_WIZARD_VALUES_FOR_UI, DisabledStrategyTypeReasons } from './constants';
import { AttachFlightsInfoType, BudgetSettingDB, FormulaType, MetricConfig, StrategyConfigurationStep, WizardFormGoalSelection, WizardFormValues } from './types';
import {
  fetchBudgetAllocationState, formatPixelGoalWeightsForUi, formatMetricsConfigFromDbToUi, getDspByMemberOrFlights,
  getOptimizationLevelForStrat, getOptLevelByFlightExtType, getSelectedOptType, mapRevTypeConfigFromDbToUi, formatGoalFromDbToUi, hasExternalCustomValueMetric,
} from './utils';
import { checkEligibility } from './steps/AttachFlights/actions';
import { DspCodeToPixel } from './components/PixelPicker/types';
import { convertQSPixelsToForm } from './components/PixelPicker/utils';
import { BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES } from './ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData } from './ConfigurationByStrategyType/BudgetOptimization/types';
import { configuringCrossPlatformStratCheck } from './steps/AttachFlights/utils';

const crossPlatformOptStratTypeId = STRATEGY_TYPE.crossPlatformOptimization.id;

export enum WizardMode {
  newStrategy = 'newStrategy',
  existingStrategy = 'existingStrategy',
  formPreviouslyFilled = 'formPreviouslyFilled',
}
export enum FormLoadingState {
  hasData = 'hasData',
  initial = 'initial',
  loading = 'loading',
}

export type ModuleInitStates =
  | { kind: FormLoadingState.initial }
  | { kind: FormLoadingState.loading }
  | { kind: FormLoadingState.hasData; data: any };

export const useInitialDataFetcher = (
  mode: WizardMode,
  member: MemberType,
  advertiser: AdvertiserType,
  attachedFlights: Array<Flight>,
  strategyType: STType,
  config: any,
  moduleFormValues: StrategyConfigurationStep,
  currencyCode: string,
  goalType: string,
  populateSegments: boolean,
  hasMultiRevType: boolean,
  goalSelectionStep: WizardFormGoalSelection,
) => {
  const [currentState, setCurrentState] = useState<ModuleInitStates>({
    kind: FormLoadingState.initial,
  });
  // hack: fix for react memory leak error
  const isMounted = useRef<boolean>(false);

  const fetchCurrencyExchange = async () => {
    if (!currencyCode) {
      return {};
    }
    const sort = 'date DESC';
    const limit = 1;
    try {
      const res = await CurrencyExchange.get({ code: currencyCode, sort, limit });
      const exchanges = _(res.data)
        .map((exchange) => _.mapKeys(exchange, (_v, k) => toCamelCase(k)))
        .keyBy('code')
        .value();

      return exchanges;
    } catch (e) {
      console.log(`could not fetch currencyExchange because: ${e}`);
      return {};
    }
  };

  const strategyTypeId = _.get(strategyType, 'id');
  const fetchStratDefaultWizardConfig = async () => {
    const params = { member: _.get(member, 'id'), strategyType: strategyTypeId };
    if (params.strategyType === crossPlatformOptStratTypeId || !params.member) {
      return {};
    }
    try {
      const stratConfigOverrides = await StratDefaultWizardConfig.getConfig(params);
      return stratConfigOverrides.data;
    } catch (e) {
      console.log(`could not fetch stratDefaultWizardConfig because: ${e}`);
      return {};
    }
  };

  const getInitData = async () => {
    setCurrentState({ kind: FormLoadingState.loading });
    const [currencyExchange, stratDefaultWizardConfig] = await Promise.all([
      fetchCurrencyExchange(),
      fetchStratDefaultWizardConfig(),
    ]);

    const basicInfo = { advertiser, member, attachedFlights };
    const data = await getInitialValuesByStrategyType(
      strategyTypeId,
      basicInfo,
      currencyExchange,
      stratDefaultWizardConfig,
      populateSegments,
    );
    return data;
  };

  // when loading strategy via QS the mode will default to newStrategy until redux populates
  useEffect(() => {
    isMounted.current = true;
    const initializeFormValues = async () => {
      const defaultValues = await getInitData();
      const data = (mode === WizardMode.newStrategy)
        ? defaultValues
        : { ...defaultValues, ...moduleFormValues };
      const hasExternalCustomValue = hasExternalCustomValueMetric(goalType, goalSelectionStep);
      if (hasExternalCustomValue || _.isEqual(strategyTypeId, STRATEGY_TYPE.amznBudgetOptimization.id) || hasMultiRevType) {
        _.set(data, 'intelligentChildObjects', false);
      }
      // logic for setting intelligentChildObj here bc step 4 form rerenders multiple times due to useEffect for initialValues
      if (_.isEqual(strategyTypeId, STRATEGY_TYPE.crossPlatformOptimization.id) && !hasMultiRevType && !hasExternalCustomValue) {
        const onlyAMZNFlights = _.every(attachedFlights, ['dsp', DSP.AMZN.id]);
        const intelligentChildObj = _.get(moduleFormValues, 'prevSetIntelChildObj') ? (!onlyAMZNFlights && _.get(moduleFormValues, 'intelligentChildObjects')) : !onlyAMZNFlights;
        _.set(data, 'intelligentChildObjects', intelligentChildObj);
      }
      if (isMounted.current) {
        setCurrentState({ kind: FormLoadingState.hasData, data });
      }
    };

    switch (mode) {
      case WizardMode.newStrategy:
      case WizardMode.formPreviouslyFilled:
        initializeFormValues();
        break;
      case WizardMode.existingStrategy:
        setCurrentState({ kind: FormLoadingState.hasData, data: config });
        break;
      default:
        assertUnreachable(mode);
    }
    // cleanup function
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, member]);

  return currentState;
};

export enum StrategyWizardStates {
  newStrategy = 'newStrategy',
  unauthorized = 'unauthorized',
  initFromQs = 'initFromQs',
}

export type StrategyWizardResponse =
  | FetcherState
  | { kind: StrategyWizardStates.newStrategy }
  | { kind: StrategyWizardStates.unauthorized }
  | { kind: StrategyWizardStates.initFromQs; data: WizardFormValues };

export type QSParams = {
  wizardForm: {
    name?: string
    member?: string
    advertiser?: string
    optimizationLevel?: string
    flightExtIds?: Array<string>
    goalType?: string
    goalTarget?: string
    revType?: string
    revValue?: string
    strategyType?: string
    impValueFilters?: DspCodeToPixel
  }
  moduleForm?: StrategyConfigurationStep
  populateSegments?: boolean
};

// if the query string has null values (?a=&b=3) or (?a&b=3) the value 'a' gets parsed to empty string ie { a: '', b: 3}
// this removes any values that may have gotten passed as empty.
const removeEmptyQsValues = (qsParams: QSParams) => _.omitBy(
  {
    ...qsParams.wizardForm,
    flightExtIds: _.filter(qsParams.wizardForm.flightExtIds, _.negate(_.isEmpty)),
    moduleForm: _.omitBy({ ...qsParams.moduleForm }, _.isNil),
  },
  _.isEmpty,
);

// make appropriate api calls to get the full objects from the DB based on query string IDs, which will be used to
// initialize the form
const getPopulatedValuesFromQueryString = async (
  filteredQsValues: any,
  attachFlightsInfo: AttachFlightsInfoType,
  setAttachFlightsInfo: (x: any) => void,
  customGoal: Partial<FormulaType>,
  metricsConfig: MetricConfig,
) => {
  const {
    advertiser: advertiserExtId,
    flightExtIds,
    strategyType: strategyTypeId,
    member: memberExtId,
    brand: queryStrBrandId,
    name,
    optimizationLevel,
    goalType,
    goalTarget,
    budgetSettings,
    revType,
    revValue,
    impValueFilters,
    moduleForm,
  } = filteredQsValues;
  // @ts-ignore
  const populatedValues: _.PartialDeep<WizardFormValues> = {};
  // load advertiser and populate member. if we have no advertiser but we have a member, fetch the member
  if (advertiserExtId) {
    const advertiserRes = await Advertiser.get({
      externalId: advertiserExtId,
      populate: [apiEnums.defaultCurrency, apiEnums.member],
    });
    const advertiser = advertiserRes.data[0];
    populatedValues.attachFlightsStep = {
      advertiser,
      member: advertiser.member,
    };
  } else if (memberExtId) {
    const memberRes = await Member.get({ externalId: memberExtId });
    populatedValues.attachFlightsStep = {
      member: _.head(memberRes.data),
    };
  }
  // set the brand if it is available
  if (queryStrBrandId) {
    const brandRes = await Brand.get({ id: queryStrBrandId, populate: 'advertisers', limit: 1, isDeleted: false });
    populatedValues.attachFlightsStep = {
      ...populatedValues.attachFlightsStep,
      brand: _.head(brandRes.data),
    };
  }
  // set the brand if there is no brand and the advertiser is tied to one
  if (!queryStrBrandId && populatedValues.attachFlightsStep.advertiser) {
    const brandsArrRes = await AdvertiserBrand.brandsForAdvertiser({ advertiserId: populatedValues.attachFlightsStep.advertiser.id });
    const brandId = _.head(brandsArrRes.data);
    if (brandId) {
      const brandRes = await Brand.get({ id: brandId, populate: 'advertisers', limit: 1, isDeleted: false });
      populatedValues.attachFlightsStep.brand = _.head(brandRes.data);
    }
  }

  populatedValues.attachFlightsStep.optimizationLevel = getOptLevelByFlightExtType(
    populatedValues.attachFlightsStep.member ? +populatedValues.attachFlightsStep.member.dsp : DSP.MULTIPLE.id,
    +optimizationLevel,
  );

  const selectedOptType = _.get(filteredQsValues, 'selectedOptType', null);
  const isCrossPlatform = configuringCrossPlatformStratCheck(selectedOptType);

  // set the selected opt type
  populatedValues.attachFlightsStep.selectedOptType = selectedOptType;

  // load flight candidates
  if (flightExtIds) {
    const memberId = _.get(populatedValues.attachFlightsStep, 'member.id');
    const advertiserId = _.get(populatedValues.attachFlightsStep, 'advertiser.id');
    const brandId = _.get(populatedValues.attachFlightsStep, 'brand.id');
    const where = { externalIds: flightExtIds, limit: RESULTS_LIMIT, memberId, advertiserId, brandId, isCrossPlatform };
    const flightCandidatesRes = (brandId && !memberId) ? await FlightCandidate.brandFlightCandidate(where) : await FlightCandidate.get(where);
    const attachedFlights = flightCandidatesRes.data;

    // set defaultCurrency on the form based on the flights attached
    const currencyId = _.get(_.head(attachedFlights), 'currency');
    const currencyRes = currencyId ? await Currency.getById(currencyId) : null;
    const defaultCurrency = _.get(currencyRes, 'data', null);
    populatedValues.attachFlightsStep.defaultCurrency = defaultCurrency;

    // need to fire an action to the attachFlights store so we know the status of the flights we got from the query
    // string. now we can send users back if one of the flights needs to be detached from another strategy.
    try {
      await checkEligibility(
        attachedFlights,
        attachFlightsInfo,
        setAttachFlightsInfo,
        strategyTypeId,
      );
    } catch (error) {
      console.error(error);
    }
    populatedValues.attachFlightsStep.attachedFlights = attachedFlights;
  }

  if (populatedValues.attachFlightsStep.member && populatedValues.attachFlightsStep.member.dsp === DSP.AMZN.id) {
    const amznOrderRes = await AMZNOrder.get({ externalId: _.head(flightExtIds) });
    const amznProductLocation = _.get(_.head(amznOrderRes.data), 'optimization.productLocation');
    populatedValues.productLocation = amznProductLocation;
  }

  // load strategy type
  if (strategyTypeId && (populatedValues.attachFlightsStep.member || populatedValues.attachFlightsStep.brand)) {
    const strategyTypeRes = await StrategyType.get({ id: strategyTypeId });
    if (
      _.get(populatedValues.attachFlightsStep, 'member.dsp') === DSP.APN.id
      && !_.isEmpty(populatedValues.attachFlightsStep.attachedFlights)
      && +strategyTypeId !== crossPlatformOptStratTypeId
    ) {
      const restrictionsRes = await StrategyRecommendation.stratTypeRestrictions(
        _.map(populatedValues.attachFlightsStep.attachedFlights, (af) => ({
          ...af,
          dsp: DSP.APN.id,
        })),
      );
      // we currently only need unsupportedAdTypes and unsupportedFlightTypes to determine strategy type's validity
      const restrictionsForStrategyType = _.pick(restrictionsRes.data[strategyTypeId], [
        DisabledStrategyTypeReasons.unsupportedAdTypes,
        DisabledStrategyTypeReasons.unsupportedFlightTypes,
      ]);
      const strategyTypeValid = _.isEmpty(_.flatten(_.values(restrictionsForStrategyType)));
      if (strategyTypeValid) {
        populatedValues.strategyTypeSelectionStep = { strategyType: strategyTypeRes.data[0] };
      }
    } else {
      populatedValues.strategyTypeSelectionStep = { strategyType: strategyTypeRes.data[0] };
    }
  }
  const firstFlight = _.head(_.get(populatedValues, 'attachFlightsStep.attachedFlights'));
  const firstFlightExtId = _.get(firstFlight, 'externalId', _.head(flightExtIds)) as string;
  const firstFlightExtType = _.get(firstFlight, 'externalType');
  // load goal type and target and budget info
  if (goalType && goalTarget) {
    // QS pixels are stored with dsp coded keys
    const goal = impValueFilters
      ? { type: goalType, target: +goalTarget, impValueFilters: convertQSPixelsToForm(impValueFilters) }
      : { type: goalType, target: +goalTarget };
    populatedValues.goalSelectionStep = { goal };
    // revType only present in QS in Single Platform Campaign Opt Type
    if (revType) {
      populatedValues.goalSelectionStep = { goal, budget: { [firstFlightExtId]: { outcome: revType, revenueValue: +revValue, externalType: firstFlightExtType } } };
    }
  }
  // when loading QS from satellite goal target & revValue is not always entered when user hits the configure in Copilot button
  if (goalType && !goalTarget) {
    const goal = { type: goalType, target: null };
    populatedValues.goalSelectionStep = { goal };
    if (revType) {
      populatedValues.goalSelectionStep = { goal, budget: { [firstFlightExtId]: { outcome: revType, revenueValue: null, externalType: firstFlightExtType } } };
    }
  }
  // populate budget settings for cross-platform strategies
  if (budgetSettings) {
    const transformedbudgetSettings = transformBudgetSettingsDB(budgetSettings as Array<BudgetSettingDB>);
    populatedValues.goalSelectionStep.budgetSettings = transformedbudgetSettings;
  }

  // load strategy config
  if (strategyTypeId) {
    const baseDefaultConfig = await getInitialValuesByStrategyType(strategyTypeId);
    populatedValues.strategyConfigurationStep = moduleForm ? { ...baseDefaultConfig, ...moduleForm } : baseDefaultConfig;
  } else {
    populatedValues.strategyConfigurationStep = {};
  }

  // load metricsConfig
  if (customGoal && metricsConfig) {
    populatedValues.goalSelectionStep.customGoal = customGoal;
    populatedValues.goalSelectionStep.metricsConfig = metricsConfig;
  }

  // load strategy name
  if (name) {
    populatedValues.strategyConfirmationStep = { name };
  }
  return { ..._.omit(INITIAL_WIZARD_VALUES_FOR_UI, 'budgetAllocationState'), ...populatedValues };
};

const initNewStrategy = async (
  qsParams: QSParams,
  attachFlightsInfo: AttachFlightsInfoType,
  setAttachFlightsInfo: (x: any) => void,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  customGoal: Partial<FormulaType>,
  metricsConfig: MetricConfig,
  dispatch: Dispatch<SetStateAction<any>>,
) => {
  if (!_.get(qsParams, 'wizardForm')) {
    return { kind: StrategyWizardStates.newStrategy };
  }

  const filteredQsValues = removeEmptyQsValues(qsParams);
  try {
    const populatedValues = await getPopulatedValuesFromQueryString(
      filteredQsValues,
      attachFlightsInfo,
      setAttachFlightsInfo,
      customGoal,
      metricsConfig,
    );
    // fetch budget opt data if flights are higher order
    const attachedFlights = populatedValues.attachFlightsStep.attachedFlights as Array<Flight>;
    if (_.includes(BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, _.get(_.head(attachedFlights), 'externalType'))) {
      await fetchBudgetAllocationState(attachedFlights, budgetAllocationData, dispatch, _.get(populatedValues, 'strategyTypeSelectionStep.strategyType.id'));
    }
    return { kind: StrategyWizardStates.initFromQs, data: populatedValues };
  } catch (error) {
    return { kind: StrategyWizardStates.newStrategy };
  }
};

export const strategySetAmznProductLocation = async (dsp: number, strategy, attachedFlights) => {
  if (dsp === DSP.AMZN.id) {
    try {
      const amznOrderRes = await AMZNOrder.get({ externalId: _.map(attachedFlights, 'externalId') });
      const amznProductLocation = _.get(_.head(amznOrderRes.data), 'optimization.productLocation');
      // eslint-disable-next-line no-param-reassign
      strategy.productLocation = amznProductLocation;
    } catch (e) {
      console.error(e);
    }
  }
};

export default (
  strategyId: string,
  qsParams: QSParams,
  attachFlightsInfo: AttachFlightsInfoType,
  setAttachFlightsInfo: (x: any) => void,
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  customGoal: Partial<FormulaType>,
  metricsConfig: MetricConfig,
  dispatch: Dispatch<SetStateAction<any>>,
): StrategyWizardResponse => {
  const getStrategy = async () => {
    if (_.isNil(strategyId)) {
      const newStrategyRes = await initNewStrategy(qsParams, attachFlightsInfo, setAttachFlightsInfo, budgetAllocationData, customGoal, metricsConfig, dispatch);
      return newStrategyRes;
    }
    const [strategyResp, configResp, flightsResp, revTypeResp] = await Promise.all([
      Strategy.getById(strategyId, { populate: strategyPopulateCols }),
      Strategy.configuration(strategyId),
      ViewFlight.get({ strategy: strategyId, limit: RESULTS_LIMIT, populate: ['advertiser', 'member'] }),
      StrategyRevenueType.get({ strategy: strategyId, limit: RESULTS_LIMIT, populate: ['goal'] }),
    ]);

    const strategy = strategyResp.data;
    const config = configResp.data;
    const attachedFlights = _.map(flightsResp.data, (f) => ({
      ...f,
      dsp: f.member.dsp,
      member: f.member.id,
      memExtId: f.member.externalId,
      advertiser: f.advertiser.id,
      advExtId: f.advertiser.externalId,
    }));
    const strategyTypeId = _.get(strategy, 'strategyType.id');

    // fetch budget opt data if flights are higher order
    if (_.includes(BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES, _.get(_.head(attachedFlights), 'externalType'))) {
      await fetchBudgetAllocationState(attachedFlights, budgetAllocationData, dispatch, strategyTypeId);
    }

    // set the brand if there is no brand and the advertiser is tied to one
    if (!strategy.brand && strategy.advertiser) {
      const brandsArrRes = await AdvertiserBrand.brandsForAdvertiser({ advertiserId: strategy.advertiser.id });
      const brandId = _.head(brandsArrRes.data);
      if (brandId) {
        const brandRes = await Brand.get({ id: brandId, populate: 'advertisers', limit: 1, isDeleted: false });
        strategy.brand = _.head(brandRes.data);
      }
    }
    // populate advertisers respective to the brand
    if (strategy.brand) {
      const brandRes = await Brand.get({ id: strategy.brand.id, populate: 'advertisers', limit: 1, isDeleted: false });
      strategy.brand = _.head(brandRes.data);
    }

    // set strategy.productLocation if DSP is AMZN
    const dsp = getDspByMemberOrFlights(attachedFlights, strategy.member);
    strategySetAmznProductLocation(dsp, strategy, attachedFlights);

    strategy.strategyGoals = getActiveStrategyGoalsInPriorityOrder(strategy.strategyGoals);
    const mainStrategyGoal = _.head(strategy.strategyGoals) as StrategyGoalDB;
    const isEngScore = isEngScoreGoalType(mainStrategyGoal.type);

    if (isEngScore) {
      const { data: goalWeights } = await GoalWeight.get({ strategyGoal: mainStrategyGoal.id, limit: RESULTS_LIMIT });
      const attributes = formatPixelGoalWeightsForUi(goalWeights);
      mainStrategyGoal.attributes = attributes;
    }

    // for crossplatform strategies, conversionRevenue is saved as roas in strategygoal.type
    if (_.isEqual(_.camelCase(mainStrategyGoal.type), CROSS_PLATFORM_ROAS_GOAL_NAME)) {
      mainStrategyGoal.type = GOAL_TYPES.conversionRevenue.value;
    }

    // if type: null, AWG goal -> get correct goalType based on goal field and populate metricsConfig
    if (_.some(strategy.strategyGoals, { type: null })) {
      const customStrategyGoal = _.find(strategy.strategyGoals, ['type', null]);
      const { goal: goalId, id: stratGoalId } = customStrategyGoal;
      const { data: goalRes } = await Goal.get({ id: goalId, limit: 1 });
      const goalType = _.chain(goalRes as Array<GoalType>)
        .head()
        .get('name')
        .camelCase()
        .value();
      const formattedGoal = formatGoalFromDbToUi(goalRes);
      customStrategyGoal.type = _.isEqual(GOAL_TYPES.impactOutcome.value, goalType) ? goalType : GOAL_TYPES.awgCreateYourOwn.value;
      const { data: goalWeights } = await GoalWeight.get({ strategyGoal: stratGoalId, limit: RESULTS_LIMIT });
      strategy.customGoal = formattedGoal;
      strategy.metricsConfig = formatMetricsConfigFromDbToUi(goalWeights);
    }

    strategy.config = mapConfigFromDbToUi(config, strategyTypeId, _.get(strategy, 'strategyType.dsp'), strategy.strategyGoals);
    strategy.strategyType = {
      ...STRATEGY_TYPE.getById(strategyTypeId),
      ...strategy.strategyType,
    };
    strategy.attachedFlights = attachedFlights;
    strategy.optimizationLevel = getOptimizationLevelForStrat(strategy.attachedFlights, strategy.strategyType);
    strategy.selectedOptType = getSelectedOptType(strategy.strategyType);

    if (!_.isEmpty(revTypeResp.data)) {
      strategy.revTypeConfig = mapRevTypeConfigFromDbToUi(revTypeResp.data);
    }

    let currencyRes;
    // populate currency based on config for cross-platform
    if (_.get(strategy.config, 'currencyCode') && strategyId) {
      // currencyRes.data in this instance will be an array
      currencyRes = await Currency.get({ code: _.get(strategy.config, 'currencyCode'), limit: 1 });
    } else {
      const currencyId = _.get(_.head(attachedFlights), 'currency');
      currencyRes = currencyId ? await Currency.getById(currencyId) : null;
    }
    const currencyResData = _.get(currencyRes, 'data', null);
    const defaultCurrency = _.isArray(currencyResData) ? _.head(currencyResData) : currencyResData;
    strategy.defaultCurrency = defaultCurrency;

    try {
      await checkEligibility(
        attachedFlights,
        attachFlightsInfo,
        setAttachFlightsInfo,
        strategyTypeId,
        strategyId,
      );
    } catch (error) {
      console.error(error);
    }

    return { kind: PossibleStates.hasData, data: strategy };
  };
  const currentState = useFetcher(getStrategy, [strategyId]);
  if (currentState.kind === PossibleStates.error) {
    const { response } = currentState.errorObject;
    if (response) {
      if (response.status === 403) {
        return { kind: StrategyWizardStates.unauthorized };
      }
    }
  }
  return currentState;
};
