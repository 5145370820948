import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Container, Step } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { Strategy, Member, User, Flight, Brand } from 'utils/types';
import { canManageBrands, canManageFlights } from '../utils';
import { WIZARD_STEPS, StepItem, WizardSteps, INITIAL_WIZARD_VALUES_FOR_UI } from '../constants';
import { WizardFormValues } from '../types';

type Props = {
  step: number
  wizardFormValues: WizardFormValues | Strategy
  strategyId: string
  hasFormErrors: boolean
};

type StepDescriptionProps = {
  formField: {}
  additionalFieldsForDisplay: Array<string>
  wizardFormValues: WizardFormValues | Strategy
  description: string
  dynamicDescription: (formField: {}, ...args: Array<string>) => string
  currentStep: number
  stepId: number
};

enum StepStatus {
  completed = 'COMPLETED',
  disabled = 'DISABLED',
  active = 'ACTIVE',
}

const shouldDisableStep = (
  isCreateNew: boolean,
  currentStep: number,
  stepId: number,
  hasFormErrors: boolean,
  member: Member,
  brand: Brand,
  attachedFlights: Array<Flight>,
  user: User,
  goalsReset: boolean,
  configReset: boolean,
): boolean => {
  const noFlightsAttached = _.isEmpty(attachedFlights);
  // member will always be available for users without cross-platform access
  if (((member && !canManageFlights(user, member)) || (!member && brand && !canManageBrands(user))) && stepId === WIZARD_STEPS[WizardSteps.attachFlightsStep].id) {
    return true;
  }
  if (stepId > currentStep) {
    return true;
  }
  if (!isCreateNew && !noFlightsAttached && goalsReset && (stepId === WIZARD_STEPS[WizardSteps.goalSelectionStep].id)) {
    return true;
  }
  if (!isCreateNew && stepId === WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id && currentStep !== WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id) {
    return true;
  }
  if (!isCreateNew && !noFlightsAttached && configReset) {
    return stepId === WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id
      || stepId === WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id
      || stepId === WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id;
  }
  if (!isCreateNew && noFlightsAttached) {
    return stepId === WIZARD_STEPS[WizardSteps.goalSelectionStep].id
      || stepId === WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id
      || stepId === WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id;
  }
  return (!isCreateNew && stepId === WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id)
    || (stepId > WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id && hasFormErrors);
};

/**
 * handle styling of the strategy steps in the progress bar:
 *  for new strategies, gray out later steps than the one currently active
 *  for existing strategies, gray out first 2 steps always, and gray out later steps if for is not filled out
 */
const getStepStatus = (
  isCreateNew: boolean,
  currentStep: number,
  stepId: number,
  hasFormErrors: boolean,
  member: Member,
  brand: Brand,
  attachedFlights: Array<Flight>,
  user: User,
  goalsReset: boolean,
  configReset: boolean,
): StepStatus => {
  /*
    shouldDisable logic must come before isCompleted logic because
    disabling step takes precedence over marking step as completed
  */
  if (shouldDisableStep(isCreateNew, currentStep, stepId, hasFormErrors, member, brand, attachedFlights, user, goalsReset, configReset)) {
    return StepStatus.disabled;
  }

  if (currentStep > stepId && (isCreateNew || (!isCreateNew && !(stepId === WIZARD_STEPS[WizardSteps.strategyTypeSelectionStep].id)))) {
    return StepStatus.completed;
  }

  return StepStatus.active;
};

const StepDescription = ({
  formField,
  additionalFieldsForDisplay,
  wizardFormValues,
  description,
  dynamicDescription,
  currentStep,
  stepId,
}: StepDescriptionProps) => {
  if (stepId >= currentStep) {
    return <Step.Description />;
  }
  let additionalFieldValues = [];
  if (formField) {
    if (additionalFieldsForDisplay) {
      additionalFieldValues = _.map(additionalFieldsForDisplay, (field) => _.get(wizardFormValues, field));
    }
    return <Step.Description style={strategyWizardStyles.progressTitle}>{dynamicDescription(formField, ...additionalFieldValues)}</Step.Description>;
  }
  return <Step.Description>{description}</Step.Description>;
};

const StrategyWizardProgress = ({
  step,
  wizardFormValues,
  strategyId,
  hasFormErrors,
}: Props) => {
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isCreateNew = !strategyId;
  const member = _.get(wizardFormValues, 'attachFlightsStep.member');
  const brand = _.get(wizardFormValues, 'attachFlightsStep.brand');
  const attachedFlights = _.get(wizardFormValues, 'attachFlightsStep.attachedFlights');
  const goalsReset = _.get(wizardFormValues, 'goalSelectionStep') === INITIAL_WIZARD_VALUES_FOR_UI.goalSelectionStep;
  const configReset = _.get(wizardFormValues, 'strategyConfigurationStep') === INITIAL_WIZARD_VALUES_FOR_UI.strategyConfigurationStep;

  return (
    <Container fluid>
      <Step.Group fluid className="five" ordered>
        {
          _.map(WIZARD_STEPS, (stepItem: StepItem) => {
            const { id, title, description } = stepItem;
            const dynamicDescription = _.get(stepItem, 'dynamicDescription');
            const additionalFieldsForDisplay = _.get(stepItem, 'additionalFieldsForDisplay');
            const formField = _.get(wizardFormValues, stepItem.field);
            const stepStatus = getStepStatus(
              isCreateNew,
              step,
              id,
              hasFormErrors,
              member,
              brand,
              attachedFlights,
              user,
              goalsReset,
              configReset,
            );
            return (
              <Step
                key={id}
                active={step === id}
                completed={stepStatus === StepStatus.completed}
                disabled={stepStatus === StepStatus.disabled}
              >
                <Step.Content>
                  <Step.Title style={strategyWizardStyles.progressTitle}>{title}</Step.Title>
                  <StepDescription
                    formField={formField}
                    additionalFieldsForDisplay={additionalFieldsForDisplay || []}
                    wizardFormValues={wizardFormValues}
                    description={description}
                    dynamicDescription={dynamicDescription}
                    currentStep={step}
                    stepId={id}
                  />
                </Step.Content>
              </Step>
            );
          })
        }
      </Step.Group>
    </Container>
  );
};

export default StrategyWizardProgress;
