import _ from 'lodash';
import React, { CSSProperties } from 'react';
import { Control, Controller } from 'react-hook-form';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import FormSectionHeader from 'components/FormSectionHeader';
import { Button, ButtonProps, Form, Message } from 'buildingBlocks';
import { StrategyType } from 'utils/types';
import CustomTextArea from 'components/CustomTextArea';
import strategyWizardStyles from 'containers/StrategyWizard/styles';

type Props = {
  disabled: boolean
  control: Control
  csvValidationMsg: string
  strategyType: StrategyType
  isCustomTreeGenerating: boolean
  isCustomTreeValidating: boolean
  csvInputLength: number
  dropzoneStyle: { [key: string]: CSSProperties }
  onGenerateTreeText: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void
  onDrop: DropFilesEventHandler
};

const FileUpload = ({
  disabled, control, csvValidationMsg, strategyType,
  isCustomTreeGenerating, isCustomTreeValidating, onGenerateTreeText,
  csvInputLength, dropzoneStyle, onDrop,
}: Props) => (
  <>
    <FormSectionHeader
      title="Tree File"
      help="Upload a CSV file specifying the desired feature and bid combinations."
      group={strategyType.displayName}
    />
    <div style={{ position: 'relative' }}>
      <Form.Field disabled={disabled}>
        <Controller
          name="treeFileInput"
          control={control}
          render={({ field }) => (
            // @ts-ignore
            <CustomTextArea
              name="treeFileInput"
              disabled={disabled}
              onChange={field.onChange}
              height={470}
              mode="python"
              {..._.omit(field, 'ref')}
            />
          )}
        />
        <Dropzone
          onDrop={onDrop}
          multiple={false}
          style={csvInputLength > 0
            ? { ...dropzoneStyle.container, border: 0, opacity: 0.1 }
            : { ...dropzoneStyle.container, opacity: 1 }}
        >
          <p style={dropzoneStyle.headerText}>
            Drag & Drop your file here, or <span style={dropzoneStyle.browseText}>Browse</span>
          </p>
          <p style={dropzoneStyle.subtitleText}>
            Alternatively, type or paste into this field
          </p>
        </Dropzone>
      </Form.Field>
    </div>
    <div style={{ textAlign: 'center' }}>
      <Controller
        name="generateCustomTree"
        control={control}
        render={({ field }) => (
          <Button
            primary
            name="generateCustomTree"
            size="small"
            content="Generate"
            loading={isCustomTreeGenerating}
            onClick={onGenerateTreeText}
            disabled={isCustomTreeValidating}
            style={isCustomTreeValidating ? { ...strategyWizardStyles.nextButtonStyle, pointerEvents: 'none' } : strategyWizardStyles.nextButtonStyle}
            {...field}
          />
        )}
      />
    </div>
    {csvValidationMsg !== null
    && <Message negative size="small">{csvValidationMsg}</Message>}
  </>
);

export default FileUpload;
