import React, { CSSProperties } from 'react';
import { Header } from 'buildingBlocks';
import Tooltip from 'containers/Tooltip';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetParent } = BUDGET_MANAGEMENT_STYLES;

type BudgetParentObjectFieldsProps = {
  name: string
  content: string
  tooltip?: string
  customStyle?: CSSProperties
  stratName?: boolean
};

const BudgetParentObjectFields = ({
  name,
  content,
  tooltip,
  customStyle,
  stratName = false,
}: BudgetParentObjectFieldsProps) => (
  <div style={customStyle ?? budgetParent.textSection}>
    <div style={{ display: 'flex' }}>
      <Header
        content={name}
        style={stratName ? budgetParent.strategyNameHeader : budgetParent.headerText}
      />
      {tooltip && (
        <Tooltip
          content={tooltip}
          tooltipstyling={budgetParent.toolTip}
        />
      )}
    </div>
    <div style={budgetParent.subHeaderText} title={content}>{content}</div>
  </div>
);

export default BudgetParentObjectFields;
