import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Toggle from 'components/Toggle';
import {
  IntelligentChildObjectsToggleProps, FixedCostInventoryToggleProps,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';

export const IntelligentChildObjectsToggle = ({ displayName }: IntelligentChildObjectsToggleProps) => {
  const { control, setValue } = useFormContext<StrategyConfigurationStep>();
  return (
    <Controller
      name="intelligentChildObjects"
      control={control}
      render={({ field: { value, onChange } }) => (
        <Toggle
          id="intelligentChildObjects"
          group={displayName}
          checked={value}
          onChange={(toggle) => {
            onChange(toggle);
            setValue('prevSetIntelChildObj', true);
          }}
        />
      )}
    />
  );
};

export const FixedCostInventoryToggle = ({
  displayName, tooltipContent,
}: FixedCostInventoryToggleProps) => (
  <Controller
    name="fixedCostInventory"
    render={({ field: { value, onChange } }) => (
      <Toggle
        id="fixedCostInventory"
        label="Fixed Cost Inventory"
        group={displayName}
        checked={value}
        tooltip={tooltipContent}
        style={BAStyle.toolTipStyle}
        onChange={onChange}
      />
    )}
  />
);
