import _ from 'lodash';
import React from 'react';
import { Button } from 'buildingBlocks';
import { ChildOptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { POP_UP_FORM } from './style';

type PopUpFooterProps = {
  selectedLineItemSession: ChildOptions
  numAvailable: number
  onExitPopup: () => void
  handleSaveLineItems: () => void
};

const PopUpFooter = ({
  selectedLineItemSession,
  numAvailable,
  onExitPopup,
  handleSaveLineItems,
}: PopUpFooterProps) => (
  <div style={POP_UP_FORM.popupFormFooter}>
    <div style={POP_UP_FORM.footerTextContainer}>
      <p style={POP_UP_FORM.numOfSelectedFiltersText}>{`${_.size(selectedLineItemSession)} Selected`}</p>
      <p style={POP_UP_FORM.numOfSelectedFiltersText}>{`${numAvailable} Available`}</p>
    </div>
    <div>
      <Button
        primary
        content="Cancel"
        style={POP_UP_FORM.cancelBtn}
        onClick={onExitPopup}
      />
      <Button
        primary
        content="Done"
        style={POP_UP_FORM.doneBtn}
        onClick={handleSaveLineItems}
      />
    </div>
  </div>
);

export default PopUpFooter;
