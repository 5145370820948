import React, { useState } from 'react';
import { Icon, Loader, Modal, Segment } from 'buildingBlocks';
import { getHeaderText } from './utils';
import { PIXEL_PICKER_STYLES } from './styles';

const { modal, modalHeader, closeIcon, modalContent, loadingSegment, loaderStyle } = PIXEL_PICKER_STYLES;

const PixelsLoading = ({ defaultOpen, goalType }: { defaultOpen: boolean, goalType: string }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(defaultOpen);
  return (
    <>
      <Loader active inline style={loaderStyle} />
      <Modal
        open={modalOpen}
        dimmer="blurring"
        style={modal}
      >
        <Icon
          name="times"
          style={closeIcon}
          onClick={() => setModalOpen(false)}
        />
        <Modal.Header style={modalHeader}>{getHeaderText(goalType)}</Modal.Header>
        <Modal.Content style={modalContent}>
          <Segment basic style={loadingSegment} loading>
            <Loader inverted />
          </Segment>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default PixelsLoading;
