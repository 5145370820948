/* eslint-disable no-param-reassign */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Checkbox, Grid } from 'buildingBlocks';
import { Pixel } from 'utils/types';
import DspPixelOptions from './DspPixelOptions';
import { PIXEL_PICKER_STYLES } from '../styles';
import { DspToPixel, DspToPixelToWeightsType, PixelCategories } from '../types';
import { getMatchingPixels } from '../utils';

const {
  displayFlex, pixelTableContainer, pixelTableHeader, pixelOptionsContainer, baseColumnStyles,
  pixelCheckbox, pixelDescription, pixelWeightsColumn, noResultRow, noResultColumn,
} = PIXEL_PICKER_STYLES;

type PixelTableProps = {
  isEngScore: boolean
  pixelCategory: PixelCategories
  searchStr: string
  pixels: DspToPixel
  selectedModalPixels: DspToPixel
  setSelectedModalPixels: Dispatch<SetStateAction<DspToPixel>>
  defaultDspToPixelToWeights: DspToPixelToWeightsType
};

const PixelTable = (props: PixelTableProps) => {
  const {
    isEngScore,
    pixelCategory,
    searchStr,
    pixels,
    selectedModalPixels,
    setSelectedModalPixels,
    defaultDspToPixelToWeights,
  } = props;
  // component will be re-rendered if pixelCategory changes, setting the visible pixels to the correct DSPToPixel object
  const pixelOptions = pixelCategory === PixelCategories.all ? pixels : selectedModalPixels;
  const [visiblePixels, setVisiblePixels] = useState<DspToPixel>(pixelOptions);

  const selectAllChecked = _.every(visiblePixels, (pixelArr, dsp) => {
    if (selectedModalPixels[dsp]) {
      // if all visible dsp pixels are within dsp array of selectedModalPixels, difference should be empty array
      return !_.size(_.differenceBy(pixelArr, selectedModalPixels[dsp], 'id'));
    }
    return false;
  });

  const onSelectAllClick = () => {
    // include weights if goal type is engagement score
    const pixelsToUse = isEngScore
      ? (
        _.reduce(visiblePixels, (result, pixelArr, dsp) => {
          _.forEach(pixelArr, (p) => {
            p.weight = defaultDspToPixelToWeights[dsp][p.id];
          });
          return result;
        }, { ...visiblePixels })
      ) : visiblePixels;
    const newSelectedModalPixels = _.reduce(pixelsToUse, (result, pixelArr, dsp) => {
      if (result[dsp]) {
        // remove common pixels if selectAll is check otherwise join dsp pixel arrays and remove duplicates
        result[dsp] = selectAllChecked
          ? _.differenceBy(result[dsp], pixelArr, 'id')
          : _.uniqBy([...pixelArr, ...result[dsp]], 'id');
      }
      // set the dsp pixels if prev selectedModalPixels did not include any from dsp
      if ((!result[dsp] || _.isEmpty(result[dsp])) && !selectAllChecked) {
        result[dsp] = pixelArr;
      }
      return result;
    }, { ...selectedModalPixels });
    setSelectedModalPixels(newSelectedModalPixels);
  };

  useEffect(() => {
    if (_.isEmpty(searchStr)) {
      setVisiblePixels(pixelOptions);
    } else {
      const pixelCopy = { ...pixelOptions };
      const filteredPixels = _.reduce(pixelCopy, (result, pixelArr, dsp) => {
        const matchingPixels = getMatchingPixels(pixelArr, searchStr);
        if (!_.isEmpty(matchingPixels)) {
          result[dsp] = matchingPixels;
        }
        return result;
      }, {});
      setVisiblePixels(filteredPixels);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr, pixelCategory, selectedModalPixels]);

  return (
    <Grid style={pixelTableContainer}>
      <Grid.Row style={pixelTableHeader}>
        <div style={displayFlex}>
          <Grid.Column style={baseColumnStyles}>
            <Checkbox
              checked={selectAllChecked}
              disabled={_.isEmpty(_.flatten(_.values(visiblePixels)))}
              onClick={onSelectAllClick}
              style={pixelCheckbox}
            />
          </Grid.Column>
          <Grid.Column style={_.omit(pixelDescription, 'width')}>
            Pixels
          </Grid.Column>
        </div>
        {isEngScore && (
          <Grid.Column style={pixelWeightsColumn}>
            Weights
          </Grid.Column>
        )}
      </Grid.Row>
      <div style={pixelOptionsContainer} className="pixel-options-container">
        {!_.isEmpty(visiblePixels) && (
          <>
            {_.map(visiblePixels, (pixArr: Array<Pixel>, dsp: string) => ((_.isEqual(pixelCategory, PixelCategories.all) || !_.isEmpty(pixArr)) && (
              <DspPixelOptions
                key={dsp}
                dsp={_.toNumber(dsp)}
                pixels={pixArr}
                selectedModalPixels={selectedModalPixels}
                setSelectedModalPixels={setSelectedModalPixels}
                defaultDspToPixelToWeights={defaultDspToPixelToWeights}
                isEngScore={isEngScore}
              />
            )))}
          </>
        )}
        {_.isEmpty(visiblePixels) && !_.isEmpty(searchStr) && (
          <Grid.Row style={noResultRow}>
            <Grid.Column style={noResultColumn}>
              No results found for “{searchStr}”. Try a new search.
            </Grid.Column>
          </Grid.Row>
        )}
      </div>
    </Grid>
  );
};

export default PixelTable;
