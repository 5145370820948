/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import React, { CSSProperties, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from 'buildingBlocks';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import { getFieldErrors } from '../utils';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type EditTitleProps = {
  value: string
  onChange: (x: any) => void
  onCancel: () => void
  budgetGroupKey: string
  name: string
};

const EditTitle = ({
  value,
  onChange,
  onCancel,
  budgetGroupKey,
  name,
}: EditTitleProps) => {
  const ref = useRef(null);
  const { setValue } = useFormContext<StrategyConfigurationStep>();
  const groupNameError = getFieldErrors(budgetGroupKey, 'groupName');
  const hasError = !_.isEmpty(groupNameError);

  const applyRulesToInput = (e) => {
    // removes whitespaces after user saves input
    setValue((name as any), e.target.value.trim());
    onCancel();
  };

  const handleOnBlur = (e) => {
    if (!hasError) {
      applyRulesToInput(e);
    }
  };

  const handleOnEnterKeyDown = (e) => {
    if (e.key === 'Enter' && !hasError) {
      applyRulesToInput(e);
    }
  };

  useMount(() => {
    ref.current.focus();
  });
  // TODO: low priority bug: pressing enter will create new Budget Group
  return (
    <div style={budgetGroupStyle.editBudgetGroupNameContainer}>
      <Input
        fluid
        size="mini"
        ref={ref}
        value={value}
        onChange={onChange}
        onBlur={handleOnBlur}
        onKeyDown={handleOnEnterKeyDown}
        style={budgetGroupStyle.editBudgetGroupNameInputField}
        transparent
      />
    </div>
  );
};

const ValidatedInput = validatedFieldWrapper(EditTitle);

type BudgetGroupNameInputProps = {
  style: CSSProperties
  name: string
  groupTitle: string
  onCancel: () => void
  budgetGroupKey: string
};

export const BudgetGroupNameInput = (props: BudgetGroupNameInputProps) => (
  <Controller
    name={props.name}
    defaultValue={props.groupTitle}
    render={({ field, fieldState }) => (
      <ValidatedInput
        type="text"
        field={field}
        fieldState={fieldState}
        onChange={field.onChange}
        onCancel={props.onCancel}
        width={12}
        skipIsDirtyCheck
        {...props}
      />
    )}
  />
);

export default BudgetGroupNameInput;
