import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, ObjectMultiDropdown, Form } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { COPILOT_COLORS } from 'globalStyles';
import { WidgetWrapper } from 'utils/widget';
import { useUsersDataFetcher } from 'utils/hooks/useUserFetcher';
import { User } from 'utils/types';
import { textStyles, developerApiLink } from './constants';

const { column } = strategyWizardStyles;

const InfoBox = () => (
  <Grid.Row>
    <Grid.Column style={column}>
      <span style={textStyles.infoBox}>
        Use this strategy to track Insertion Orders that the Marketing Science or Data Science teams are optimizing using the &nbsp;
        <a
          href={developerApiLink}
          style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Copilot APIs.
        </a>
      </span>
    </Grid.Column>
  </Grid.Row>
);

const UploadSDFSection = () => (
  <Grid.Row>
    <Grid.Column style={column} width={10}>
      <div>
        <h4 style={textStyles.header}>Upload SDF (Coming soon)</h4>
        <div style={textStyles.content}>
          <span>
            Use this strategy to direct Marketing Science / Data Science teams to the Insertion Order to be used.<br />
            If you would like to make changes using SDF, please reach out with a&nbsp;
            <a
              href="#"
              style={{ color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.BLUES.B500_WAVE }}
              onClick={() => WidgetWrapper('open')}
            >
              support ticket.
            </a>
          </span>
        </div>
      </div>
    </Grid.Column>
  </Grid.Row>
);

type EmailComponentProps = {
  users: Array<User>,
  usersFetchError: string,
};

const EmailComponent = ({ users, usersFetchError }: EmailComponentProps) => {
  const { control } = useFormContext();
  return (
    <Grid.Row>
      <Grid.Column style={column} width={8}>
        <div>
          <h4 style={textStyles.header}>Share with Team Members</h4>
          <div style={textStyles.content}>
            The following team members will be notified with strategy details to allow them to
            update the Custom SDF strategy via Copilot API.
            Please ensure that team members have existing Copilot access.
          </div>
          <br />
          <Form.Field style={{ width: '50%' }}>
            <Controller
              name="selectedUsersForEmail"
              control={control}
              render={({ field }) => (
                // @ts-ignore find a way to get the props to work with rhf controller
                <ObjectMultiDropdown
                  name="selectedUsersForEmail"
                  options={users}
                  keyFn={(obj: { email: string; }) => obj.email}
                  placeholder="Choose user(s)"
                  selection
                  search={{ searchType: 'local' }}
                  value={field.value}
                  onChange={field.onChange}
                  {...field}
                />
              )}
            />
            {usersFetchError && <span style={{ color: '#DB2828' }}>Failed to fetch users</span>}
          </Form.Field>
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

const CustomSDF = () => {
  const [users, usersFetchError] = useUsersDataFetcher();

  return (
    <Grid stackable celled="internally">
      <InfoBox />
      <EmailComponent
        users={users}
        usersFetchError={usersFetchError}
      />
      <UploadSDFSection />
    </Grid>
  );
};

export default CustomSDF;
