import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Icon } from 'buildingBlocks';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import {
  BudgetGroup as BudgetGroupType,
  BudgetGroupLineItemMapping,
  GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { DEFAULT_MAX_ALLOCATION, DEFAULT_MIN_ALLOCATION } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import BudgetGroupName from './BudgetGroupName';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import BudgetGroupExpandedInfo, { AllocationRangeInput, DisplayEstimatedDelivery } from './BudgetGroupExpandedInfo';
import BudgetGroupInfoField from './BudgetGroupInfoField';
import { displayAllocationRange, displayDailyDelivery, displayLifetimeDelivery } from '../utils';

const { budgetGroup: budgetGroupStyle, budgetParent } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupDisplayInfoProps = {
  budgetGroup: BudgetGroupType
  lifeTimeBudget: number
  accordionOpen: boolean
  setAccordionOpen: Dispatch<SetStateAction<boolean>>
  budgetGroupKey: string
  requiredDailyValue: number
  budgetGroupToLineItems: BudgetGroupLineItemMapping
  setBudgetGroupToLineItems: Dispatch<SetStateAction<BudgetGroupLineItemMapping>>
  budgetMetric: string
  generalAllocationRangeError: string
  remainingSpendDays: number
  budgetGroups: GroupSettings
};

const BudgetGroupDisplayInfo = ({
  budgetGroup,
  lifeTimeBudget,
  accordionOpen,
  setAccordionOpen,
  budgetGroupKey,
  requiredDailyValue,
  budgetGroupToLineItems,
  setBudgetGroupToLineItems,
  budgetMetric,
  generalAllocationRangeError,
  remainingSpendDays,
  budgetGroups,
}: BudgetGroupDisplayInfoProps) => {
  const reduxFormValues = useSelector<GlobalState>((state) => state.strategyWizard.strategyConfigurationStep);
  const prevBudgetGroupSettings = _.get(reduxFormValues, `groupSettings[${budgetGroupKey}]`);
  const isEditMode = !!_.size(_.get(prevBudgetGroupSettings, 'childExtIds'));

  const [editingTitle, setEditingTitle] = useState<boolean>(!isEditMode);
  const { formState: { errors }, setValue } = useFormContext<StrategyConfigurationStep>();

  const hasError = !!_.get(errors, `groupSettings[${budgetGroupKey}]`) || !!generalAllocationRangeError;
  const defaultMinValue = _.get(prevBudgetGroupSettings, 'min', DEFAULT_MIN_ALLOCATION);
  const defaultMaxValue = _.get(prevBudgetGroupSettings, 'max', DEFAULT_MAX_ALLOCATION);
  const aggDelivery = _.get(budgetGroup, 'aggDelivery', 0);

  const watchForMinVal = useWatch({ name: (`groupSettings[${budgetGroupKey}].min` as any), defaultValue: defaultMinValue });
  const watchForMaxVal = useWatch({ name: (`groupSettings[${budgetGroupKey}].max` as any), defaultValue: defaultMaxValue });

  const collapsedAllocationContent = displayAllocationRange(watchForMinVal, watchForMaxVal);
  // in edge case when there is no cumData and less than 1 day remaining, use the lifetimeBudget
  const dailyValue = requiredDailyValue > lifeTimeBudget ? lifeTimeBudget : requiredDailyValue;
  const dailyDeliveryValue = displayDailyDelivery(dailyValue, watchForMinVal, watchForMaxVal, budgetMetric);
  const lifetimeDeliveryValue = displayLifetimeDelivery(requiredDailyValue, watchForMinVal, watchForMaxVal, budgetMetric, remainingSpendDays, aggDelivery);

  const iconToUse = accordionOpen ? 'angle down' : 'angle right';

  const handleDeleteBudgetGroup = () => {
    setBudgetGroupToLineItems(_.omit(budgetGroupToLineItems, budgetGroupKey));
    setValue('groupSettings', _.omit(budgetGroups, budgetGroupKey));
  };

  return (
    <div style={accordionOpen ? budgetGroupStyle.budgetGroupContainer : budgetGroupStyle.collapsedBudgetGroupContainer}>
      <Icon
        size="large"
        name={iconToUse}
        disabled={hasError}
        onClick={() => setAccordionOpen(!accordionOpen)}
        style={accordionOpen ? budgetGroupStyle.accordionOpenIcon : budgetGroupStyle.accordionCloseIcon}
      />
      <div style={budgetGroupStyle.budgetGroupInfoContainer}>
        <div style={budgetGroupStyle.budgetGroupInfoFirstHalf}>
          <BudgetGroupExpandedInfo
            accordionOpen={accordionOpen}
            style={{ width: '60%', ...(accordionOpen && { paddingBottom: '16px' }) }}
            displayContent={(
              <BudgetGroupName
                groupTitle={budgetGroup.groupName}
                isEditMode={editingTitle}
                onEditTitle={() => setEditingTitle(true)}
                accordionOpen={accordionOpen}
                onCancel={() => setEditingTitle(false)}
                budgetGroupKey={budgetGroupKey}
              />
            )}
            accordionContent={<p style={budgetParent.subHeaderText}>{_.size(budgetGroup.childExtIds)} Objects Selected </p>}
          />
          <BudgetGroupExpandedInfo
            accordionOpen={accordionOpen}
            style={{ width: '38%' }}
            displayContent={(
              <BudgetGroupInfoField
                name="Allocation Range"
                accordionCollapsed={!accordionOpen}
                collapsedContent={collapsedAllocationContent}
              />
            )}
            accordionContent={(<AllocationRangeInput budgetGroupKey={budgetGroupKey} />)}
          />
        </div>
        <div style={budgetGroupStyle.budgetGroupInfoSecondHalf}>
          <BudgetGroupExpandedInfo
            accordionOpen={accordionOpen}
            style={{ width: '42%' }}
            displayContent={(
              <BudgetGroupInfoField
                name="Daily Delivery"
                accordionCollapsed={!accordionOpen}
                collapsedContent={dailyDeliveryValue}
              />
            )}
            accordionContent={(<DisplayEstimatedDelivery delivery={dailyDeliveryValue} />)}
          />
          <BudgetGroupExpandedInfo
            accordionOpen={accordionOpen}
            style={{ width: '41%', paddingLeft: '6px' }}
            displayContent={(
              <BudgetGroupInfoField
                name="Lifetime Delivery"
                accordionCollapsed={!accordionOpen}
                collapsedContent={lifetimeDeliveryValue}
              />
            )}
            accordionContent={(<DisplayEstimatedDelivery delivery={lifetimeDeliveryValue} />)}
          />
        </div>
      </div>
      <Icon
        name="cancel"
        style={budgetGroupStyle.deleteIcon}
        onClick={handleDeleteBudgetGroup}
      />
    </div>
  );
};

export default BudgetGroupDisplayInfo;
