/* eslint-disable no-mixed-operators */
import _ from 'lodash';
import React from 'react';
import ReactHighcharts from 'react-highcharts';
import { COPILOT_COLORS } from 'globalStyles';
import { PacingProps } from './Pacing';

const a = 0.11689375;
const b = 0.89155969;
const c = -0.03713518;
const d = 0.0757086;
const e = -0.03937878;
const f = 0.00598462;
const g = -0.00977476;
const h = 0.00387282;

export const getYAxis = (pacing: number) => _.map(_.range(1, 11), (x : number) => {
  const y = (a + b * pacing)
  + ((c + d * pacing + e * pacing ** 2) * x ** 2)
  + ((f + g * pacing + h * pacing ** 2) * x ** 3);
  return _.round(y * 100);
});

const getChartOptions = (pacing: number) => {
  const data = getYAxis(pacing);
  return {
    chart: {
      type: 'spline',
      height: 200,
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    xAxis: {
      title: {
        text: 'Time',
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: false,
      },
      minorTickLength: 0,
      tickLength: 0,
      lineColor: COPILOT_COLORS.graphGrey,
    },
    yAxis: {
      title: {
        text: 'Pacing (%)',
      },
      min: 0,
      max: 200,
      lineColor: COPILOT_COLORS.graphGrey,
      gridLineWidth: 0,
      lineWidth: 1,
      plotLines: [{
        color: COPILOT_COLORS.graphGrey,
        width: 1,
        value: 0,
      }],
      offset: -1,
    },
    series: [
      {
        showInLegend: false,
        data,
      },
    ],
    exporting: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
  };
};

const PacingGraph = ({ pacing }: PacingProps) => (
  // @ts-ignore props overload issue
  <ReactHighcharts config={getChartOptions(pacing)} />
);

export default PacingGraph;
