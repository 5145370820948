import React from 'react';
import _ from 'lodash';
import { Menu, Tab } from 'buildingBlocks';
import {
  BID_LIST_STRING,
  VOLUME_CONTROL_BID_LIST_STRING,
  BidListValidity,
  BidListErrorArray,
  BidListStatus,
  PageStatusType,
  PageStatus,
  paddingTop5px,
  FromTabType,
  TabType,
  VolumeControlStatus,
} from './constants';
import { TranslationErrorsByField } from './context';
import TabComponent from './TabComponent';

const colorMap = (bidListValidity: BidListValidity, notGeneratedWarning: string | null) => {
  const mapper = {
    [BidListValidity.Valid]: () => 'green-tab-color',
    [BidListValidity.Invalid]: () => 'red-tab-color',
    [BidListValidity.NotYetValidated]: (possibleWarning: string | null) => {
      if (_.isNil(possibleWarning)) return 'not-yet-validated-tab-color';
      return 'no-content-tab-color';
    },
  };

  return mapper[bidListValidity](notGeneratedWarning);
};

const determineValidity = (pageStatus: PageStatusType, fromTabType: FromTabType, bidListErrors: BidListErrorArray) => {
  const flattenedPageStatus = fromTabType === TabType.BID_LIST_TAB
    ? _.get(pageStatus, 'bidListStatus', pageStatus)
    : _.get(pageStatus, 'volumeControlStatus', pageStatus);

  switch (flattenedPageStatus) {
    case BidListStatus.BidListsAreValid:
    case VolumeControlStatus.VolumeControlAreValid:
      return BidListValidity.Valid;
    case BidListStatus.NotYetValidated:
    case VolumeControlStatus.NotYetValidated:
      return BidListValidity.NotYetValidated;
    case BidListStatus.BidListsError:
    case VolumeControlStatus.VolumeControlError:
      return _.isEmpty(bidListErrors) ? BidListValidity.Valid : BidListValidity.Invalid;
    default:
      return BidListValidity.NotYetValidated;
  }
};

type TabsProps = {
  onValidateBidListClick: () => void,
  onBidListChange: (event: React.ChangeEvent, val: string) => void,
  onVolumeControlBidListChange: (event: React.ChangeEvent, val: string) => void,
  bidListString: string,
  volumeControlBidListString: string,
  pageStatus: PageStatusType,
  bidListErrors: BidListErrorArray,
  volumeControlErrors: BidListErrorArray,
  noBidListGeneratedWarning: string | null,
  noVcBidListGeneratedWarning: string | null,
  translationErrorsByField: TranslationErrorsByField,
};

const Tabs = ({
  onValidateBidListClick,
  onBidListChange,
  onVolumeControlBidListChange,
  bidListString,
  volumeControlBidListString,
  pageStatus,
  bidListErrors,
  volumeControlErrors,
  noBidListGeneratedWarning,
  noVcBidListGeneratedWarning,
  translationErrorsByField,
}: TabsProps) => {
  const bidListValidity = determineValidity(pageStatus, TabType.BID_LIST_TAB, bidListErrors);
  const vcBidListValidity = determineValidity(pageStatus, TabType.VOLUME_CONTROL_TAB, volumeControlErrors);
  const bidListTabColor = colorMap(bidListValidity, noBidListGeneratedWarning);
  const vcBidListTabColor = colorMap(vcBidListValidity, noVcBidListGeneratedWarning);

  const shouldDisableBidListValidate = (
    pageStatus.bidListStatus === PageStatus.InitialPageStatus
      || pageStatus.bidListStatus === PageStatus.CsvHasError
      || pageStatus.bidListStatus === PageStatus.BidListsGenerating
      || pageStatus.bidListStatus === BidListStatus.BidListsAreValid
  );

  const shouldDisableVolumeControlValidate = (
    pageStatus.bidListStatus === PageStatus.InitialPageStatus
      || pageStatus.volumeControlStatus === PageStatus.CsvHasError
      || pageStatus.volumeControlStatus === PageStatus.VolumeControlGenerating
      || pageStatus.volumeControlStatus === VolumeControlStatus.VolumeControlAreValid
  );

  const shouldDisableBidListInput = (
    pageStatus.bidListStatus === PageStatus.CsvHasError
      || pageStatus.bidListStatus === PageStatus.BidListsGenerating
      || pageStatus.bidListStatus === BidListStatus.BidListsValidating
  );

  const shouldDisableVolumeControlInput = (
    pageStatus.volumeControlStatus === PageStatus.CsvHasError
      || pageStatus.volumeControlStatus === PageStatus.VolumeControlGenerating
      || pageStatus.volumeControlStatus === VolumeControlStatus.VolumeControlValidating
  );

  const shouldDisplayValidateButtonLoader = pageStatus.bidListStatus === BidListStatus.BidListsValidating;

  const shouldDisableValidateBidList = shouldDisableBidListValidate || bidListString.length === 0;
  const shouldDisableValidateVolumeControl = shouldDisableVolumeControlValidate || volumeControlBidListString.length === 0;

  return (
    [
      {
        menuItem: <Menu.Item id={bidListTabColor} key={1}><div style={paddingTop5px}>Bid List</div></Menu.Item>,
        render: () => (
          <Tab.Pane>
            <TabComponent
              onChange={onBidListChange}
              onValidateBidListClick={onValidateBidListClick}
              bidListString={bidListString}
              errors={bidListErrors}
              id={BID_LIST_STRING}
              bidListValidity={bidListValidity}
              shouldDisableValidateBidList={shouldDisableValidateBidList}
              shouldDisableBidListInput={shouldDisableBidListInput}
              shouldDisplayValidateButtonLoader={shouldDisplayValidateButtonLoader}
              notGeneratedWarning={noBidListGeneratedWarning}
              translationErrorsByField={translationErrorsByField}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: <Menu.Item id={vcBidListTabColor} key={2}><div style={paddingTop5px}>Volume Control</div></Menu.Item>,
        render: () => (
          <Tab.Pane>
            <TabComponent
              onChange={onVolumeControlBidListChange}
              onValidateBidListClick={onValidateBidListClick}
              bidListString={volumeControlBidListString}
              errors={volumeControlErrors}
              id={VOLUME_CONTROL_BID_LIST_STRING}
              bidListValidity={vcBidListValidity}
              shouldDisableValidateBidList={shouldDisableValidateVolumeControl}
              shouldDisableBidListInput={shouldDisableVolumeControlInput}
              shouldDisplayValidateButtonLoader={shouldDisplayValidateButtonLoader}
              notGeneratedWarning={noVcBidListGeneratedWarning}
              translationErrorsByField={translationErrorsByField}
            />
          </Tab.Pane>
        ),
      },
    ]
  );
};

export default Tabs;
