import _ from 'lodash';
import React from 'react';
import { Header } from 'buildingBlocks';
import { FLIGHT_EXTERNAL_TYPE } from 'constantsBase';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetGroup } = BUDGET_MANAGEMENT_STYLES;

type LineItemPopUpContentProps = {
  lineItemName: string
  childExtId: string
  lineItemExtType: number
  parentObjectName: string
  lineItemMember: string
  flightDuration: string
  daysRemaining: string
};

const LineItemPopUpContent = ({
  lineItemName,
  childExtId,
  lineItemExtType,
  parentObjectName,
  lineItemMember,
  flightDuration,
  daysRemaining,
}: LineItemPopUpContentProps) => {
  const flightType = _.get(FLIGHT_EXTERNAL_TYPE.getById(lineItemExtType), 'wizardDisplayName');
  return (
    <div style={budgetGroup.lineItemPopUpContainer}>
      <Header
        size="small"
        as="h6"
        content={lineItemName}
        style={budgetGroup.lineItemPopUpHeader}
      />
      <p style={budgetGroup.lineItemPopUpText}><strong>Type: </strong>{flightType}</p>
      {childExtId && (<p style={budgetGroup.lineItemPopUpText}><strong>ID: </strong>{childExtId}</p>)}
      <p style={budgetGroup.lineItemPopUpText}><strong>Member: </strong>{lineItemMember}</p>
      <p style={budgetGroup.lineItemPopUpText}><strong>Duration: </strong>{flightDuration}</p>
      <p style={budgetGroup.lineItemPopUpText}>{daysRemaining} remaining</p>
      <p style={budgetGroup.lineItemPopUpText}><strong>Parent Insertion Order: </strong>{parentObjectName}</p>
    </div>
  );
};

export default LineItemPopUpContent;
