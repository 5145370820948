import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import DspIcon from 'components/DspIcon';
import { ChildOptions, ExpandAllOptionsObject, ParentOptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import ParentAccordion from './ParentAccordion';

type DspOptionsSectionType = {
  dspId: string | number
  sectionOptions: ParentOptions,
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  search: string
  alreadyAttachedLineItemKeys: Array<string>
  expandAllObj: ExpandAllOptionsObject
  setExpandAllObj: Dispatch<SetStateAction<ExpandAllOptionsObject>>
};

const DspOptionsSection = ({
  dspId,
  sectionOptions,
  selectedLineItemSession,
  setSelectedLineItemSession,
  search,
  alreadyAttachedLineItemKeys,
  expandAllObj,
  setExpandAllObj,
}: DspOptionsSectionType) => {
  const optionsForSection = _.map(sectionOptions, (childOptions: ChildOptions, parentOption: string) => (
    <ParentAccordion
      key={parentOption}
      parentOption={parentOption}
      childOptions={childOptions}
      selectedLineItemSession={selectedLineItemSession}
      setSelectedLineItemSession={setSelectedLineItemSession}
      search={search}
      alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
      expandAllObj={expandAllObj}
      setExpandAllObj={setExpandAllObj}
    />
  ));

  return (
    <>
      <div>
        <DspIcon
          dspId={+dspId}
          status="budgetGroup"
        />
      </div>
      {optionsForSection}
    </>
  );
};

export default DspOptionsSection;
