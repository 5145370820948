/* eslint-disable jsx-a11y/no-static-element-interactions */
import _ from 'lodash';
import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Accordion, Checkbox, Icon } from 'buildingBlocks';
import { SingleChildOption, ChildOptions, ExpandAllOptionsObject } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { POP_UP_FORM } from './style';
import { isAlreadyAttached } from '../GroupSettings/utils';

type ItemDisplayProps = {
  text: string,
  item: any,
  onClick: (item: SingleChildOption) => void,
  checked?: boolean,
  style?: CSSProperties,
  disabled?: boolean,
};

const LineItemDisplay = ({ text, item, onClick, checked, style, disabled }: ItemDisplayProps) => {
  const handleOnClick = () => onClick(item);
  return (
    <div onClick={handleOnClick} className="row-item item-display" style={style}>
      <Checkbox className="pop-up-form-checkbox" checked={checked} disabled={disabled} />
      <span className="text" style={disabled ? POP_UP_FORM.disabledChildOptionText : POP_UP_FORM.childOptionText}>{text}</span>
    </div>
  );
};

type ParentAccordionProps = {
  parentOption: string
  childOptions: ChildOptions
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  search: string
  alreadyAttachedLineItemKeys: Array<string>
  expandAllObj: ExpandAllOptionsObject
  setExpandAllObj: Dispatch<SetStateAction<ExpandAllOptionsObject>>
};

const ParentAccordion = ({
  parentOption,
  childOptions,
  selectedLineItemSession,
  setSelectedLineItemSession,
  search,
  alreadyAttachedLineItemKeys,
  expandAllObj,
  setExpandAllObj,
}: ParentAccordionProps) => {
  const showChildren = expandAllObj[parentOption] || !!search;

  const childOptionKeys = new Set(_.keys(childOptions));
  const allAttachedAndSelectedLineItemKeys = _.concat(alreadyAttachedLineItemKeys, _.keys(selectedLineItemSession));
  const allChildOptionsSelectedInSingleBudgetGroup = _.isEqual(selectedLineItemSession, childOptions) || _.size(_.pickBy(selectedLineItemSession, (_val, key: string) => childOptionKeys.has(key))) === _.size(childOptions);
  const allChildOptionsSelected = _.size(_.omit(childOptions, allAttachedAndSelectedLineItemKeys)) === 0;
  const allChildOptionsAlreadyAttached = isAlreadyAttached(childOptions, alreadyAttachedLineItemKeys);

  const handleParentAccordionClick = () => {
    setExpandAllObj({ ...expandAllObj, [parentOption]: !expandAllObj[parentOption] });
  };

  const handleParentCheckBoxClick = () => {
    if (allChildOptionsSelected) {
      setSelectedLineItemSession(_.omit(selectedLineItemSession, _.keys(childOptions)));
    } else {
      setSelectedLineItemSession({ ...selectedLineItemSession, ..._.omit(childOptions, alreadyAttachedLineItemKeys) });
    }
  };

  const handleChildOptionClick = (item: SingleChildOption, key: string) => {
    if (_.includes(selectedLineItemSession, item)) {
      const newSelected = _.omit(selectedLineItemSession, [`${key}`]);
      setSelectedLineItemSession(newSelected);
    } else {
      setSelectedLineItemSession({ ...selectedLineItemSession, [key]: item });
    }
  };

  const iconToUse = expandAllObj[parentOption] ? 'angle down' : 'angle right';

  const childOptionsToDisplay = _.map(childOptions, (item: SingleChildOption, key: string) => {
    const alreadyAttached = _.includes(alreadyAttachedLineItemKeys, key);
    const alreadySelected = !!_.size(_.find(selectedLineItemSession, ['lineItemDisplayName', item.lineItemDisplayName]));
    return (
      <LineItemDisplay
        key={`${parentOption} - ${item.extId}`}
        text={item.lineItemDisplayName}
        item={item}
        onClick={(i) => {
          if (!alreadyAttached) {
            handleChildOptionClick(i, key);
          }
        }}
        checked={alreadySelected || alreadyAttached}
        style={POP_UP_FORM.childOptionContainer}
        disabled={alreadyAttached}
      />
    );
  });

  const isChecked = allChildOptionsSelectedInSingleBudgetGroup || allChildOptionsAlreadyAttached;
  const isIndeterminate = allChildOptionsSelected && !allChildOptionsSelectedInSingleBudgetGroup && !allChildOptionsAlreadyAttached;

  return (
    <div>
      <Accordion key={`${parentOption}`}>
        <Accordion.Title active={expandAllObj[parentOption]}>
          <div style={POP_UP_FORM.filterTitle}>
            <Icon
              size="large"
              name={iconToUse}
              style={POP_UP_FORM.parentOptAccordionIcon}
              onClick={handleParentAccordionClick}
            />
            <Checkbox
              className="pop-up-form-checkbox"
              checked={isChecked}
              disabled={allChildOptionsAlreadyAttached}
              indeterminate={isIndeterminate}
              onClick={handleParentCheckBoxClick}
            />
            <span
              onClick={handleParentCheckBoxClick}
              style={allChildOptionsAlreadyAttached ? POP_UP_FORM.disabledParentOptionText : POP_UP_FORM.parentOptionText}
            >
              {parentOption}
            </span>
          </div>
        </Accordion.Title>
        <Accordion.Content active={showChildren} style={{ padding: '0px 42px' }}>
          {childOptionsToDisplay}
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default ParentAccordion;
