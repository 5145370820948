import React from 'react';
import { Header } from 'buildingBlocks';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetGroup } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupInfoFieldProps = {
  name: string
  accordionCollapsed?: boolean
  collapsedContent?: string
};

const BudgetGroupInfoField = ({
  name,
  accordionCollapsed,
  collapsedContent,
}:BudgetGroupInfoFieldProps) => {
  const headerContent = accordionCollapsed ? `${name}:` : name;
  return (
    <div style={budgetGroup.infoSectionContainer}>
      <div style={budgetGroup.infoSection}>
        <Header
          as="h6"
          content={headerContent}
          style={accordionCollapsed ? budgetGroup.infoSectionText : budgetGroup.headerText}
        />
        {accordionCollapsed && (<p style={budgetGroup.infoSectionText}>{collapsedContent}</p>)}
      </div>
    </div>
  );
};

export default BudgetGroupInfoField;
