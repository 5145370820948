import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'buildingBlocks';
import { COPILOT_COLORS } from 'globalStyles';
import { RED_INFO_CIRCLE_SRC, TEAL_INFO_CIRCLE_SRC } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { GOAL_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { pluralizer } from 'utils/formattingUtils';

const { NEW_DESIGN_SYSTEM: { REDS } } = COPILOT_COLORS;

type Props = {
  numberSelected: number
  onClick: Dispatch<SetStateAction<boolean>>
  modalOpen: boolean
};

const CustomButton = ({ numberSelected, onClick, modalOpen }: Props) => {
  const textStyling = numberSelected === 0 ? { ...GOAL_SECTION_STYLES.pixelPickerText, color: REDS.R500_WATERMELON } : GOAL_SECTION_STYLES.pixelPickerText;
  const infoCircleSrc = numberSelected === 0 ? RED_INFO_CIRCLE_SRC : TEAL_INFO_CIRCLE_SRC;
  const pixelText = pluralizer('Pixel', 'Pixels')(numberSelected);

  return (
    <>
      {!modalOpen && (
        <div style={GOAL_SECTION_STYLES.pixelPickerContainer}>
          <img src={infoCircleSrc} alt="infocircle" />
          <span style={textStyling}>{numberSelected} Conversion {pixelText} Selected</span>
        </div>
      )}
      <Button
        // @ts-ignore
        onClick={onClick}
        type="button"
        content="Configure"
        style={GOAL_SECTION_STYLES.confirmButton}
      />
    </>
  );
};

export default CustomButton;
