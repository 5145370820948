import _ from 'lodash';
import React from 'react';
import { Icon, Popup } from 'buildingBlocks';
import { MomentTimeUnits } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData, SingleChildOption } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { dateDifference, displayDate, getIntervalDateToUse } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { Flight } from 'utils/types';
import { getMemberByParentExtId } from '../utils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import LineItemPopUpContent from './LineItemPopUpContent';

const { budgetGroup: budgetGroupStyle, budgetBanner } = BUDGET_MANAGEMENT_STYLES;

type SingleLineItemProps = {
  lineItem: SingleChildOption
  budgetAllocationData: BudgetAllocationData
  attachedFlights: Array<Flight>
};

const SingleLineItem = ({
  lineItem,
  budgetAllocationData,
  attachedFlights,
}: SingleLineItemProps) => {
  const parentObjExtId = lineItem.parentId;

  const parentObjectName = _.get(budgetAllocationData, 'hierarchy.parentSettings.name');
  const childExtIdToSettings = _.get(budgetAllocationData, 'hierarchy.childExtIdToSettings');
  const invalidData = _.get(budgetAllocationData, 'hierarchy.invalidData');
  const origToClone = _.get(budgetAllocationData, 'origToClone');
  const lineItemMember = getMemberByParentExtId(attachedFlights, parentObjExtId);

  const childData = lineItem?.extId ? childExtIdToSettings[lineItem?.extId] : childExtIdToSettings[lineItem?.origExtId];
  const cloneExtId = _.get(origToClone, `${lineItem?.origExtId}`);
  const childExtId = _.get(lineItem, 'extId') ?? cloneExtId;
  // handles populating StrategyType of an attached Line Item that expired
  const invalidChildExtId = _.get(lineItem, 'extId') ?? _.get(lineItem, 'origExtId');
  const lineItemExtType = _.get(childData, 'extType') ?? _.get(invalidData[invalidChildExtId], 'extType');
  const flightTimezone = _.get(_.find(attachedFlights, ['externalId', parentObjExtId]), 'timezone');

  const startDate = _.get(childData, 'startDate');
  const endDate = _.get(childData, 'endDate');
  const flightDuration = `${displayDate(startDate, lineItemExtType, flightTimezone)} - ${displayDate(endDate, lineItemExtType, flightTimezone)}`;
  const daysRemaining = dateDifference(getIntervalDateToUse(startDate), getIntervalDateToUse(endDate), MomentTimeUnits.days);

  // if lineItem is a clone, we check to see if there's the extId
  const lineItemText = (lineItem?.origExtId && cloneExtId) ? `${lineItem.lineItemDisplayName} (${cloneExtId})` : lineItem.lineItemDisplayName;

  return (
    <div style={budgetGroupStyle.selectedLineItemsContainer}>
      <p style={budgetGroupStyle.lineItemText}>{lineItemText}</p>
      <Popup
        size="small"
        on="hover"
        position="bottom center"
        trigger={(
          <Icon
            name="info"
            size="tiny"
            bordered
            circular
            style={budgetBanner.infoCircle}
          />
        )}
        content={(
          <LineItemPopUpContent
            lineItemName={lineItemText}
            childExtId={childExtId}
            lineItemExtType={lineItemExtType}
            parentObjectName={parentObjectName}
            lineItemMember={lineItemMember}
            flightDuration={flightDuration}
            daysRemaining={daysRemaining}
          />
        )}
      />
    </div>
  );
};

export default SingleLineItem;
