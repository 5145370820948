import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { Button, Divider, Icon, Popup } from 'buildingBlocks';
import { VISIBLE_LINE_ITEM_LIMIT } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { BudgetAllocationData, ChildOptions, SingleChildOption } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { WizardFormValues } from 'containers/StrategyWizard/types';
import { getStyleForLineItemContainer, truncateLineItems } from '../utils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import SingleLineItem from './SingleLineItem';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type DisplaySelectedLineItemsProps = {
  lineItemsToDisplay: ChildOptions
  budgetData: BudgetAllocationData
};

const DisplaySelectedLineItems = ({
  lineItemsToDisplay,
  budgetData,
}: DisplaySelectedLineItemsProps) => {
  const [openPopUp, setOpenPopUp] = useState<boolean>(false);
  const {
    attachFlightsStep: {
      attachedFlights,
    },
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const numOfLineItems = _.size(lineItemsToDisplay);

  // sorting by extId/origExtId to ensure clone always appears after original
  // eslint-disable-next-line newline-per-chained-call
  const sortedLineItems = _(lineItemsToDisplay).toPairs().sortBy((lI) => lI[1]?.extId ?? lI[1]?.origExtId).fromPairs().value();
  const [firstNLineItems, remainingLineItems] = truncateLineItems(sortedLineItems, VISIBLE_LINE_ITEM_LIMIT);
  const allLineItems = _.map(firstNLineItems, (lineItem: SingleChildOption, key: string) => (
    <SingleLineItem
      key={key}
      lineItem={lineItem}
      budgetAllocationData={budgetData[lineItem.parentId]}
      attachedFlights={attachedFlights}
    />
  ));

  // since each line item has a clone, need to get a handle on the Nth line item to display in show all
  const firstFiveLineItems = _.slice(allLineItems, 0, VISIBLE_LINE_ITEM_LIMIT - 1);
  const keyOfNthLineItem = _.last(_.keys(firstNLineItems));
  const restOfLineItems = numOfLineItems >= VISIBLE_LINE_ITEM_LIMIT
    ? { [keyOfNthLineItem]: { ...firstNLineItems[keyOfNthLineItem] }, ...remainingLineItems }
    : {};

  const showAllDiv = (
    <Popup
      on="click"
      basic
      trigger={(
        <div style={budgetGroupStyle.selectedLineItemsContainer}>
          {_.size(restOfLineItems)} More Objects <Icon name="angle right" size="large" style={budgetGroupStyle.showAllIcon} />
        </div>
      )}
      open={openPopUp}
      onClose={() => setOpenPopUp(false)}
      onOpen={() => setOpenPopUp(true)}
      key="popupDiv"
    >
      <Popup.Content>
        <div style={{ width: '500px' }}>
          <div style={budgetGroupStyle.showAllContainer}>
            {_.map(restOfLineItems, (_lineItem, lineItemName: string) => (
              <div key={lineItemName} style={budgetGroupStyle.showAllLineItemText}>
                {lineItemName}
              </div>
            ))}
          </div>
          <Divider style={{ margin: 5 }} />
          <div style={budgetGroupStyle.showAllFooter}>
            <Button
              content="Done"
              onClick={() => setOpenPopUp(false)}
              style={budgetGroupStyle.showAllDoneBtn}
            />
          </div>
        </div>
      </Popup.Content>
    </Popup>
  );

  return (
    <div style={getStyleForLineItemContainer(numOfLineItems)}>
      {numOfLineItems > VISIBLE_LINE_ITEM_LIMIT
        ? [...firstFiveLineItems, showAllDiv]
        : allLineItems}
    </div>
  );
};

export default DisplaySelectedLineItems;
