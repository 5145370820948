import _ from 'lodash';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import FormSectionHeader from 'components/FormSectionHeader';
import { Button, ButtonProps, Form, Message, Input } from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { ButtonClickHandler, StrategyType } from 'utils/types';
import CustomTextArea from 'components/CustomTextArea';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import LeafNameToggle from './LeafNameToggle';

const InputWrapper = validatedFieldWrapper(Input);

type Props = {
  strategyType: StrategyType
  handleAdd: ButtonClickHandler
  handleRemove: ButtonClickHandler
  isCustomTreeValidating: boolean
  control: Control
  onValidateCustomTreeClick: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void
  cannotValidateTree: boolean
  isCustomTreeValid: boolean
  validationOutput: string
};

const TreeText = ({
  strategyType, isCustomTreeValidating, cannotValidateTree, isCustomTreeValid,
  validationOutput, control, handleAdd, handleRemove, onValidateCustomTreeClick,
}: Props) => (
  <>
    <Form.Field required>
      <FormSectionHeader
        title="Tree Text"
        help="Type your Bonsai Tree text inline."
        group={strategyType.displayName}
      >
        <LeafNameToggle
          onAdd={handleAdd}
          onRemove={handleRemove}
        />
      </FormSectionHeader>
    </Form.Field>
    <Form.Field required disabled={isCustomTreeValidating}>
      <Controller
        name="customTreeInput"
        control={control}
        render={({ field }) => (
          <CustomTextArea
            name="customTreeInput"
            disabled={isCustomTreeValidating}
            height={557}
            mode="python"
            onChange={field.onChange}
            {..._.omit(field, 'ref')}
          />
        )}
      />
    </Form.Field>
    <div style={{ textAlign: 'center' }}>
      <Controller
        name="validateCustomTree"
        control={control}
        render={({ field }) => (
          <Button
            primary
            name="validateCustomTree"
            size="small"
            content="Validate tree"
            loading={isCustomTreeValidating}
            onClick={onValidateCustomTreeClick}
            disabled={cannotValidateTree}
            style={cannotValidateTree ? { ...strategyWizardStyles.nextButtonStyle, pointerEvents: 'none' } : strategyWizardStyles.nextButtonStyle}
            {...field}
          />
        )}
      />
    </div>
    <div style={{ marginTop: '10px', marginLeft: '20%', marginRight: '20%', textAlign: 'center' }}>
      {validationOutput
                && (
                  <Message size="mini" color={isCustomTreeValid ? 'green' : 'red'}>
                    <Message.Content>{validationOutput}</Message.Content>
                  </Message>
                )}
    </div>
    <Form.Field required>
      {/** hidden field to track page validity */}
      <Controller
        name="pageValidator"
        control={control}
        render={({ field, fieldState }) => (
          <InputWrapper
            name="pageValidator"
            type="hidden"
            onChange={field.onChange}
            fieldState={fieldState}
            field={field}
            {..._.omit(field, ['value', 'ref'])}
          />
        )}
      />
    </Form.Field>
  </>
);

export default TreeText;
