import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import AttachFlightsStep from 'containers/StrategyWizard/steps/AttachFlights/AttachFlightsStep';
import Confirm from 'containers/StrategyWizard/steps/StrategyConfirmation/Confirm';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { StrategyConfig, User } from 'utils/types';
import { WidgetWrapper, clearAllWidgetFieldsBesidesEmail } from 'utils/widget';
import { Router } from 'utils/withRouter';
import { WizardSteps, WIZARD_STEPS } from '../constants';
import GoalSelection from '../steps/GoalSelection/index';
import { QSParams, StrategyWizardResponse } from '../hooks';
import StrategyConfigurationStep from '../steps/StrategyConfiguration/StrategyConfigurationStep';
import { AttachFlightsInfoType } from '../types';
import RevenueTypeProvider from '../steps/GoalSelection/contexts/GoalSectionProvider';

type Props = {
  strategyId: number
  strategyConfig: StrategyConfig
  strategyTypeId: number
  qsParams: QSParams
  attachFlightsInfo: AttachFlightsInfoType
  setAttachFlightsInfo: (x: any) => void
  strategyState: StrategyWizardResponse
  canAccessCrossPlatform: boolean
  router: Router
};

// eslint-disable-next-line consistent-return
const EditExistingStrategyRoutes = (props: Props) => {
  const {
    strategyId,
    strategyConfig,
    strategyTypeId,
    qsParams,
    attachFlightsInfo,
    setAttachFlightsInfo,
    strategyState,
    canAccessCrossPlatform,
    router,
  } = props;

  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const { location: { pathname } } = router;

  useMount(() => {
    if (strategyId) {
      WidgetWrapper('prefill', 'ticketForm', { custom_fields: { cf_copilot_strategy_id: `${strategyId}` } });
    }

    // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
    return function cleanUp() { clearAllWidgetFieldsBesidesEmail(user.email); };
  });

  if (pathname === '/strategies/wizard/1') return null;

  if (pathname === `/strategies/wizard/${strategyId}/${WIZARD_STEPS[WizardSteps.attachFlightsStep].id}`) {
    return (
      <AttachFlightsStep
        strategyId={strategyId}
        qsParams={qsParams}
        attachFlightsInfo={attachFlightsInfo}
        setAttachFlightsInfo={setAttachFlightsInfo}
        canAccessCrossPlatform={canAccessCrossPlatform}
        router={router}
      />
    );
  }
  if (_.includes(pathname, `/strategies/wizard/${strategyId}/${WIZARD_STEPS[WizardSteps.goalSelectionStep].id}`)) {
    return (
      <RevenueTypeProvider>
        <GoalSelection strategyId={strategyId} qsParams={qsParams} />
      </RevenueTypeProvider>
    );
  }
  if (pathname === `/strategies/wizard/${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfigurationStep].id}`) {
    return (
      <StrategyConfigurationStep
        strategyId={strategyId}
        strategyConfig={strategyConfig}
        qsParams={qsParams}
        strategyTypeId={strategyTypeId}
      />
    );
  }
  if (pathname === `/strategies/wizard/${strategyId}/${WIZARD_STEPS[WizardSteps.strategyConfirmationStep].id}`) {
    return (
      <Confirm
        strategyId={strategyId}
        strategyState={strategyState}
      />
    );
  }
};

export default EditExistingStrategyRoutes;
