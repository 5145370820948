import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;
const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;
const { toolTipStyle } = BAStyle;

export const BUDGET_MANAGEMENT_STYLES: any = {
  budgetAllocation: {
    intelligenceContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[16],
    },
    intelligenceSection: {
      display: 'flex',
      justifyContent: 'space-between',
      color: NEUTRALS.N600_ABBEY,
    },
    budgetManagementHeaderContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY,
    },
    sectionHeader: {
      ...HEADING.H5,
      margin: 0,
      color: NEUTRALS.N600_ABBEY,
    },
    budgetManagementText: {
      ...BODY.SMALL,
      marginBottom: '18px',
      color: NEUTRALS.N600_ABBEY,
    },
    intelligenceSectionText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
    },
  },
  budgetParent: {
    outerContainer: {
      display: 'flex',
      width: '100%',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      borderLeft: `4px solid ${BLUES.B500_WAVE}`,
      height: '120px',
      padding: '16px 20px',
      background: NEUTRALS.N50_ALABASTER,
      borderRadius: '3px',
      gap: SPACING[8],
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    textSection: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '42%',
    },
    strategyNameHeader: {
      ...HEADING.H5,
      margin: 0,
      color: NEUTRALS.N800_MATTER,
    },
    headerText: {
      ...HEADING.H6,
      color: NEUTRALS.N600_ABBEY,
    },
    subHeaderText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: 3,
      textOverflow: 'ellipsis',
      wordBreak: 'break-all',
    },
    stratName: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      width: '55%',
      overflowWrap: 'break-word',
    },
    toolTip: {
      ...toolTipStyle,
      fontSize: SPACING[20],
      margin: 0,
      color: NEUTRALS.N400_GRANITE,
      marginLeft: SPACING[8],
    },
  },
  budgetGroup: {
    outerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      borderRadius: SPACING[4],
      padding: SPACING[16],
    },
    collapsedOuterContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      borderRadius: SPACING[4],
      padding: '8px 16px',
    },
    accordionContentContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      padding: '24px 24px 0 20px',
    },
    popUpFormContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    showAllFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '8px 0',
    },
    showAllDoneBtn: {
      width: '100px',
      background: NEUTRALS.N0_WHITE,
      color: NEUTRALS.N600_ABBEY,
    },
    budgetGroupContainer: {
      display: 'flex',
      width: '100%',
      height: '66px',
    },
    showAllContainer: {
      maxHeight: '480px',
      overflowY: 'scroll',
    },
    collapsedBudgetGroupContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    expandedBudgetGroupContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '4px 0',
      alignItems: 'center',
    },
    expandedBudgetGroupInfo: {
      display: 'flex',
      flexDirection: 'column',
      width: '20%',
    },
    selectedLineItemsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      borderRadius: SPACING[4],
      padding: SPACING[8],
      height: SPACING[32],
    },
    budgetGroupInfoContainer: {
      display: 'flex',
      width: '100%',
      padding: '4px 0',
      alignItems: 'center',
      gap: SPACING[4],
    },
    budgetGroupInfoFirstHalf: {
      display: 'flex',
      width: '55%',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    budgetGroupInfoSecondHalf: {
      display: 'flex',
      width: '42%',
      justifyContent: 'center',
      gap: SPACING[16],
    },
    editBudgetGroupNameContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    budgetGroupNameContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '75%',
    },
    singleRowLineItemContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridTemplateRows: 'repeat(1, 1fr)',
      gap: SPACING[8],
      paddingBottom: SPACING[16],
    },
    budgetAllocationBtn: {
      ...HEADING.H6,
      background: NEUTRALS.N0_WHITE,
      border: `1px solid ${NEUTRALS.N300_ALTO}`,
      borderRadius: SPACING[4],
      color: NEUTRALS.N800_MATTER,
    },
    budgetGroupsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: SPACING[16],
    },
    lineItemPopUpContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '450px',
      minHeight: '165px',
      gap: SPACING[8],
    },
    lineItemPopUpHeader: {
      ...HEADING.H6,
      margin: 0,
      color: NEUTRALS.N800_MATTER,
      wordBreak: 'break-all',
    },
    lineItemPopUpText: {
      ...DESCRIPTION.REGULAR,
      color: NEUTRALS.N600_ABBEY,
      margin: 0,
      wordBreak: 'break-all',
    },
    lineItemText: {
      ...BODY.SMALL,
      color: NEUTRALS.N800_MATTER,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      margin: 0,
      maxWidth: '500px',
    },
    showAllLineItemText: {
      ...DESCRIPTION.REGULAR,
      color: NEUTRALS.N800_MATTER,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      padding: '4px 0',
    },
    infoSectionContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      width: '100%',
    },
    infoSection: {
      display: 'flex',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    infoSectionText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
      margin: 0,
    },
    editBudgetGroupNameInputField: {
      ...HEADING.H6,
      marginRight: '10px',
      width: '100%',
      borderBottom: '1px solid',
      marginBottom: SPACING[8],
    },
    budgetGroupNameHeader: {
      ...HEADING.H5,
      margin: 0,
      paddingRight: SPACING[8],
      color: NEUTRALS.N800_MATTER,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    accordionOpenIcon: {
      color: NEUTRALS.N900_BLACK,
      margin: '4px 4px 0 0',
    },
    accordionCloseIcon: {
      color: NEUTRALS.N900_BLACK,
      margin: '0 4px 4px 0',
    },
    deleteIcon: {
      color: NEUTRALS.N600_ABBEY,
      margin: '0 0 4px 0',
    },
    headerText: {
      ...HEADING.H6,
      color: NEUTRALS.N600_ABBEY,
      margin: '0 8px 0 0',
    },
    showAllIcon: {
      color: NEUTRALS.N400_GRANITE,
      width: '2%',
    },
    subHeaderText: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      WebkitLineClamp: 3,
      textOverflow: 'ellipsis',
      width: '100%',
      paddingTop: SPACING[12],
    },
    allocationRangeContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      paddingTop: SPACING[8],
    },
    allocationRangeDivider: {
      width: '14%',
      textAlign: 'right',
      paddingRight: SPACING[4],
    },
    displayBudgetGroupName: {
      display: 'flex',
      width: '100%',
    },
    budgetGroupNameIcon: {
      fontSize: SPACING[16],
      color: NEUTRALS.N400_GRANITE,
      marginTop: SPACING[2],
    },
  },
  budgetBanner: {
    container: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      border: `1px solid ${NEUTRALS.N200_LINEN}`,
      background: NEUTRALS.N0_WHITE,
      padding: SPACING[16],
      height: SPACING[56],
      borderRadius: SPACING[4],
    },
    text: {
      ...BODY.SMALL,
      color: NEUTRALS.N600_ABBEY,
    },
    infoCircle: {
      boxShadow: `0 0 0 1px ${NEUTRALS.N600_ABBEY} inset`,
      color: NEUTRALS.N600_ABBEY,
    },
  },
};
