/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button, Popup, Input } from 'buildingBlocks';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import {
  BudgetGroupOptions,
  ChildOptions,
  ExpandAllOptionsObject,
  FilterButtonState,
  BudgetGroupLineItemMapping,
  ParentOptions,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { usePrevious } from 'utils/hooks/usePrevious';
import { POP_UP_FORM } from './style';
import FormOptions from './FormOptions';
import PopUpFooter from './PopUpFooter';

type PopUpFormProps = {
  buttonTitle: string
  groupedOptions: BudgetGroupOptions
  selectedLineItems: ChildOptions
  budgetGroupToLineItems: BudgetGroupLineItemMapping
  budgetGroupKey: string
  allActiveChildOptions: ChildOptions
  hasObjectsAttached: React.MutableRefObject<boolean>
  saveBudgetGroupToGroupSettings: Function
};

const PopUpForm = ({
  buttonTitle,
  groupedOptions,
  selectedLineItems,
  budgetGroupToLineItems,
  budgetGroupKey,
  allActiveChildOptions,
  hasObjectsAttached,
  saveBudgetGroupToGroupSettings,
}: PopUpFormProps) => {
  // source for accordion logic. Should only display collapseAll when all parentAccordions are open
  const expandAllOptionsObj = useMemo<ExpandAllOptionsObject>(() => _.reduce(groupedOptions, (acc: ExpandAllOptionsObject, options: ParentOptions) => {
    _.forEach(options, (_childOptions, parentKey: string) => {
      acc[parentKey] = true;
    });
    return acc;
  }, {}), [groupedOptions]);

  const [expandAllObj, setExpandAllObj] = useState<ExpandAllOptionsObject>(expandAllOptionsObj);
  const [search, setSearch] = useState<string>('');
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [selectedLineItemSession, setSelectedLineItemSession] = useState<ChildOptions>(selectedLineItems);
  const [filterBtnState, setFilterBtnState] = useState<FilterButtonState>(FilterButtonState.all);

  const { clearErrors } = useFormContext<StrategyConfigurationStep>();

  const prev = usePrevious<ChildOptions>(selectedLineItems);

  const alreadyAttachedLineItems = _.size(budgetGroupToLineItems) && _.reject(budgetGroupToLineItems, (_lI, key: string) => key === budgetGroupKey);
  const alreadyAttachedLineItemKeys = _.chain(alreadyAttachedLineItems).map((lI) => _.keys(lI)).flatten().value();
  const availableLIs = _.size(allActiveChildOptions) - (_.size(selectedLineItemSession) + _.size(alreadyAttachedLineItemKeys));
  const numAvailable = availableLIs > 0 ? availableLIs : 0;

  useEffect(() => {
    setSelectedLineItemSession(selectedLineItems);
  }, [selectedLineItems]);

  useEffect(() => {
    setExpandAll(_.every(expandAllObj));
  }, [expandAllObj]);

  const onExitPopup = () => {
    setSelectedLineItemSession(prev);
    setPopupOpen(false);
    setSearch('');
  };

  const handleExpandAllClick = () => {
    // need to explicitly set to true/false since each values can be offset via individual accordion click
    if (expandAll) {
      setExpandAllObj(_.mapValues(expandAllObj, () => false));
    } else {
      setExpandAllObj(_.mapValues(expandAllObj, () => true));
    }
    setExpandAll(!expandAll);
  };

  const handleSaveLineItems = () => {
    // hack to sync error so line item error does not display when trying to add objects initally
    clearErrors((`groupSettings[${budgetGroupKey}].childExtIds` as any));
    saveBudgetGroupToGroupSettings(selectedLineItemSession);
    setPopupOpen(false);
    setSearch('');
    // eslint-disable-next-line no-param-reassign
    hasObjectsAttached.current = true;
  };

  return (
    <Popup
      on="click"
      basic
      trigger={(
        <Button
          icon="plus"
          type="button"
          content={buttonTitle}
          style={POP_UP_FORM.popUpFormButton}
        />
      )}
      open={popupOpen}
      onOpen={() => setPopupOpen(true)}
      onClose={onExitPopup}
    >
      <Popup.Content>
        <div style={POP_UP_FORM.individualFilterPopUpContainer}>
          <Input
            className="pop-up-form-search"
            icon="search"
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            fluid
          />
          <div style={POP_UP_FORM.filterSection}>
            <div style={{ display: 'flex' }}>
              <p style={POP_UP_FORM.textButton} onClick={handleExpandAllClick}>
                {!expandAll ? 'Expand All' : 'Collapse All'}
              </p>
              <p style={POP_UP_FORM.textButton} onClick={() => setSelectedLineItemSession(_.omit(allActiveChildOptions, alreadyAttachedLineItemKeys))}>
                Select All
              </p>
              <p style={POP_UP_FORM.textButton} onClick={() => setSelectedLineItemSession({})}>
                Deselect All
              </p>
            </div>
            <div style={POP_UP_FORM.filterBtnContainer}>
              <Button
                style={filterBtnState === FilterButtonState.all ? POP_UP_FORM.selectedFilterBtn : POP_UP_FORM.filterBtn}
                size="tiny"
                content="All"
                onClick={() => setFilterBtnState(FilterButtonState.all)}
              />
              <div style={POP_UP_FORM.verticalDivider} />
              <Button
                style={filterBtnState === FilterButtonState.selected ? POP_UP_FORM.selectedFilterBtn : POP_UP_FORM.filterBtn}
                size="tiny"
                content="Selected"
                onClick={() => setFilterBtnState(FilterButtonState.selected)}
              />
            </div>
          </div>
          <FormOptions
            groupedOptions={groupedOptions}
            search={search}
            selectedLineItemSession={selectedLineItemSession}
            setSelectedLineItemSession={setSelectedLineItemSession}
            alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
            filterBtnState={filterBtnState}
            expandAllObj={expandAllObj}
            setExpandAllObj={setExpandAllObj}
          />
          <PopUpFooter
            selectedLineItemSession={selectedLineItemSession}
            numAvailable={numAvailable}
            onExitPopup={onExitPopup}
            handleSaveLineItems={handleSaveLineItems}
          />
        </div>
      </Popup.Content>
    </Popup>
  );
};

export default PopUpForm;
