import React from 'react';
import { Menu, Header } from 'buildingBlocks';
import { header } from 'components/SubNav/style';

type Props = {
  strategyName?: string
  isNewStrategy: boolean
};

const StrategyWizardHeader = ({ strategyName = '', isNewStrategy }: Props) => {
  const title = `Edit Strategy | ${strategyName}`;
  return (
    <Menu.Item fitted style={{ margin: 0, minWidth: 0, flexBasis: '85%' }}>
      <Header style={header}>{isNewStrategy ? 'Create Strategy' : title}</Header>
    </Menu.Item>
  );
};

export default StrategyWizardHeader;
