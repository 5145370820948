import _ from 'lodash';
import React, { ChangeEventHandler, SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Input, Form, PercentageInput as PercentageInputSemantic, Label, Checkbox,
} from 'buildingBlocks';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';

export const InputWrapper = validatedFieldWrapper(Input);
export const PercentageWrapper = validatedFieldWrapper(PercentageInputSemantic);

type RHFInputWrapperProps = {
  name: string
  component: typeof React.Component
  label?: string
  title?: string
  placeholder?: string
  type?: string
  required?: boolean
  onChange?: Function
  disabled?: boolean
};

export const RHFInputWrapper = ({ name, component: Component, onChange, required, ...rest }: RHFInputWrapperProps) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={required ? { required: true } : {}}
      render={({ field, fieldState }) => {
        const customOnChange = (e: SyntheticEvent, v: { value: string | number }) => {
          if (onChange) {
            onChange(e);
          }
          field.onChange(v.value);
        };
        return (
          <Component
            fluid
            field={field}
            fieldState={fieldState}
            onChange={customOnChange}
            {...rest}
          />
        );
      }}
    />
  );
};

/* eslint-disable react/default-props-match-prop-types */
RHFInputWrapper.defaultProps = {
  component: InputWrapper,
};
/* eslint-enable */

export const TextInput = (props: RHFInputWrapperProps) => (
  <RHFInputWrapper type="text" component={InputWrapper} {...props} />
);

export const NumberInput = (props) => (
  <RHFInputWrapper
    type="number"
    step="any"
    component={InputWrapper}
    {...props}
  />
);

export const CurrencyInput = (props) => (
  <RHFInputWrapper
    type="number"
    component={InputWrapper}
    labelPosition="right"
    label={_.get(props, 'currency.code')}
    {...props}
  />
);

export const DualLabelInput = (props: { value: number, onChange: ChangeEventHandler }) => (
  <div>
    <Input fluid labelPosition="right" type="text">
      <Label>Last</Label>
      <input
        type="number"
        onChange={props.onChange}
        value={props.value}
      />
      <Label>Days</Label>
    </Input>
  </div>
);

export const PercentageInputRedux = (props: RHFInputWrapperProps) => (
  <RHFInputWrapper component={PercentageWrapper} {...props} />
);

// Dropdown component is not a validated field wrapper type of, so we need to manually add the label
export const DropdownInput = <T extends {}>({ name, label, ...rest }: T & RHFInputWrapperProps) => (
  <Form.Field>
    <label htmlFor={name}>{label}</label>
    <RHFInputWrapper name={name} {...rest} selection />
  </Form.Field>
);

type RFCheckboxProps = {
  input: { onChange: Function },
};

export const RFCheckbox = ({ input, ...rest } : RFCheckboxProps) => (
  <Checkbox onChange={(_e, checkbox) => input.onChange(checkbox.checked)} {..._.omit(rest, 'meta')} />
);
