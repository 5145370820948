import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState, ChangeEvent, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input';
import { Icon, Modal, Input, Button } from 'buildingBlocks';
import { WizardFormGoalSelection } from 'containers/StrategyWizard/types';
import { isEngScoreGoalType } from 'containers/StrategyWizard/ConfigurationByStrategyType/utils';
import { Pixel } from 'utils/types';
import CustomButton from './CustomButton';
import CategorySwitcher from './PixelPickerModal/CategorySwitcher';
import PixelTable from './PixelPickerModal/PixelTable';
import { DspToPixel, DspToPixelToWeightsType, PixelCategories } from './types';
import { PIXEL_PICKER_STYLES } from './styles';
import { getHeaderText, getTotalMatchingPixelCount } from './utils';

const {
  modal, closeIcon, modalHeader, modalContent, configurationContainer, description,
  search, searchIcon, footerContainer, errorMessage, cancelBtn, confirmBtn,
} = PIXEL_PICKER_STYLES;

type PixelPickerModalProps = {
  pixels: DspToPixel
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  goalType: string
  modalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
  onChange: Function
};

const PixelPickerModal = (props: PixelPickerModalProps) => {
  const { pixels, defaultDspToPixelToWeights, goalType, modalOpen, setModalOpen, onChange } = props;
  const impValueFilters = useWatch<WizardFormGoalSelection>({ name: 'goal.impValueFilters' }) as DspToPixel; // pixels currently saved on the form

  const [selectedModalPixels, setSelectedModalPixels] = useState<DspToPixel>(impValueFilters);
  const [searchStr, setSearchStr] = useState<string>('');
  const [pixelCategory, setPixelCategory] = useState<PixelCategories>(PixelCategories.all);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // clear error every time user makes any changes in the modal
    setError('');
  }, [selectedModalPixels]);

  const searchCleared = _.isEmpty(searchStr);
  const isEngScore = isEngScoreGoalType(goalType);
  const numberSelected = _
    .chain(impValueFilters)
    .values()
    .flatten()
    .size()
    .value();

  const selectionValidated = () => {
    // check that at least one pixel is selected per dsp
    if (!_.every(_.keys(pixels), (dsp: string) => (selectedModalPixels[dsp] && (_.size(selectedModalPixels[dsp]) >= 1)))) {
      setError('You must select at least one pixel per platform.');
      return false;
    }
    // check that weights are configured for all selected pixels when goal type is engagement score
    if (isEngScore && !_.every(_.flatten(_.values(selectedModalPixels)), (p: Pixel) => _.isNumber(p.weight))) {
      setError('Please enter weightings for all selected pixels.');
      return false;
    }
    // check that all configured weights are greater than or equal to 0 for selected pixels when goal type is engagement score
    if (isEngScore && !_.every(_.flatten(_.values(selectedModalPixels)), (p: Pixel) => _.isNumber(p.weight) && p.weight >= 0)) {
      setError('Please enter a minimum weighting of 0 for all selected pixels.');
      return false;
    }
    return true;
  };

  const handleModalClose = () => {
    setSelectedModalPixels(impValueFilters);
    setSearchStr('');
    setModalOpen(false);
  };

  const handleConfirmClick = () => {
    if (selectionValidated()) {
      onChange(selectedModalPixels);
      setModalOpen(false);
    }
  };

  return (
    <Modal
      open={modalOpen}
      trigger={(
        <CustomButton
          onClick={() => setModalOpen(true)}
          numberSelected={numberSelected}
          modalOpen={modalOpen}
        />
      )}
      dimmer="blurring"
      style={modal}
    >
      <Icon
        name="times"
        style={closeIcon}
        onClick={handleModalClose}
      />
      <Modal.Header style={modalHeader}>{getHeaderText(goalType)}</Modal.Header>
      <Modal.Content style={modalContent}>
        <div style={configurationContainer}>
          <p style={description}>Select and assign weights to the conversion pixels which contribute towards the strategy goal.</p>
          <Input
            id="pixel-picker-search"
            icon={(
              <Icon
                name={searchCleared ? 'search' : 'times'}
                style={{ ...searchIcon, ...(!searchCleared && { cursor: 'pointer' }) }}
                onClick={searchCleared ? null : () => setSearchStr('')}
              />
            )}
            placeholder="Search by pixel name or ID"
            style={search}
            value={searchStr}
            onChange={(_e: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => setSearchStr(value)}
          />
          <CategorySwitcher
            pixelCategory={pixelCategory}
            setPixelCategory={setPixelCategory}
            allCount={getTotalMatchingPixelCount(pixels, searchStr)}
            selectedCount={getTotalMatchingPixelCount(selectedModalPixels, searchStr)}
          />
          <PixelTable
            isEngScore={isEngScore}
            pixelCategory={pixelCategory}
            searchStr={searchStr}
            pixels={pixels}
            selectedModalPixels={selectedModalPixels}
            setSelectedModalPixels={setSelectedModalPixels}
            defaultDspToPixelToWeights={defaultDspToPixelToWeights}
          />
        </div>
        <div style={footerContainer}>
          <div style={errorMessage}>{error}</div>
          <div>
            <Button
              style={cancelBtn}
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              style={confirmBtn}
              onClick={handleConfirmClick}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default PixelPickerModal;
