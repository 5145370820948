/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWatch, useFormContext } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Accordion } from 'buildingBlocks';
import {
  BudgetGroupOptions,
  BudgetGroup as BudgetGroupType,
  ChildOptions,
  BudgetGroupLineItemMapping,
  BudgetInterval,
  GroupSettings,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import ErrorMessage from 'components/ErrorComponent/ErrorMessage';
import PopUpForm from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/PopUpForm/PopUpForm';
import { BudgetSetting, StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { getTotalDelivered } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { Flight } from 'utils/types';
import BudgetGroupDisplayInfo from './BudgetGroupDisplayInfo';
import DisplaySelectedLineItems from './DisplaySelectedLineItems';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import { getAllBudgetGroupLineItems, getAttachedLineItems, getFieldErrors } from '../utils';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupProps = {
  groupedOptions: BudgetGroupOptions
  lifeTimeBudget: number
  budgetGroup: BudgetGroupType
  budgetGroupKey: string
  budgetGroupToLineItems: BudgetGroupLineItemMapping
  setBudgetGroupToLineItems: Dispatch<SetStateAction<BudgetGroupLineItemMapping>>
  requiredDailyValue: number
  budgetMetric: string
  generalAllocationRangeError: string
  remainingSpendDays: number
  interval: BudgetInterval | BudgetSetting
  allActiveChildOptions: ChildOptions
  budgetGroups: GroupSettings
};

const BudgetGroup = ({
  groupedOptions,
  lifeTimeBudget,
  budgetGroup,
  budgetGroupKey,
  budgetGroupToLineItems,
  setBudgetGroupToLineItems,
  requiredDailyValue,
  budgetMetric,
  generalAllocationRangeError,
  remainingSpendDays,
  interval,
  allActiveChildOptions,
  budgetGroups,
}: BudgetGroupProps) => {
  const attachedFlights = useSelector<GlobalState>((state) => state.strategyWizard.attachFlightsStep.attachedFlights) as Array<Flight>;
  const [accordionOpen, setAccordionOpen] = useState<boolean>(true);
  const [selectedLineItems, setSelectedLineItems] = useState<ChildOptions>({});
  const [lineItemsToDisplay, setLineItemsToDisplay] = useState<ChildOptions>({});
  const { setValue } = useFormContext<StrategyConfigurationStep>();
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });

  const hasObjectsAttached = useRef<boolean>(!!_.size(budgetGroup.childExtIds));
  const lineItemFieldErrors = getFieldErrors(budgetGroupKey, 'childExtIds');
  const {
    goalSelectionStep,
    budgetAllocationState,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const budgetData = _.get(budgetAllocationState, 'data');
  const budget = _.get(goalSelectionStep, 'budget');

  useEffect(() => {
    // creates clone for each attached line item for single DSP flow
    if (_.size(allActiveChildOptions)) {
      const prevAttachedLineItems = getAttachedLineItems(budgetGroup.childExtIds, allActiveChildOptions);
      const lineItemObjsToDisplay = intelligentChildObjectsEnabled ? getAllBudgetGroupLineItems(attachedFlights, prevAttachedLineItems, budgetData) : prevAttachedLineItems;
      setSelectedLineItems(prevAttachedLineItems);
      setLineItemsToDisplay(lineItemObjsToDisplay);
    }
  }, [budgetGroup.childExtIds, allActiveChildOptions, intelligentChildObjectsEnabled]);

  const saveBudgetGroupToGroupSettings = (updatedLineItems: ChildOptions) => {
    const lineItemsToCalculateSpend = intelligentChildObjectsEnabled
      ? getAllBudgetGroupLineItems(attachedFlights, updatedLineItems, budgetData)
      : updatedLineItems;
    const aggDeliveryObj = getTotalDelivered(budgetData, [interval], budget, lineItemsToCalculateSpend);
    const aggDelivery = _.head(_.values(aggDeliveryObj)) ?? 0;
    const updatedBudgetGroups = {
      ...budgetGroups,
      [budgetGroupKey]: {
        ...budgetGroups[budgetGroupKey],
        childExtIds: _.map(updatedLineItems, 'extId'),
        aggDelivery,
      },
    };
    setValue('groupSettings', updatedBudgetGroups);
    setBudgetGroupToLineItems({ ...budgetGroupToLineItems, [budgetGroupKey]: updatedLineItems });
    setSelectedLineItems(updatedLineItems);
  };

  return (
    <Accordion style={accordionOpen ? budgetGroupStyle.outerContainer : budgetGroupStyle.collapsedOuterContainer}>
      <Accordion.Title
        active={accordionOpen}
        style={{ width: '100%' }}
      >
        <BudgetGroupDisplayInfo
          budgetGroup={budgetGroup}
          lifeTimeBudget={lifeTimeBudget}
          accordionOpen={accordionOpen}
          setAccordionOpen={setAccordionOpen}
          budgetGroupKey={budgetGroupKey}
          requiredDailyValue={requiredDailyValue}
          budgetGroupToLineItems={budgetGroupToLineItems}
          setBudgetGroupToLineItems={setBudgetGroupToLineItems}
          budgetMetric={budgetMetric}
          generalAllocationRangeError={generalAllocationRangeError}
          remainingSpendDays={remainingSpendDays}
          budgetGroups={budgetGroups}
        />
      </Accordion.Title>
      {accordionOpen && (
        <Accordion.Content
          active={accordionOpen}
          style={budgetGroupStyle.accordionContentContainer}
        >
          <div style={budgetGroupStyle.popUpFormContainer}>
            {!!_.size(selectedLineItems) && (
              <DisplaySelectedLineItems
                lineItemsToDisplay={lineItemsToDisplay}
                budgetData={budgetData}
              />
            )}
            <PopUpForm
              buttonTitle="Add / Remove Objects"
              groupedOptions={groupedOptions}
              selectedLineItems={selectedLineItems}
              budgetGroupToLineItems={budgetGroupToLineItems}
              budgetGroupKey={budgetGroupKey}
              allActiveChildOptions={allActiveChildOptions}
              hasObjectsAttached={hasObjectsAttached}
              saveBudgetGroupToGroupSettings={saveBudgetGroupToGroupSettings}
            />
            {/* only render error if user has attempted to attach line items to budget group */}
            {(lineItemFieldErrors && hasObjectsAttached.current) && (
            <ErrorMessage
              style={{ margin: '8px 0' }}
              errorText={lineItemFieldErrors.message as string}
            />
            )}
          </div>
        </Accordion.Content>
      )}
    </Accordion>
  );
};

export default BudgetGroup;
