import _ from 'lodash';
import { MutableRefObject } from 'react';
import moment, { Moment } from 'moment';
import momentTimeZone from 'moment-timezone';
import { ALGORITHM_TYPE, DSP, FLIGHT_EXTERNAL_TYPE, GOAL_TYPES, RevenueType } from 'constantsBase';
import { assertUnreachable } from 'utils/types';
import { formatNumber, toSnakeCase } from 'utils/formattingUtils';
import { safeDivision } from 'utils/calculations';
import { ISO_DATE, SIMPLE_DATE_FORMAT } from 'utils/dateTime';
import { BaseAnalyticsDatum } from 'charts/BudgetOptimizationViz/types';
import { createIsoDate } from 'charts/utils';
import { getBrowserTimeZone } from 'containers/Login/sagas';
import { getParentExternalTypeByDSP } from 'containers/StrategyWizard/utils';
import { BudgetSetting, RevenueTypeConfig } from 'containers/StrategyWizard/types';
import { getBudgetKeyWithPrefix } from 'containers/StrategyWizard/steps/GoalSelection/utils';
import {
  SupportedExternalFlightTypes,
  BudgetAllocationData,
  EstimatedSpendMetrics,
  ParentMetrics,
  ChildExtIdToSettings,
  ChildSettingsData,
  BudgetInterval,
  ChildOptions,
} from './types';
import { BudgetTypes } from './constants';
import { getAdditionalConfigurations } from './mapper';

enum IntervalText {
  previous = 'Previous',
  next = 'Next',
  current = 'Current',
}
export const getChildDisplayName = (parentExternalTypeId: SupportedExternalFlightTypes) => {
  switch (parentExternalTypeId) {
    case FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id:
    case FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id:
    case FLIGHT_EXTERNAL_TYPE.amznOrder.id:
      return 'Line Items';
    case FLIGHT_EXTERNAL_TYPE.ttdCampaign.id:
    case FLIGHT_EXTERNAL_TYPE.wmtCampaign.id:
      return 'Ad Groups';
    default:
      return assertUnreachable(parentExternalTypeId);
  }
};

export const getIntervalText = (parentSettings: BudgetInterval, today = moment()) => {
  const parentIntervalEndDate = _.get(parentSettings, 'endDate');
  if (today.isAfter(moment(parentIntervalEndDate))) {
    const nextInterval = _.first(_.get(parentSettings, 'futureIntervals'));
    if (_.isEmpty(nextInterval)) {
      return IntervalText.previous;
    }
    return IntervalText.next;
  }
  return IntervalText.current;
};

/**
 * get the 'current' budget interval. if no current, use the next future interval.
 * if no future, use most recent past interval. the info attached to parentSettings is either the current,
 * or if there is no current (endDate in the past), it is the most recent interval.
 */
export const getInterval = (parentSettings: BudgetInterval, today = moment()) => {
  const text = getIntervalText(parentSettings, today);
  if (text === IntervalText.next) {
    // future interval obj doesn't have budgetType or currencyCode attributes
    return {
      ..._.first(parentSettings.futureIntervals),
      budgetType: parentSettings.budgetType,
      currencyCode: parentSettings.currencyCode,
    };
  }
  return parentSettings;
};

const getLifetimeEventBudget = (lifetimeBudget: number, revenueValue: number | string) => Math.round(_.toNumber(safeDivision(lifetimeBudget, _.toNumber(revenueValue))));

const getLifeTimeBudgetForCPM = (lifetimeBudget: number, revenueValue: number | string) => Math.round(_.toNumber(safeDivision(lifetimeBudget, _.toNumber(revenueValue)) * 1000));

export const getLifeTimeBudget = (interval: BudgetInterval | BudgetSetting, revenueValue?: string | number, revenueType?: RevenueType, displayInEvents: boolean = false): number => {
  // differentiate whether interval is a BudgetSetting or BudgetInterval type
  const intervalToUse = _.isNil(_.get(interval, 'budgetType')) ? _.get(interval, 'budget') : _.get(interval, 'budgetAmount');
  if (revenueValue && intervalToUse && !_.isNaN(+intervalToUse) && displayInEvents) {
    return (revenueType === GOAL_TYPES.cpm.value)
      ? getLifeTimeBudgetForCPM(intervalToUse, revenueValue)
      : getLifetimeEventBudget(intervalToUse, revenueValue);
  }
  if (_.get(interval, 'budgetType') === BudgetTypes.imps) {
    return _.get(interval, 'budgetImps');
  }
  return intervalToUse;
};

export const displayCurrencyOrImps = (parentSettings: BudgetInterval): string => {
  const budgetType = _.get(parentSettings, 'budgetType');
  if (_.isNil(budgetType) || budgetType === BudgetTypes.amount) {
    return _.get(parentSettings, 'currencyCode');
  }
  return _.upperCase(BudgetTypes.imps);
};

export const requiredDaily = (lifetimeBudget: number, delivery: number, remainingDays: number): number => {
  if (remainingDays <= 0) {
    return 0;
  }
  const dailyBudget = (lifetimeBudget - delivery) / remainingDays;
  return dailyBudget > 0 ? dailyBudget : 0;
};

export const displayEstimatedMinMax = (value: number): string => formatNumber(Math.round(value));

export const estimatedDailyMinMax = (parentRequiredDaily: number, dailyAllocation: number): number => parentRequiredDaily * dailyAllocation;

export const estimatedLifetimeMinMax = (
  delivery: number,
  cloneDelivery: number,
  remainingDays: number,
  estimatedDaily: number,
  includesIntelligentLineItems: boolean,
): number => {
  const totalDelivery = includesIntelligentLineItems ? delivery + cloneDelivery : delivery;
  const estimatedLifetimeMinMaxValue = totalDelivery + remainingDays * estimatedDaily;
  return Math.round(estimatedLifetimeMinMaxValue);
};

const getMetric = (
  cumData: _.Dictionary<BaseAnalyticsDatum>,
  dateInterval: string,
  revenueType: RevenueType,
  budgetType: string,
  clientEventRevenueValue?: string | number,
) => {
  const cumDataForDate = cumData[dateInterval];
  if (revenueType) {
    // eslint-disable-next-line default-case
    switch (revenueType) {
      case RevenueType.cpc:
        return _.get(cumDataForDate, 'clicks', 0) * _.toNumber(clientEventRevenueValue);
      case RevenueType.cpcv:
        return _.get(cumDataForDate, 'completes', 0) * _.toNumber(clientEventRevenueValue);
      case RevenueType.cpm:
        return safeDivision((_.get(cumDataForDate, 'imps', 0) * _.toNumber(clientEventRevenueValue)), 1000);
    }
  }
  return budgetType === BudgetTypes.amount ? _.get(cumDataForDate, 'revenue', 0) : _.get(cumDataForDate, 'imps', 0);
};

// single platform dates are provided as strings, cross-platform interval dates are objects
export const getIntervalDateToUse = (date: object | string) => (
  _.isString(date) ? createIsoDate(date) : date
);

/**
* This function returns, per the passed-in interval, the most recent non-zero metric value
* This fuction is called when either the endDate or the startDate value is zero or non-existing
* The starting point (for checking if there's a metric for a given date) is typically
* the date prior to the endDate of the interval, unless an override is supplied via the startingPointOverride
* The Override is used start the check date prior to the startDate of the next interval
*/
const getMostRecentNonZeroMetric = (
  cumData: _.Dictionary<BaseAnalyticsDatum>,
  interval: BudgetSetting | BudgetInterval,
  revenueType: RevenueType,
  clientEventRevenueValue?: string | number,
  startingPointOverride?: Moment,
  budgetType?: string,
) => {
  let cumMetric;
  const startingPoint = startingPointOverride || moment(getIntervalDateToUse(interval.endDate)).subtract(1, 'day');
  while (startingPoint.isSameOrAfter(getIntervalDateToUse(interval.startDate)) && !cumMetric) {
    cumMetric = getMetric(cumData, startingPoint.format(ISO_DATE), revenueType, budgetType, clientEventRevenueValue);
    startingPoint.subtract(1, 'day');
  }
  return cumMetric;
};

const getDateAmountsFromCumData = (
  cumData: _.Dictionary<BaseAnalyticsDatum>,
  intervals: Array<BudgetSetting | BudgetInterval>,
  revenueType?: RevenueType,
  clientEventRevenueValue?: string | number,
  budgetType?: string,
) => {
  // eslint-disable-next-line no-param-reassign
  intervals = _.orderBy(intervals, 'startDate');
  const keysIntervals = Object.keys(intervals);

  const output = {};

  // PER INTERVAL
  _.forEach(keysIntervals, (key) => {
    const startDate = moment(getIntervalDateToUse(intervals[key].startDate));
    const endDate = moment(getIntervalDateToUse(intervals[key].endDate));

    if (startDate.isAfter(moment(), 'day')) return;

    const intervalStartDate = startDate.clone().subtract(1, 'day').format(ISO_DATE);
    const intervalEndDate = endDate.format(ISO_DATE);
    // cumData's key is the dates
    let cumMetricStart = getMetric(cumData, intervalStartDate, revenueType, budgetType, clientEventRevenueValue);
    let cumMetricEnd = getMetric(cumData, intervalEndDate, revenueType, budgetType, clientEventRevenueValue);
    if (!cumMetricEnd) {
      // FIND THE LAST ENTRY IN THIS CURRENT INTERVAL
      cumMetricEnd = getMostRecentNonZeroMetric(cumData, intervals[key], revenueType, clientEventRevenueValue, undefined, budgetType);
    }

    if (!cumMetricStart) {
      // FIND THE LAST ENTRY PREVIOUS TO THIS INTERVAL
      // IF THE FIRST INTERVAL, 0
      if (key === '0') {
        cumMetricStart = 0;
      } else {
        const prevKey = _.toString(+key - 1);
        const startingPointOverride = startDate.clone().subtract(2, 'day');
        cumMetricStart = getMostRecentNonZeroMetric(cumData, intervals[prevKey], revenueType, clientEventRevenueValue, startingPointOverride, budgetType);
      }
    }

    const startDateNoTime = startDate.format(ISO_DATE);
    if (cumMetricStart && cumMetricEnd) {
      output[startDateNoTime] = cumMetricEnd - cumMetricStart;
    } else if (!cumMetricStart && cumMetricEnd) {
      output[startDateNoTime] = cumMetricEnd;
    } else {
      output[startDateNoTime] = 0;
    }
  });
  return output;
};

export const getTotalDelivered = (
  budgetAllocationData: { [flightExtId: string]: BudgetAllocationData },
  intervals: Array<BudgetSetting | BudgetInterval>,
  revTypeConfig?: RevenueTypeConfig,
  selectedLineItems?: ChildOptions,
  finishCalculations?: MutableRefObject<boolean>,
) => {
  // PER PARENT OR LINE ITEM
  const output = {};
  const budgetType = _.get(_.head(intervals), 'budgetType', BudgetTypes.amount);
  // Need to get delivery of selected line items here
  if (_.size(selectedLineItems)) {
    _.forEach(selectedLineItems, ({ extId, parentId }) => {
      const cumDataToUse = _.get(budgetAllocationData, `${parentId}.childData.${extId}.cumData`, {}) as unknown as Array<BaseAnalyticsDatum>;
      const cumData = _.keyBy(cumDataToUse, (o) => createIsoDate(o.date));
      const budgetKey = getBudgetKeyWithPrefix(parentId);
      const intervalAmounts = getDateAmountsFromCumData(cumData, intervals, _.get(revTypeConfig, `[${budgetKey}].outcome`) as unknown as RevenueType, _.get(revTypeConfig, `[${budgetKey}].revenueValue`, 0) as unknown as string, budgetType);
      output[extId] = intervalAmounts;
    });
  } else {
    // intervals for Cross-Platform are always BudgetSettings and budget type is always amount
    _.forEach(budgetAllocationData, (value, key) => {
      const cumData = _.keyBy(value?.parentData?.cumData, (o) => createIsoDate(o.date));
      const budgetKey = getBudgetKeyWithPrefix(key);
      const intervalAmounts = getDateAmountsFromCumData(cumData, intervals, _.get(revTypeConfig, `[${budgetKey}].outcome`) as unknown as RevenueType, _.get(revTypeConfig, `[${budgetKey}].revenueValue`, 0) as unknown as string, budgetType);
      output[key] = intervalAmounts;
    });
  }

  const summedResult = _.mergeWith({}, ..._.values(output), (objValue, srcValue) => (_.isNumber(objValue) ? objValue + srcValue : srcValue));
  _.forEach(summedResult, (value, key) => {
    summedResult[key] = _.round(value);
  });

  if (finishCalculations) {
    // eslint-disable-next-line no-param-reassign
    finishCalculations.current = true;
  }

  return summedResult;
};

export const getEstimatedSpendMetrics = (
  requiredDailyValue: number,
  delivery: number,
  cloneDelivery: number,
  remainingDays: number,
  minValue: number,
  maxValue: number,
  includesIntelligentLineItems: boolean,
): EstimatedSpendMetrics => {
  const estimatedDailyMinValue = estimatedDailyMinMax(requiredDailyValue, _.toNumber(minValue));
  const estimatedDailyMaxValue = estimatedDailyMinMax(requiredDailyValue, _.toNumber(maxValue));
  const estimatedLifetimeMinValue = estimatedLifetimeMinMax(
    delivery,
    cloneDelivery,
    remainingDays,
    estimatedDailyMinValue,
    includesIntelligentLineItems,
  );
  const estimatedLifetimeMaxValue = estimatedLifetimeMinMax(
    delivery,
    cloneDelivery,
    remainingDays,
    estimatedDailyMaxValue,
    includesIntelligentLineItems,
  );
  return { estimatedDailyMinValue, estimatedDailyMaxValue, estimatedLifetimeMinValue, estimatedLifetimeMaxValue };
};

export const getParentMetrics = (
  interval: BudgetInterval | BudgetSetting,
  remainingSpendDays: number,
  revTypeConfig?: RevenueTypeConfig,
  budgetAllocationData?: { [flightExtId: string]: BudgetAllocationData },
): ParentMetrics => {
  const lifeTimeBudget = getLifeTimeBudget(interval);
  const deliveryObj = getTotalDelivered(budgetAllocationData, [interval], revTypeConfig);
  const delivery = _.head(_.values(deliveryObj)) ?? 0;
  const requiredDailyValue = requiredDaily(lifeTimeBudget, delivery, remainingSpendDays);
  return { lifeTimeBudget, requiredDailyValue };
};

export const isValidChildData = (parentSettings: BudgetInterval, data: ChildSettingsData) => data.active
  && moment(data.startDate).isBefore(parentSettings.endDate)
  && moment(data.endDate).isAfter(parentSettings.startDate);

export const getValidChildData = (parentSettings: BudgetInterval, childExtIdToSettings: ChildExtIdToSettings) => {
  const validData = _.pickBy(childExtIdToSettings, (v) => isValidChildData(parentSettings, v));
  const invalidData = _.omit(childExtIdToSettings, _.keys(validData));
  return { validData, invalidData };
};

export const displayDate = (
  date: Date | string,
  flightExternalType: number = null,
  tz: string,
  dateFormat: string = SIMPLE_DATE_FORMAT,
) => {
  // getBrowserTimeZone doesn't initialize as a default arg
  const timezone = tz ?? getBrowserTimeZone();
  // error handling when there is an issue populating the date
  if (_.isNil(date)) {
    return '';
  }
  // for Cross-Platform - Budget Interval dates are objects
  if (typeof date === 'object') {
    return moment(date.toISOString()).format(dateFormat);
  }
  if (_.includes([FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id, FLIGHT_EXTERNAL_TYPE.dbmLineItem.id], flightExternalType)) {
    const uniformDate = createIsoDate(date as string);
    return moment(uniformDate).format(dateFormat);
  }
  return momentTimeZone.utc(date).tz(timezone).format(dateFormat);
};

export const getChildDisplayNameByDsp = (dsp: number) => {
  if (dsp === DSP.MULTIPLE.id) {
    return 'Line Items';
  }
  const parentExternalTypeId = getParentExternalTypeByDSP(dsp);
  return getChildDisplayName(parentExternalTypeId as SupportedExternalFlightTypes);
};

export const dateDifference = (startDate, endDate, unit) => `${moment(endDate).diff(startDate, unit)} ${unit}`;

export const getConfigObjByAlgoTypeId = (targetingPlusId: number, formValues: {}) => {
  switch (targetingPlusId) {
    case (ALGORITHM_TYPE.dbmTargetingPlus.id):
      // eslint-disable-next-line no-case-declarations
      const additionalConfig = getAdditionalConfigurations(formValues, ALGORITHM_TYPE.dbmBudgetOptimization.id);
      return _.mapKeys(additionalConfig, (_val, key) => toSnakeCase(key));
    case (ALGORITHM_TYPE.ttdTargetingPlus.id):
      return { bid_grouper: _.get(formValues, 'bidOptimization', false), is_fixed_cost_inventory: false };
    case (ALGORITHM_TYPE.wmtTargetingPlus.id):
      return { bid_grouper: _.get(formValues, 'bidOptimization', false), is_fixed_cost_inventory: false };
    case (ALGORITHM_TYPE.xndrTargetingPlus.id):
    default:
      return {};
  }
};
