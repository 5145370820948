import React from 'react';
import { Grid } from 'buildingBlocks';
import { displayDate, displayEstimatedMinMax } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import { BudgetInterval, TooltipsDescriptions } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import { BudgetSetting } from 'containers/StrategyWizard/types';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import BudgetParentObjectFields from './BudgetParentObjectFields';

const { budgetParent, budgetGroup } = BUDGET_MANAGEMENT_STYLES;
const displayBudget = (budgetValue: number, metric: string) => `${displayEstimatedMinMax(budgetValue)} ${metric}`;

type BudgetParentObjectProps = {
  interval: BudgetInterval | BudgetSetting
  tooltips: TooltipsDescriptions
  strategyName: string
  lifeTimeBudget: number
  requiredDailyValue: number
  budgetMetric: string
  flightExtType: number
  flightTimezone: string
};

const BudgetParentObject = ({
  interval,
  tooltips,
  strategyName,
  lifeTimeBudget,
  requiredDailyValue,
  budgetMetric,
  flightExtType,
  flightTimezone,
}: BudgetParentObjectProps) => {
  const { daily: dailyTooltip, lifetimeBudget: lifetimeBudgetTooltip } = tooltips;

  const startAndEndDate = `${displayDate(interval?.startDate, flightExtType, flightTimezone)} \u2014 ${displayDate(interval?.endDate, flightExtType, flightTimezone)}`;

  // in edge case when there is no cumData and less than 1 day remaining, use the lifetimeBudget
  const dailyValue = requiredDailyValue > lifeTimeBudget ? lifeTimeBudget : requiredDailyValue;
  const dailyBudgetValue = displayBudget(dailyValue, budgetMetric);
  const lifetimeBudgetValue = displayBudget(lifeTimeBudget, budgetMetric);

  return (
    <>
      <Grid.Row>
        <Grid.Column width={12}>
          <div style={budgetParent.outerContainer}>
            <div style={budgetGroup.budgetGroupInfoFirstHalf}>
              <BudgetParentObjectFields
                name="Strategy Name"
                content={strategyName}
                customStyle={budgetParent.stratName}
                stratName
              />
              <BudgetParentObjectFields
                name="Start and End Date"
                content={startAndEndDate}
                customStyle={{ width: '37%' }}
              />
            </div>
            <div style={budgetGroup.budgetGroupInfoSecondHalf}>
              <BudgetParentObjectFields
                name="Daily Budget:"
                content={dailyBudgetValue}
                tooltip={dailyTooltip}
              />
              <BudgetParentObjectFields
                name="Lifetime Budget:"
                content={lifetimeBudgetValue}
                tooltip={lifetimeBudgetTooltip}
              />
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </>
  );
};

export default BudgetParentObject;
