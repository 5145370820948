import React from 'react';
import _ from 'lodash';

const CUSTOM_TREE_MODAL_MSG = 'The maximum bid in this tree is' as const;
export const CUSTOM_TREE_MODAL_HEADER = 'Check your maximum bid' as const;

type CustomTreeChickenBoxContentProps = {
  maxBid: number,
  unboundedComputeNodes: Array<string>,
};

export default ({ maxBid, unboundedComputeNodes }: CustomTreeChickenBoxContentProps) => (
  <div style={{ marginBottom: '12px' }}>
    <p>{CUSTOM_TREE_MODAL_MSG} <strong style={{ color: '#7CB342' }}>{maxBid}</strong></p>
    <p>Do you wish to proceed?</p>
    {!_.isEmpty(unboundedComputeNodes)
      && (
      <p>Detected
        <strong style={{ color: '#e53935' }}> {unboundedComputeNodes.length} </strong>
        compute node(s) with no max bid defined.
      </p>
      )}
  </div>
);
