import _ from 'lodash';
import React, { ComponentType, useRef } from 'react';
import { Controller } from 'react-hook-form';
import { PercentageInput, Popup } from 'buildingBlocks';
import BAStyle from '../style';
import { ALL_MIN_VALUES_EXCEEDED_COPY, ALL_MAX_VALUES_EXCEEDED_COPY } from '../constants';

const getFieldValidity = (error) => {
  if (error && (!_.includes([ALL_MIN_VALUES_EXCEEDED_COPY, ALL_MAX_VALUES_EXCEEDED_COPY], error))) {
    return false;
  }
  return true;
};

/* eslint-disable react/prop-types */
export const validatedRangeFieldWrapper = (InnerComponent: ComponentType<any>) => (
  ({ error, ...rest }) => {
    const isValid = getFieldValidity(error);
    const contextRef = useRef(null);
    return (
      <span
        className={`field ${(!isValid ? 'error required' : '')}`}
        style={rest.spanstyle ?? { position: 'relative' }}
      >
        {/* need to use a div here because refs to this custom component do not have element methods */}
        {/* attempting to use a ref to InnerComponent will fail because Popup uses these methods internally */}
        <div ref={contextRef} />
        <InnerComponent label={rest.label} labelPosition={rest.labelPosition} {...rest} />
        {error && (
          <Popup
            size="tiny"
            content={<p style={BAStyle.alert}>{error.message}</p>}
            context={contextRef.current}
            open={!isValid}
          />
        )}
      </span>
    );
  }
);

export const PercentageWrapper = validatedRangeFieldWrapper(PercentageInput);
// custom onChange to prevent 0 values registering as a "0" string
// eslint-disable-next-line no-unused-expressions
const allocationRangeOnChange = (value: number | string, onChange: Function) => (value === '' ? onChange(value) : onChange(+value));

export const DailyAllocationRangeInput = (props) => (
  <Controller
    name={props.name}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <PercentageWrapper
        fluid={false}
        value={value >= 0 ? value : ''}
        onChange={(v) => allocationRangeOnChange(v, onChange)}
        error={error}
        {...props}
      />
    )}
  />
);
