import React from 'react';
import numeral from 'numeral';
import { Grid, Icon } from 'buildingBlocks';
import { DEFAULT_MAX_ALLOCATION, DEFAULT_MIN_ALLOCATION } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { MetricsFormattingConstants } from 'constantsBase';
import { pluralizer } from 'utils/formattingUtils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetBanner } = BUDGET_MANAGEMENT_STYLES;

type BudgetBannerProps = {
  nonAttachedLineItemsCount: number
};

const BudgetBanner = ({ nonAttachedLineItemsCount }: BudgetBannerProps) => {
  const objectPluralizer = pluralizer('object', 'objects')(nonAttachedLineItemsCount);
  const bannerText = `All remaining ${nonAttachedLineItemsCount} ${objectPluralizer} will have a default daily allocation range (${DEFAULT_MIN_ALLOCATION * 100} \u2014 ${numeral(DEFAULT_MAX_ALLOCATION).format(MetricsFormattingConstants.PERCENTAGE_NO_DECIMALS)}).`;
  return (
    <Grid.Row>
      <Grid.Column width={12}>
        <div style={budgetBanner.container}>
          <Icon
            name="info"
            size="tiny"
            bordered
            circular
            style={budgetBanner.infoCircle}
          />
          <p style={budgetBanner.text}>{bannerText}</p>
        </div>
      </Grid.Column>
    </Grid.Row>
  );
};

export default BudgetBanner;
