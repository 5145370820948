import _ from 'lodash';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Grid, Icon } from 'buildingBlocks';
import dspIconStyles from 'components/DspIcon/style';
import { DSP } from 'constantsBase';
import { DEFAULT_WEIGHT } from 'containers/StrategyWizard/ConfigurationByStrategyType/constants';
import { COPILOT_LAYOUT } from 'globalStyles';
import { Pixel } from 'utils/types';
import PixelOption from './PixelOption';
import { PIXEL_PICKER_STYLES } from '../styles';
import { DspToPixel, DspToPixelToWeightsType } from '../types';

const { SPACING } = COPILOT_LAYOUT;
const {
  baseColumnStyles, dspPixelOptionsContainer, dspHeader, dspAccordionIcon, dspDisplayName, pixelRowError,
} = PIXEL_PICKER_STYLES;

const removePixelFromArr = (pixArr: Array<Pixel>, pixel: Pixel) => _.filter(pixArr, (pix: Pixel) => !_.isEqual(pix.id, pixel.id));

type DspPixelOptionsProps = {
  dsp: number
  pixels: Array<Pixel>
  selectedModalPixels: DspToPixel
  setSelectedModalPixels: Dispatch<SetStateAction<DspToPixel>>
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  isEngScore: boolean
};

const DspPixelOptions = (props: DspPixelOptionsProps) => {
  const { dsp, pixels, selectedModalPixels, setSelectedModalPixels, defaultDspToPixelToWeights, isEngScore } = props;
  const [showPixelsList, setShowPixelsList] = useState<boolean>(true);

  const pixelFetchError = _.isString(pixels);
  const noPixelsForDsp = _.isEmpty(pixels);
  const currSelectedDspPixels = _.get(selectedModalPixels, dsp, []);
  const selectedModalDspPixelIds = _.map(currSelectedDspPixels, 'id');
  const dspInfo = DSP.getById(dsp);
  const dspName = _.get(dspInfo, 'displayName');

  const dspHeaderStyle = { ...dspIconStyles[_.get(dspInfo, 'code')], ...dspHeader, ...(!showPixelsList && { marginBottom: SPACING[8] }) };
  const noPixelsMessage = pixelFetchError
    ? `Error fetching ${dspName} pixels. Please try again later.`
    : `No pixels found for ${dspName}. Please remove the attached ${dspName} flights.`;

  const onCheckboxClick = (pixel: Pixel) => {
    const isChecked = _.includes(selectedModalDspPixelIds, pixel.id);
    const newDspPixelArray = isChecked
      ? removePixelFromArr(currSelectedDspPixels, pixel)
      : _.sortBy([...currSelectedDspPixels, pixel], 'id');
    const newSelectedModalPixels = { ...selectedModalPixels, [dsp]: newDspPixelArray };
    setSelectedModalPixels(newSelectedModalPixels);
  };

  const onWeightChange = (pixel: Pixel) => {
    const pixelsCopy = removePixelFromArr(currSelectedDspPixels, pixel);
    // set default pixel weight to what user configured
    defaultDspToPixelToWeights[dsp][pixel.id] = pixel.weight;
    setSelectedModalPixels({ ...selectedModalPixels, [dsp]: _.sortBy([...pixelsCopy, pixel], 'id') });
  };

  return (
    <div style={dspPixelOptionsContainer}>
      <Grid.Row style={dspHeaderStyle}>
        <Grid.Column style={baseColumnStyles}>
          {!noPixelsForDsp && (
            <Icon
              name={showPixelsList ? 'angle down' : 'angle right'}
              onClick={() => setShowPixelsList(!showPixelsList)}
              style={dspAccordionIcon}
            />
          )}
          <div style={{ ...dspDisplayName, ...(noPixelsForDsp && { marginLeft: SPACING[2] }) }}>{dspName}</div>
        </Grid.Column>
      </Grid.Row>
      {(pixelFetchError || noPixelsForDsp) && (
        <Grid.Row style={pixelRowError}>
          <Grid.Column>
            {noPixelsMessage}
          </Grid.Column>
        </Grid.Row>
      )}
      {!pixelFetchError && (
        <div style={{ ...(!showPixelsList && { display: 'none' }) }}>
          {_.map(pixels, (p: Pixel) => {
            const alreadySelectedPixel = _.find(currSelectedDspPixels, (pix: Pixel) => _.isEqual(pix.id, p.id));
            const fallbackDefaultWeight = _.get(defaultDspToPixelToWeights, `[${dsp}][${p.id}]`, DEFAULT_WEIGHT);
            return (
              <PixelOption
                key={`${dsp} - ${p.id}`}
                isEngScore={isEngScore}
                pixel={p}
                defaultWeight={_.get(alreadySelectedPixel, 'weight') ?? fallbackDefaultWeight}
                checked={_.includes(selectedModalDspPixelIds, p.id)}
                onCheckboxClick={onCheckboxClick}
                onWeightChange={onWeightChange}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DspPixelOptions;
