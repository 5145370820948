import _ from 'lodash';
import React, { CSSProperties, ReactElement, useEffect } from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import { DailyAllocationRangeInput } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/Wrappers';
import { StrategyConfigurationStep } from 'containers/StrategyWizard/types';
import { BUDGET_MANAGEMENT_STYLES } from '../style';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type DisplayEstimatedDeliveryProps = {
  delivery: string
};

export const DisplayEstimatedDelivery = ({ delivery }: DisplayEstimatedDeliveryProps) => (
  <div style={budgetGroupStyle.subHeaderText}>
    {delivery}
  </div>
);

type AllocationRangeInputProps = {
  budgetGroupKey: string
};

export const AllocationRangeInput = ({ budgetGroupKey }: AllocationRangeInputProps) => {
  const groupSettings = useWatch({ name: 'groupSettings' });
  const { trigger } = useFormContext<StrategyConfigurationStep>();
  const budgetGroupMin = _.get(groupSettings, `${budgetGroupKey}.min`);
  const budgetGroupMax = _.get(groupSettings, `${budgetGroupKey}.max`);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
    }
  };

  useEffect(() => {
    trigger('groupSettings');
  }, [budgetGroupMin, budgetGroupMax, trigger]);

  return (
    <div style={budgetGroupStyle.allocationRangeContainer}>
      <DailyAllocationRangeInput
        name={`groupSettings[${budgetGroupKey}].min`}
        className="budget-management-input-field"
        label="Min"
        labelPosition="right"
        icon="percent"
        style={{ width: '70%' }}
        spanstyle={{ width: '36%' }}
        // need this to prevent new budget group from being added when user presses enter in AllocationRangeInput
        onKeyDown={handleKeyDown}
      />
      <div style={budgetGroupStyle.allocationRangeDivider}>&#8212;</div>
      <DailyAllocationRangeInput
        name={`groupSettings[${budgetGroupKey}].max`}
        className="budget-management-input-field"
        label="Max"
        labelPosition="right"
        icon="percent"
        style={{ width: '70%' }}
        spanstyle={{ width: '36%' }}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

type BudgetGroupExpandedInfoProps = {
  accordionOpen: boolean
  displayContent: ReactElement
  accordionContent: ReactElement
  style?: CSSProperties
};

const BudgetGroupExpandedInfo = ({
  accordionOpen,
  displayContent,
  accordionContent,
  style,
}: BudgetGroupExpandedInfoProps) => {
  const expandedBudgetGroupStyle = { ...budgetGroupStyle.expandedBudgetGroupInfo, ...style };
  return (
    <div style={expandedBudgetGroupStyle}>
      {displayContent}
      { accordionOpen && (accordionContent) }
    </div>
  );
};

export default BudgetGroupExpandedInfo;
