/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Dispatch, SetStateAction } from 'react';
import { Header, Icon } from 'buildingBlocks';
import { TRUNCATION_LIMIT } from 'constantsBase';
import { truncate } from 'utils/formattingUtils';
import { BUDGET_MANAGEMENT_STYLES } from '../style';
import BudgetGroupNameInput from './BudgetGroupNameInput';

const { budgetGroup: budgetGroupStyle } = BUDGET_MANAGEMENT_STYLES;

type BudgetGroupNameProps = {
  groupTitle: string
  isEditMode: boolean
  onEditTitle: Dispatch<SetStateAction<any>>
  accordionOpen: boolean
  onCancel: () => void
  budgetGroupKey: string
};

const BudgetGroupName = ({
  groupTitle,
  isEditMode,
  onEditTitle,
  accordionOpen,
  onCancel,
  budgetGroupKey,
}: BudgetGroupNameProps) => {
  const fieldName = `groupSettings[${budgetGroupKey}].groupName`;
  return (
    <div style={budgetGroupStyle.budgetGroupNameContainer}>
      {
        isEditMode
          ? (
            <BudgetGroupNameInput
              style={{ width: '100%' }}
              name={fieldName}
              groupTitle={groupTitle}
              onCancel={onCancel}
              budgetGroupKey={budgetGroupKey}
            />
          )
          : (
            <div style={budgetGroupStyle.displayBudgetGroupName}>
              <Header
                as="h5"
                title={groupTitle}
                content={truncate(groupTitle, TRUNCATION_LIMIT)}
                style={budgetGroupStyle.budgetGroupNameHeader}
              />
              {accordionOpen && (
                <Icon
                  size="small"
                  name="edit"
                  onClick={onEditTitle}
                  style={budgetGroupStyle.budgetGroupNameIcon}
                />
              )}
            </div>
          )
        }
    </div>
  );
};

export default BudgetGroupName;
