import _ from 'lodash';
import React, { CSSProperties, ChangeEvent, useState } from 'react';
import { InputOnChangeData } from 'semantic-ui-react/dist/commonjs/elements/Input';
import { Checkbox, Grid, Input } from 'buildingBlocks';
import { Pixel } from 'utils/types';
import { PIXEL_PICKER_STYLES } from '../styles';

const {
  baseColumnStyles, pixelCheckbox, pixelDescription, pixelWeightsColumn, pixelNameDivider,
  pixelOptionRow, pixelWeightInput,
} = PIXEL_PICKER_STYLES;

type PixelOptionProps = {
  isEngScore: boolean
  pixel: Pixel
  defaultWeight: number
  checked: boolean
  onCheckboxClick: Function
  onWeightChange: Function
};

export const PixelOption = (props: PixelOptionProps) => {
  const { isEngScore, pixel, defaultWeight, checked, onCheckboxClick, onWeightChange } = props;
  const [weight, setWeight] = useState<number | ''>(defaultWeight);
  const pixelDescStyle: CSSProperties = { ...baseColumnStyles, ...pixelDescription, fontSize: 14 };
  const pixelWeightError = _.isString(weight) || (_.isNumber(weight) && weight < 0);

  const onWeightInputChange = (value: string) => {
    const numberedWeight = _.isEmpty(value) ? '' : _.toNumber(value);
    setWeight(numberedWeight);
    onWeightChange({ ...pixel, weight: numberedWeight });
  };

  return (
    <Grid.Row style={pixelOptionRow}>
      <Grid.Column style={baseColumnStyles}>
        <Checkbox
          checked={checked}
          style={pixelCheckbox}
          onClick={() => onCheckboxClick({ ...pixel, ...(isEngScore && { weight }) })}
        />
      </Grid.Column>
      <Grid.Column style={pixelDescStyle}>
        {pixel.id}
        {pixel.name && (
          <>
            <span style={pixelNameDivider}>|</span>
            {pixel.name}
          </>
        )}
      </Grid.Column>
      {isEngScore && (
        <Grid.Column style={pixelWeightsColumn}>
          <Input
            className={`pixel-weight-input${(pixelWeightError && checked) ? ' pixelWeightError' : ''}`}
            type="number"
            style={pixelWeightInput}
            disabled={!checked}
            value={weight}
            onChange={(_e: ChangeEvent<HTMLInputElement>, { value }: InputOnChangeData) => onWeightInputChange(value)}
          />
        </Grid.Column>
      )}
    </Grid.Row>
  );
};

export default PixelOption;
