import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Grid, Header, Divider } from 'buildingBlocks';
import PermissionWrapper from 'components/PermissionWrapper';
import { DSP } from 'constantsBase';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { getDspByHigherOrderExtType, hasExternalCustomValueMetric, isHigherOrderExternalType } from 'containers/StrategyWizard/utils';
import { StrategyConfigurationStep, WizardFormValues } from 'containers/StrategyWizard/types';
import { getChildDisplayName } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/utils';
import Tooltip from 'containers/Tooltip';
import { BUDGET_MANAGEMENT_STYLES } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/components/GroupSettings/style';
import { Permission } from 'utils/featureFlags';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { RevenueTypeOutcomeOptions } from 'containers/StrategyWizard/steps/GoalSelection/constants';
import { configuringCampaignStratCheck } from 'containers/StrategyWizard/steps/AttachFlights/utils';
import Pacing from './Pacing';
import { IntelligentChildObjectsToggle, FixedCostInventoryToggle } from './Toggles/Toggles';
import { BudgetAllocationProps, SupportedExternalFlightTypes } from '../types';
import { shouldShowIntelligentChildObjectsToggle, tooltipContent, tooltipContentForBudget } from './Toggles/utils';
import GroupSettings from './GroupSettings/GroupSettings';

const { column, row, divider } = strategyWizardStyles;
const { budgetAllocation, budgetParent } = BUDGET_MANAGEMENT_STYLES;

const BudgetAllocation = ({
  displayName,
  flight,
  budgetAllocationState,
  tooltips,
  member,
  strategyType,
  user,
  isCrossPlatformOptimization,
  hasChildFlightsData,
}: BudgetAllocationProps) => {
  const {
    attachFlightsStep: { attachedFlights, selectedOptType },
    goalSelectionStep,
    strategyConfigurationStep,
  } = useSelector<GlobalState>((state) => state.strategyWizard) as WizardFormValues;

  const goalType = _.get(goalSelectionStep, 'goal.type');
  const hasExternalCustomValue = hasExternalCustomValueMetric(goalType, goalSelectionStep);
  const revenueOutcomeType = _.get(goalSelectionStep, 'revenueOutcomeType');
  const reduxIntelChildObj = _.get(strategyConfigurationStep, 'intelligentChildObjects');
  const intelligentChildObjectsEnabled = useWatch({ name: 'intelligentChildObjects' });
  const { setValue, getValues } = useFormContext<StrategyConfigurationStep>();
  const hasOnlyAMZNFlights = isCrossPlatformOptimization && _.every(attachedFlights, ['dsp', DSP.AMZN.id]);
  const hasSomeAMZNFlight = isCrossPlatformOptimization && _.some(attachedFlights, ['dsp', DSP.AMZN.id]);
  const dailyParentBudgetInflationRatio = useWatch({ name: 'dailyParentBudgetInflationRatio' });

  const dsp = getDspByHigherOrderExtType(flight.externalType);
  const icoTooltipContent = tooltipContent(dsp, 'intelligentChildObjects');
  const bmTooltipContent = tooltipContentForBudget(_.get(tooltips, 'budgetManagement'));
  const fixedCostInventoryTooltipContent = tooltipContent(dsp, 'fixedCostInventory');
  const hasMultipleOutcomesOrValues = _.isEqual(revenueOutcomeType, RevenueTypeOutcomeOptions.multiple);
  const showIntelligentChildObjectsToggle = shouldShowIntelligentChildObjectsToggle(user, member, strategyType, hasExternalCustomValue) && !hasOnlyAMZNFlights && !hasMultipleOutcomesOrValues;
  const hasSingleParentLevelObject = isHigherOrderExternalType(_.head(attachedFlights).externalType) && _.size(attachedFlights) === 1;
  const isHigherOrderOrCrossPlatform = isCrossPlatformOptimization || hasSingleParentLevelObject;
  const prevSetIntelChildObj = getValues('prevSetIntelChildObj');

  useMount(() => {
    if (!hasOnlyAMZNFlights && prevSetIntelChildObj && !_.isNil(reduxIntelChildObj)) {
      setValue('intelligentChildObjects', reduxIntelChildObj);
    }
    if (reduxIntelChildObj && hasExternalCustomValueMetric(goalType, goalSelectionStep)) {
      setValue('intelligentChildObjects', false);
    }
  });

  useEffect(() => {
    setValue('bidOptimization', !!intelligentChildObjectsEnabled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intelligentChildObjectsEnabled]);

  const showFixedCostInventoryToggle = (member?.dsp === DSP.TTD.id) && configuringCampaignStratCheck(selectedOptType);
  const intelligentChildObjectDisplayName = isCrossPlatformOptimization ? 'Line Items' : getChildDisplayName(flight.externalType as SupportedExternalFlightTypes);
  const baseIntelligenceText = `Activate Intelligent ${intelligentChildObjectDisplayName} to improve goal performance.`;
  const intelligenceSectionText = hasSomeAMZNFlight ? `${baseIntelligenceText} Not available on Amazon DSP.` : baseIntelligenceText;

  return (
    <>
      <Pacing pacing={dailyParentBudgetInflationRatio} />
      <Divider style={divider} />
      {showIntelligentChildObjectsToggle && (
        <>
          <Grid.Row style={row}>
            <Grid.Column style={column} width={12}>
              <div style={budgetAllocation.intelligenceContainer}>
                <div>
                  <div style={budgetAllocation.intelligenceSection}>
                    <div style={{ display: 'flex' }}>
                      <Header
                        as="h5"
                        style={budgetAllocation.sectionHeader}
                        content={`Intelligent ${intelligentChildObjectDisplayName}`}
                      />
                      <Tooltip
                        content={icoTooltipContent}
                        tooltipstyling={budgetParent.toolTip}
                      />
                    </div>
                    <IntelligentChildObjectsToggle
                      displayName={displayName}
                    />
                  </div>
                  <div style={budgetAllocation.intelligenceSectionText}>
                    <span>{intelligenceSectionText}</span>
                  </div>
                </div>
                {showFixedCostInventoryToggle && intelligentChildObjectsEnabled && (
                  <Grid.Column width={12}>
                    <FixedCostInventoryToggle
                      displayName={displayName}
                      tooltipContent={fixedCostInventoryTooltipContent}
                    />
                  </Grid.Column>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Divider style={divider} />
        </>
      )}
      {isHigherOrderOrCrossPlatform && (
        <PermissionWrapper
          permissions={Permission.budgetAllocationGroups}
        >
          <Grid.Row style={row}>
            <Grid.Column style={column} width={12}>
              <div style={budgetAllocation.budgetManagementHeaderContainer}>
                <Header
                  as="h5"
                  style={budgetAllocation.sectionHeader}
                  content="Budget Management"
                />
                <Tooltip
                  content={bmTooltipContent}
                  tooltipstyling={budgetParent.toolTip}
                />
              </div>
              <div style={budgetAllocation.budgetManagementText}>
                Control how budget is allocated across strategy objects
              </div>
              <Controller
                name="groupSettings"
                render={() => (
                  <GroupSettings
                    budgetAllocationState={budgetAllocationState}
                    tooltips={tooltips}
                    hasChildFlightsData={hasChildFlightsData}
                  />
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </PermissionWrapper>
      )}
    </>
  );
};

export default BudgetAllocation;
