import React from 'react';
import { Grid, Header } from 'buildingBlocks';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { COPILOT_COLORS, COPILOT_TYPOGRAPHY } from 'globalStyles';
import PacingSlider from './PacingSlider';
import PacingGraph from './PacingGraph';

const { column, row } = strategyWizardStyles;

const pacingMainCopy = 'Set pacing speed to spend budget more quickly or slowly.';

export type PacingProps = {
  pacing: number
};

const Pacing = ({ pacing }: PacingProps) => (
  <Grid.Row style={row}>
    <Grid.Column style={column} width={12}>
      <Header
        as="h5"
        style={{ ...COPILOT_TYPOGRAPHY.HEADING.H5, margin: 0, color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY }}
        content="Pacing"
      />
      <p style={{ ...COPILOT_TYPOGRAPHY.BODY.SMALL, color: COPILOT_COLORS.NEW_DESIGN_SYSTEM.NEUTRALS.N600_ABBEY }}>{pacingMainCopy}</p>
      <div style={{ marginBottom: '15px', fontSize: '0.8571rem' }}>
        <Grid stackable>
          <Grid.Column width={6}>
            <PacingSlider />
          </Grid.Column>
          <Grid.Column width={6}>
            <PacingGraph pacing={pacing} />
          </Grid.Column>
        </Grid>
      </div>
    </Grid.Column>
  </Grid.Row>
);

export default Pacing;
