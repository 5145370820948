import _ from 'lodash';
import { DSP, FLIGHT_EXTERNAL_TYPE, STRATEGY_TYPE, ALGORITHM_TYPE } from 'constantsBase';
import { FLIGHT_ATTACH_STATUS } from 'containers/StrategyWizard/steps/AttachFlights/constants';

export const BUDGET_ALLOCATION_SUPPORTED_EXT_TYPES = [
  FLIGHT_EXTERNAL_TYPE.ttdCampaign.id,
  FLIGHT_EXTERNAL_TYPE.wmtCampaign.id,
  FLIGHT_EXTERNAL_TYPE.dbmInsertionOrder.id,
  FLIGHT_EXTERNAL_TYPE.apnInsertionOrder.id,
  FLIGHT_EXTERNAL_TYPE.amznOrder.id,
];

export const SUPPORTED_FLIGHT_STATUSES_WITH_FLIGHT_CHILD_VIEW = [
  FLIGHT_ATTACH_STATUS.ADD.status,
  FLIGHT_ATTACH_STATUS.ATTACHED.status,
  FLIGHT_ATTACH_STATUS.REMOVE_OTHER.status,
  FLIGHT_ATTACH_STATUS.UPDATE.status,
];

export enum BudgetTypes {
  amount = 'amount',
  imps = 'imps',
}

export const BUDGET_OPT_CONFIG = 'budget_opt_conf';

export enum Status {
  loading = 'loading',
  hasData = 'hasData',
  initial = 'initial',
  error = 'error',
}

export const INITIAL_VALUES = {
  budgetAllocation: true,
  dailyParentBudgetInflationRatio: 1,
  intelligentChildObjects: true,
  bidOptimization: true,
  fixedCostInventory: false,
  viewability: { enabled: false },
  groupSettings: {},
};

export const CROSS_PLATFORM_INITIAL_VALUES = _.omit(INITIAL_VALUES, ['viewability', 'bidOptimization', 'fixedCostInventory']);

export const BUDGET_ALLOCATION_COPY = 'Copilot will optimize campaign delivery towards higher performing ad groups whilst ensuring overall campaign delivery requirements. Delivery goals are prioritized over primary KPI performance.';

export const FAILURE_TEXT = 'There was a problem.';

// eslint-disable-next-line max-len
const INSERTION_ORDER_DESCRIPTION_COPY = 'Budget optimization will aim to fulfill an insertion order’s lifetime budget goals whilst increasing allocation towards high performing line items. Pacing settings in the DSP are changed to enable budget optimization. For a full list, please ';

export const BUDGET_OPT_STRAT_DESCRIPTIONS = {
  [DSP.TTD.id]: 'Budget optimization will aim to fulfill a campaign’s lifetime budget goals whilst increasing allocation towards high performing ad groups. Pacing settings in the DSP are changed to enable budget optimization. For a full list, please ',
  [DSP.DBM.id]: INSERTION_ORDER_DESCRIPTION_COPY,
  [DSP.APN.id]: INSERTION_ORDER_DESCRIPTION_COPY,
};

type TooltipDescription = {
  [id: number]: {
    budget: string,
    daily: string,
    lifetimeBudget: string,
    dailyAllocationRange: string,
    estimatedDaily: string,
    estimatedLifetime: string,
    intelligentChildObjects: string,
    disclaimer: string,
    bidOptimization: string,
    fixedCostInventory: string,
    budgetManagement: string,
  }
} | { [key: string]: string };

export const TOOLTIP_DESCRIPTIONS: TooltipDescription = {
  [DSP.TTD.id]: {
    budget: 'ttd-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the campaign.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the ad group is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the ad group is active. ',
    estimatedLifetime: 'The estimated lifetime delivery for the ad group. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot clones the active Ad Groups in this Campaign and dynamically adjusts targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Ad Groups.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  [DSP.DBM.id]: {
    budget: 'dv360-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the insertion order.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the line item is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the line item is active.',
    estimatedLifetime: 'The estimated lifetime delivery for the line item. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot clones the active Line Items in this Insertion Order and dynamically adjusts targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Line Item.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  [DSP.APN.id]: {
    budget: 'xandr-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the insertion order.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the line item is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the line item is active.',
    estimatedLifetime: 'The estimated lifetime delivery for the line item. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot clones the active Line Items in this Insertion Order and dynamically adjusts targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Line Item.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  [DSP.AMZN.id]: {
    budget: 'amazon-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the order.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the line item is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the line item is active.',
    estimatedLifetime: 'The estimated lifetime delivery for the line item. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot clones the active Line Items in this Order and dynamically adjusts targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Line Item.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  [DSP.MULTIPLE.id]: {
    budget: 'multi-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the insertion order.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the line item is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the line item is active.',
    estimatedLifetime: 'The estimated lifetime delivery for the line item. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot creates copies of Line Items within this Insertion Order. Intelligent Line Items adjust targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Line Item.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  [DSP.WALMART.id]: {
    budget: 'walmart-budget',
    daily: 'Required daily delivery for the remainder of the strategy budget interval. This is calculated by remaining delivery / remaining days.',
    lifetimeBudget: 'The total lifetime budget for the campaign.',
    dailyAllocationRange: 'The model will attempt to deliver between the min and max range each day the ad group is active.',
    estimatedDaily: 'Budget optimization will aim to deliver between the estimated min and max each day the ad group is active. ',
    estimatedLifetime: 'The estimated lifetime delivery for the ad group. Historic delivery is taken into account.',
    intelligentChildObjects: 'Copilot clones the active Ad Groups in this Campaign and dynamically adjusts targeting and bid values over time to improve goal performance.',
    disclaimer: 'Enhance performance with Intelligent Ad Groups.',
    bidOptimization: 'Copilot optimizes bidding based on the goal type and goal value.',
    fixedCostInventory: 'Copilot optimizes bidding for fixed cost inventory.',
    budgetManagement: 'Budget Management allows you to create groups to configure minimum and maximum budget allocation.',
  },
  algoType: 'Filter for users who created or last updated a strategy running the selected algorithms.',
  startDateTime: 'Message will be delivered to all users at this date and time in UTC.',
} as const;

export const MIN_AGGREGATE_LIMIT = 0.8;
export const MAX_AGGREGATE_LIMIT = 1;

export const DEFAULT_MIN_ALLOCATION = 0.00;
export const DEFAULT_MAX_ALLOCATION = 1;

export const UNDER_DELIVERY_COPY = 'Setting the pacing slider to a value under 100% will lead to budget under delivery';
export const ALL_MIN_VALUES_EXCEEDED_COPY = `The combined minimum allocation should be equal to or less than ${MIN_AGGREGATE_LIMIT * 100}%`;
export const ALL_MAX_VALUES_EXCEEDED_COPY = `The combined maximum allocation should be equal to or more than ${MAX_AGGREGATE_LIMIT * 100}%`;

export const SINGLE_BUDGET_GROUP_MAX_EXCEEDED_COPY = `The sum of maximum allocations should be equal to or greater than ${MAX_AGGREGATE_LIMIT * 100}%`;
export const SINGLE_BUDGET_GROUP_MIN_EXCEEDED_COPY = `The minimum allocation should be equal to or less than ${MIN_AGGREGATE_LIMIT * 100}%`;

/**
 * do not remove;
 * This is being used as an prefix string for redux form field as the redux form misinterprets numbered only external
 * ids from dbm and xandr/appnexus as array indexes.
 */
export const reduxFormFix = 'reduxFormFix';

export const BETA_TAGS_SUPPORTED_STRATEGY_TYPES = [
  STRATEGY_TYPE.apnBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
];

export const INTELLIGENT_CHILD_OBJECTS_SUPPORTED_STRATEGY_TYPES = [
  STRATEGY_TYPE.ttdBudgetOptimization.id,
  STRATEGY_TYPE.dbmBudgetOptimization.id,
  STRATEGY_TYPE.apnBudgetOptimization.id,
  STRATEGY_TYPE.wmtBudgetOptimization.id,
];

export const TARGETING_PLUS_SUPPORTED_ALGO_TYPES = [
  ALGORITHM_TYPE.ttdBudgetOptimization.id,
  ALGORITHM_TYPE.dbmBudgetOptimization.id,
  ALGORITHM_TYPE.apnBudgetOptimization.id,
  ALGORITHM_TYPE.wmtBudgetOptimization.id,
];

export const BID_MODEL_SUPPORTED_STRATEGY_TYPES = [
  STRATEGY_TYPE.ttdBudgetOptimization.id,
];

export const BID_MODEL_SUPPORTED_ALGO_TYPES = [
  ALGORITHM_TYPE.ttdBudgetOptimization.id,
];

export const BUDGET_OPTIMIZATION_ALGO_TYPES = [
  ALGORITHM_TYPE.apnBudgetOptimization.id,
  ALGORITHM_TYPE.ttdBudgetOptimization.id,
  ALGORITHM_TYPE.dbmBudgetOptimization.id,
  ALGORITHM_TYPE.wmtBudgetOptimization.id,
];

export const ICO_BEST_PRACTICE_CONFIG_SUPPORTED_ALGO_TYPES = [
  ALGORITHM_TYPE.dbmBudgetOptimization.id,
];

export const BUDGET_OPTIMIZATION_AND_MULTI_DSP_ALGO_TYPES = [
  ALGORITHM_TYPE.apnBudgetOptimization.id,
  ALGORITHM_TYPE.amznBudgetOptimization.id,
  ALGORITHM_TYPE.ttdBudgetOptimization.id,
  ALGORITHM_TYPE.wmtBudgetOptimization.id,
  ALGORITHM_TYPE.dbmBudgetOptimization.id,
  ALGORITHM_TYPE.crossPlatformOptimization.id,
];

export const CHILD_PRIORITIES = 'childPriorities';

export const WIKI_LINK = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-budget-optimization-';

export const WIKI_LINK_REV_TYPE = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001234593-campaigns#A.-Revenue-Type';

export const CROSS_PLATFORM_REV_TYPE_LINK = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001246889';

export const TTD_FIXED_COST_INVENTORY_WIKI = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001150650-deals-private-marketplaces';

export const LINK_FOR_BUDGET_MANAGEMENT_TOOLTIP = 'https://copilotsupport.freshdesk.com/support/solutions/articles/47001125069-configuration-campaign-level-optimization#Budget-Management%3A Budget-Allocation-Groups';

export const DBM_ICO_BEST_PRACTICE_CONFIGURATIONS = {
  rateBasedGoal: {
    config: {
      cpcvLever: { lever_enabled: true, lever_type: 'static', lever_scale: 0.20 },
      thresholdBoundsLever: { lever_enabled: true, lever_type: 'dynamic', lever_scale: 0.60, targeting_type: 'include' },
      minDeliveryFracLever: { lever_enabled: true, lever_type: 'dynamic', lever_scale: 0.6 },
    },
  },
  costBasedGoal: {
    config: {
      cpcvLever: { lever_enabled: true, lever_type: 'dynamic', lever_scale: 0.20 },
      cpmLever: { lever_enabled: true, lever_type: 'dynamic', lever_scale: 0.20 },
      thresholdBoundsLever: { lever_enabled: true, lever_type: 'static', lever_scale: 0.15, targeting_type: 'exclude' },
      minDeliveryFracLever: { lever_enabled: true, lever_type: 'static', lever_scale: 0.6 },
    },
  },
};

export const YOUTUBE_NOT_SUPPORTED_WARNING = 'This goal type does not support Youtube. Copilot will optimize based on the DV360 Custom Bidding algorithm selected either for each Line Item, or for the Insertion Order.';

export const ILI_DISPLAY_NAME_SUFFIX = '_CP_INT';

export enum MomentTimeUnits {
  minutes = 'minutes',
  hours = 'hours',
  days = 'days',
  weeks = 'weeks',
  months = 'months',
  years = 'years',
}

export const VISIBLE_LINE_ITEM_LIMIT = 6;

export const DEFAULT_GROUP_ID = 'defaultGroup';

export const DEFAULT_GROUP_CONFIG = {
  groupName: 'Default',
  min: DEFAULT_MIN_ALLOCATION,
  max: DEFAULT_MAX_ALLOCATION,
  childExtIds: [],
};
