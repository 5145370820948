import { CSSProperties } from 'react';
import { COPILOT_COLORS } from 'globalStyles';

const { NEW_DESIGN_SYSTEM: { BLUES, YELLOWS, NEUTRALS } } = COPILOT_COLORS;

export const BAStyle: { [key: string]: CSSProperties } = {
  toolTipStyle: {
    fontSize: '14px',
    verticalAlign: 'text-bottom',
    color: NEUTRALS.N400_GRANITE,
  },
  handleSlider: {
    top: '-2px',
    background: BLUES.B500_WAVE,
    width: '70px',
    height: '30px',
    borderRadius: '20px',
    padding: '4px',
    color: 'white',
    textAlign: 'center',
    borderColor: BLUES.B500_WAVE,
  },
  handleSliderOrange: {
    top: '-2px',
    background: YELLOWS.Y500_GOLD,
    width: '70px',
    height: '30px',
    borderRadius: '20px',
    padding: '4px',
    color: 'white',
    textAlign: 'center',
    borderColor: YELLOWS.Y500_GOLD,
  },
  alert: {
    color: YELLOWS.Y500_GOLD,
    fontSize: '12px',
  },
  truncateStyle: {
    paddingLeft: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '320px',
    display: 'block',
    float: 'left',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  intelligentObjTableStyle: {
    color: '#4a4a4a',
    fontSize: '13px',
    backgroundColor: 'white',
    border: '1px solid #e8e8e8',
  },
  tableStyle: {
    color: '#4a4a4a',
    fontSize: '13px',
    backgroundColor: '#f3f1f1',
  },
  dateStyle: {
    paddingLeft: '20px',
    display: 'block',
    float: 'left',
  },
  parentRowLifetimeBudgetText: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  noDataStyle: {
    height: '130px',
    paddingTop: '3%',
  },
};

export default BAStyle;
