import { GOAL_TYPES } from 'constantsBase';

export enum PageStatus {
  InitialPageStatus = 'InitialPageStatus',
  CsvHasError = 'CsvHasError',
  BidListsGenerating = 'BidListsGenerating',
  VolumeControlGenerating = 'VolumeControlGenerating',
}

export enum BidListStatus {
  NotYetValidated = 'NotYetValidated',
  BidListsValidating = 'BidListsValidating',
  BidListsAreValid = 'BidListsAreValid',
  BidListsError = 'BidListsError',
}

export enum VolumeControlStatus {
  NotYetValidated = 'NotYetValidated',
  VolumeControlValidating = 'VolumeControlValidating',
  VolumeControlAreValid = 'VolumeControlAreValid',
  VolumeControlError = 'VolumeControlError',
}

export type PageStatusType = {
  bidListStatus: PageStatus | BidListStatus,
  volumeControlStatus: PageStatus | VolumeControlStatus
};

export enum TabType {
  BID_LIST_TAB = 'BID_LIST_TAB',
  VOLUME_CONTROL_TAB = 'VOLUME_CONTROL_TAB',
}

export type FromTabType = TabType.BID_LIST_TAB | TabType.VOLUME_CONTROL_TAB;

export const BID_LIST_CSV_INPUT = 'bidListCsvInput' as const;
export const BID_LIST_STRING = 'bidListString' as const;
export const VOLUME_CONTROL_BID_LIST_STRING = 'volumeControlBidListString' as const;
export const DEFAULT_BID_LIST_STRING = '' as const;
export const DEFAULT_VC_BID_LIST_STRING = '' as const;

export const INITIAL_VALUES = {
  [BID_LIST_CSV_INPUT]: '',
  [BID_LIST_STRING]: DEFAULT_BID_LIST_STRING,
  [VOLUME_CONTROL_BID_LIST_STRING]: DEFAULT_VC_BID_LIST_STRING,
  pageValidator: BidListStatus.NotYetValidated,
};

export const CUSTOM_BID_LIST_GOAL_TYPE_CHANGE = 'CUSTOM_BID_LIST_GOAL_TYPE_CHANGE' as const;
export const VOLUME_CONTROL_PARSE_JSON_FAILED = 'VOLUME_CONTROL_PARSE_JSON_FAILED' as const;

export const MAX_SIZE = 10 as const;
export const MAX_ERRORS_TO_SHOW = 3 as const;

export const ALLOWED_GOAL_TYPES = [
  GOAL_TYPES.cpa,
  GOAL_TYPES.cpc,
  GOAL_TYPES.conversionRate,
  GOAL_TYPES.ctr,
  GOAL_TYPES.conversionRevenue,
  GOAL_TYPES.engagementScore,
  GOAL_TYPES.exposureTime,
  GOAL_TYPES.cpcv,
  GOAL_TYPES.completionRate,
  GOAL_TYPES.cpcl,
  GOAL_TYPES.ivrImps,
  GOAL_TYPES.ivrMeasured,
  GOAL_TYPES.vcpm,
  GOAL_TYPES.margin,
];

export const TRANSLATION_ERRORS_BY_FIELD = 'translationErrorsByField' as const;
export const NO_BID_LIST_GENERATED_COPY = 'No Bid List was generated as the CSV did not include the header "BidAdjustment"' as const; // eslint-disable-line max-len
export const NO_VC_BID_LIST_GENERATED_COPY = 'No Volume Control was generated as the CSV did not include the header "VolumeControlPriority"' as const; // eslint-disable-line max-len

export const ACCEPTED_TYPES = new Set(['text/csv', 'application/vnd.ms-excel', 'application/octet-stream']);

export type BidList = Array<{ [key: string]: unknown }>;
export type BidListErrorArray = Array<{ [key: string]: unknown } | string>;

export type BidListStringObject = {
  [BID_LIST_STRING]?: string,
  [VOLUME_CONTROL_BID_LIST_STRING]?: string,
};

export type JsonParseErrorObj = { bidListJsonParseError?: string, volumeControlBidListJsonParseError?: string };

export type ValidationPayload = {
  pageStatus: PageStatusType,
  [BID_LIST_STRING]: string,
  [VOLUME_CONTROL_BID_LIST_STRING]: string,
  bidListErrors: BidListErrorArray,
  volumeControlBidListErrors: BidListErrorArray,
};

export enum BidListValidity {
  Valid = 'Valid',
  Invalid = 'Invalid',
  NotYetValidated = 'NotYetValidated',
}

export const RESET_CSV_VALIDATION = 'CUSTOM_BID_LIST:RESET_CSV_VALIDATION' as const;
export const GENERATE_BID_LISTS = 'CUSTOM_BID_LIST:GENERATE_BID_LISTS' as const;
export const GENERATE_BID_LISTS_VOLUME_CONTROL = 'CUSTOM_BID_LIST:GENERATE_BID_LISTS_VOLUME_CONTROL' as const;
export const PARSE_THEN_VALIDATE_BID_LISTS = 'CUSTOM_BID_LIST:PARSE_THEN_VALIDATE_BID_LISTS' as const;
export const PARSE_JSON_FAILED = 'CUSTOM_BID_LIST:PARSE_JSON_FAILED' as const;
export const VALIDATE_BID_LISTS = 'CUSTOM_BID_LIST:VALIDATE_BID_LISTS' as const;
export const VALIDATE_BID_LISTS_VOLUME_CONTROL = 'CUSTOM_BID_LIST:VALIDATE_BID_LISTS_VOLUME_CONTROL' as const;
export const CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS = 'CUSTOM_BID_LIST:CHECK_ORDERED_MAP_FOR_VALIDITY_STATUS' as const;
export const INITIALIZE_EXISTING_STRATEGY = 'CUSTOM_BID_LIST:INITIALIZE_EXISTING_STRATEGY' as const;
export const RESET_BID_LISTS_VOLUME_CONTROL_STATUS = 'CUSTOM_BID_LIST:RESET_BID_LISTS_VOLUME_CONTROL_STATUS' as const;
export const SUCCESSFULLY_PARSED_CSV_DATA = 'CUSTOM_BID_LIST:SUCCESSFULLY_PARSED_CSV_DATA' as const;
export const PARSE_CSV_DATA_FAILED = 'CUSTOM_BID_LIST:PARSE_CSV_DATA_FAILED' as const;
export const BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED = 'CUSTOM_BID_LIST:BID_LIST_VOLUME_CONTROL_VALIDATION_API_RES_RECEIVED' as const;
export const VALIDATION_ERROR = 'CUSTOM_BID_LIST:VALIDATION_ERROR' as const;
export const RESET_PROPS = 'CUSTOM_BID_LIST:RESET_PROPS' as const;

// STYLING

export const paddingTop5px = { paddingTop: '5px' };
