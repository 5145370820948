import { CSSProperties } from 'react';
import { COPILOT_COLORS, COPILOT_LAYOUT, COPILOT_TYPOGRAPHY } from 'globalStyles';

const {
  NEW_DESIGN_SYSTEM: { NEUTRALS, BLUES },
} = COPILOT_COLORS;

const { SPACING } = COPILOT_LAYOUT;

const { HEADING, BODY, DESCRIPTION } = COPILOT_TYPOGRAPHY;

type Props = { [key: string]: CSSProperties | { [key: string]: CSSProperties } };

export const POP_UP_FORM: Props = {
  popUpFormButton: {
    ...HEADING.H6,
    width: '222px',
    height: SPACING[32],
    borderRadius: SPACING[4],
    color: BLUES.B500_WAVE,
    backgroundColor: NEUTRALS.N0_WHITE,
    border: 'none',
    boxShadow: 'none',
    padding: '0 40px 0 0',
  },
  showAllPopUpContainer: {
    height: '525px',
    width: '400px',
  },
  showAllPopUpList: {
    height: '480px',
    overflowY: 'auto',
  },
  individualFilterPopUpContainer: {
    width: '500px',
    height: '460px',
  },
  filterOptionsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  textButton: {
    ...BODY.SMALL,
    padding: SPACING[8],
    margin: 0,
    cursor: 'pointer',
    border: 'none',
    boxShadow: 'none',
    background: 'none',
    color: BLUES.B500_WAVE,
    textTransform: 'capitalize',
  },
  parentOptionsContainer: {
    height: '75%',
    width: '100%',
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    borderRadius: SPACING[4],
    overflow: 'auto',
    padding: '16px 16px 0 16px',
  },
  optionsList: {
    display: 'flex',
    flexDirection: 'column',
  },
  popupFormFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
  },
  numOfSelectedFiltersText: {
    ...BODY.SMALL,
    color: NEUTRALS.N600_ABBEY,
    margin: 0,
  },
  cancelBtn: {
    ...HEADING.H6,
    width: '100px',
    height: SPACING[32],
    backgroundColor: NEUTRALS.N0_WHITE,
    color: NEUTRALS.N800_MATTER,
    margin: 0,
    lineHeight: 0,
  },
  doneBtn: {
    ...HEADING.H6,
    color: NEUTRALS.N0_WHITE,
    backgroundColor: BLUES.B500_WAVE,
    width: '100px',
    height: SPACING[32],
    margin: '0 0 0 16px',
    lineHeight: 0,
  },
  filterBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
  },
  filterBtn: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    padding: '8px 0',
  },
  filterSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  verticalDivider: {
    height: SPACING[20],
    border: `1px solid ${NEUTRALS.N200_LINEN}`,
    backgroundColor: NEUTRALS.N200_LINEN,
    margin: '0 8px',
  },
  selectedFilterBtn: {
    background: 'transparent',
    boxShadow: 'none',
    border: 'none',
    color: NEUTRALS.N800_MATTER,
    fontWeight: 600,
    padding: '8px 0',
  },
  parentOptionText: {
    ...DESCRIPTION.BOLD,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N800_MATTER,
  },
  childOptionContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: SPACING[8],
  },
  childOptionText: {
    ...DESCRIPTION.REGULAR,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N800_MATTER,
  },
  footerTextContainer: {
    display: 'flex',
    width: '32%',
    justifyContent: 'space-between',
  },
  parentOptAccordionIcon: {
    color: NEUTRALS.N400_GRANITE,
  },
  disabledChildOptionText: {
    ...DESCRIPTION.REGULAR,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N300_ALTO,
  },
  disabledParentOptionText: {
    ...DESCRIPTION.BOLD,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    marginLeft: SPACING[4],
    color: NEUTRALS.N300_ALTO,
  },
};
