/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import {
  BudgetGroupOptions,
  ChildOptions,
  FilterButtonState,
  ExpandAllOptionsObject,
  ParentOptions,
  SingleChildOption,
} from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/types';
import DspOptionsSection from './DspOptionsSection';
import { POP_UP_FORM } from './style';

type FormOptionsProps = {
  groupedOptions: BudgetGroupOptions
  search: string
  selectedLineItemSession: ChildOptions
  setSelectedLineItemSession: Dispatch<SetStateAction<ChildOptions>>
  alreadyAttachedLineItemKeys: Array<string>
  filterBtnState: FilterButtonState
  expandAllObj: ExpandAllOptionsObject
  setExpandAllObj: Dispatch<SetStateAction<ExpandAllOptionsObject>>
};

const FormOptions = ({
  groupedOptions,
  search,
  selectedLineItemSession,
  setSelectedLineItemSession,
  alreadyAttachedLineItemKeys,
  filterBtnState,
  expandAllObj,
  setExpandAllObj,
}: FormOptionsProps) => {
  const [filteredOptions, setFilteredOptions] = useState<BudgetGroupOptions>(groupedOptions);

  // using optionsToSearch as source for search
  const optionsToSearch = useRef<BudgetGroupOptions>(groupedOptions);

  const updateExpandAllObj = (groupedOptionsObj: BudgetGroupOptions) => {
    setExpandAllObj(_.reduce(groupedOptionsObj, (acc: ExpandAllOptionsObject, options: ParentOptions) => {
      _.forEach(options, (_childOpt, parentOpt: string) => {
        acc[parentOpt] = true;
      });
      return acc;
    }, {}));
  };

  const getSelectedOptions = () => {
    const selectedChildOptions = {};
    const selectedLineItemsKeys = _.concat(_.keys(selectedLineItemSession));
    _.forEach(groupedOptions, (groupedOption: ParentOptions, key: string) => {
      _.forEach(groupedOption, (childOption: ChildOptions, parentOption: string) => {
        const matches = _.pickBy(childOption, (_childOptionObj, childOptionName: string) => _.includes(selectedLineItemsKeys, childOptionName));
        if (_.size(matches)) {
          selectedChildOptions[key] = { ...selectedChildOptions[key], [parentOption]: matches };
        }
      });
    });
    updateExpandAllObj(selectedChildOptions);
    setFilteredOptions(selectedChildOptions);
    optionsToSearch.current = selectedChildOptions;
  };

  const getAllChildOptions = () => {
    updateExpandAllObj(groupedOptions);
    setFilteredOptions(groupedOptions);
  };

  const filterOptions = () => {
    if (search === '') {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      filterBtnState === FilterButtonState.all
        ? getAllChildOptions()
        : getSelectedOptions();
      return;
    }
    const result = {};
    _.forEach(optionsToSearch.current, (options: ParentOptions, dspKey: string) => {
      _.forEach(options, (childOptions: ChildOptions, parentOption: string) => {
        const lineItemMatches = _.pickBy(childOptions, (option: SingleChildOption) => _.includes(option.lineItemDisplayName.toLowerCase(), search.toLowerCase()));
        if (_.size(lineItemMatches)) {
          result[dspKey] = { ...result[dspKey], [parentOption]: lineItemMatches };
        }
      });
    });
    updateExpandAllObj(result);
    setFilteredOptions(result);
  };

  const filterOptionsWrapper = useCallback(_.debounce(filterOptions, 400), [search, selectedLineItemSession]);

  useEffect(() => {
    if (filterBtnState === FilterButtonState.selected) {
      getSelectedOptions();
    } else {
      getAllChildOptions();
      optionsToSearch.current = groupedOptions;
    }
    // need to trigger filter function when toggling filterBtnState
    filterOptions();
  }, [filterBtnState, selectedLineItemSession]);

  useEffect(() => {
    filterOptionsWrapper();
  }, [search]);

  const allOptions = _.map(filteredOptions, (options: ParentOptions, dspKey: string) => (
    <DspOptionsSection
      key={dspKey}
      dspId={dspKey}
      sectionOptions={options}
      selectedLineItemSession={selectedLineItemSession}
      setSelectedLineItemSession={setSelectedLineItemSession}
      search={search}
      alreadyAttachedLineItemKeys={alreadyAttachedLineItemKeys}
      expandAllObj={expandAllObj}
      setExpandAllObj={setExpandAllObj}
    />
  ));

  return (
    <div style={POP_UP_FORM.parentOptionsContainer}>
      <div style={POP_UP_FORM.optionsList}>
        {_.size(allOptions)
          ? allOptions
          : (<div> No results found.</div>)}
      </div>
    </div>
  );
};

export default FormOptions;
