import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ImageFile } from 'react-dropzone';
import { Form, Grid, ObjectDropdown, Segment } from 'buildingBlocks';
import FormSectionHeader from 'components/FormSectionHeader/FormSectionHeader';
import strategyWizardStyles from 'containers/StrategyWizard/styles';
import { WizardFormValues } from 'containers/StrategyWizard/types';
import { GlobalState } from 'reducers';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { dropzoneStyle } from 'components/BrowseDragDropPasteInputTextZone/style';
import { ModuleProps } from 'containers/StrategyWizard/steps/StrategyConfiguration/components/Loader';
import { ACCEPTED_TYPES, ADD_LEAF_NAMES, CustomTreeStatus, CUSTOM_TREE_INPUT, PageStatus, REMOVE_LEAF_NAMES, RESET_CSV_VALIDATION, RESET_CUSTOM_TREE_VALIDATION, TREE_FILE_CSV_INPUT, UPDATE_CUSTOM_TREE_VALIDATION_STATUS, VALIDATED_INITIAL_TREE_STATUS } from './constants';
import { getValidationOutput, modelTypeEnumOptions } from './utils';
import { generateTreeText, handleTreeValidation, INITIAL_STATE, reducer, toggleLeafNames } from './context';
import FileUpload from './components/FileUpload';
import TreeText from './components/TreeText';

const { column } = strategyWizardStyles;

const CustomTree = (props: ModuleProps) => {
  const [reader, setReader] = useState<FileReader>(new FileReader());
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { pageStatus, validationError, csvValidationMsg, cachedTreeFileInputs } = state;
  const { initialValues, strategyId, setKoalaBoxValues } = props;

  const {
    attachFlightsStep: { member },
    strategyTypeSelectionStep: { strategyType },
    strategyConfigurationStep,
  } = useSelector<GlobalState>((globalState) => globalState.strategyWizard) as WizardFormValues;
  const reduxCustomTreeInput = _.get(strategyConfigurationStep, 'customTreeInput', '');
  const treeFileInput = useWatch({ name: 'treeFileInput' });
  const customTreeInput = useWatch({ name: 'customTreeInput' });
  const pageValidation = useWatch({ name: 'pageValidator' });
  const { control, setValue, trigger } = useFormContext();

  const customTreeStatus = _.get(pageStatus, 'customTreeStatus');
  const isCustomTreeValid = customTreeStatus === CustomTreeStatus.CustomTreeIsValid;
  const isCustomTreeValidating = customTreeStatus === CustomTreeStatus.CustomTreeValidating;
  const isCustomTreeGenerating = pageStatus === PageStatus.CustomTreeGenerating;
  const cannotValidateTree = isCustomTreeValid;
  const csvInputLength = treeFileInput?.length;
  const validationOutput = getValidationOutput(pageStatus, validationError);

  useMount(() => {
    if (initialValues.customTreeInput && strategyId) {
      const trimmedCustomTreeInput = _.trim(initialValues.customTreeInput);
      const trimmedTreeFileInput = _.trim(initialValues.treeFileInput);
      dispatch({ type: VALIDATED_INITIAL_TREE_STATUS, payload: { trimmedCustomTreeInput, trimmedTreeFileInput } });
    }
  });

  useEffect(() => {
    if (!reader) {
      setReader(new FileReader());
    }
  }, [reader]);

  useEffect(() => {
    if (customTreeInput === '') {
      dispatch({ type: RESET_CUSTOM_TREE_VALIDATION });
    } else {
      const trimmedCustomTreeInput = _.trim(customTreeInput);
      const shouldRevalidate = trimmedCustomTreeInput !== reduxCustomTreeInput;
      dispatch({ type: UPDATE_CUSTOM_TREE_VALIDATION_STATUS, payload: { trimmedCustomTreeInput, shouldRevalidate } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTreeInput]);

  useEffect(() => {
    const trimmedTreeFileInput = _.trim(treeFileInput || '');
    const cachedCustomTree = cachedTreeFileInputs.get(trimmedTreeFileInput);
    if (cachedCustomTree) {
      setValue(CUSTOM_TREE_INPUT, cachedCustomTree);
    } else if (initialValues.treeFileInput !== treeFileInput && !cachedCustomTree) {
      setValue(CUSTOM_TREE_INPUT, '');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeFileInput]);

  useEffect(() => {
    setValue('pageValidator', pageStatus.customTreeStatus);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageStatus]);

  useEffect(() => {
    trigger();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageValidation]);

  const onValidateCustomTreeClick = async () => {
    await handleTreeValidation(member, customTreeInput, setKoalaBoxValues, dispatch);
  };

  const onGenerateTreeText = async () => {
    // trimming here because we want the CSV value stored in the OrderedMap to be trimmed
    const trimmedTreeFileInput = _.trim(treeFileInput);
    await generateTreeText(trimmedTreeFileInput, setValue, dispatch);
  };

  const onDrop = (accepted: Array<ImageFile>) => {
    const file = _.first(accepted);
    if (_.isEmpty(accepted) || ACCEPTED_TYPES.has(file.type)) {
      reader.onload = async () => {
        const resultString = reader.result;
        await dispatch({ type: RESET_CSV_VALIDATION });
        setValue(TREE_FILE_CSV_INPUT, resultString);
      };
    }

    reader.onabort = () => console.error('file reading was aborted'); // eslint-disable-line no-console
    reader.onerror = () => console.error('file reading has failed'); // eslint-disable-line no-console
    reader.readAsText(file, 'UTF-8');
  };

  const handleAdd = () => {
    if (!_.isEmpty(customTreeInput)) {
      toggleLeafNames(ADD_LEAF_NAMES, customTreeInput, setValue, dispatch);
    }
  };

  const handleRemove = () => {
    if (!_.isEmpty(customTreeInput)) {
      toggleLeafNames(REMOVE_LEAF_NAMES, customTreeInput, setValue, dispatch);
    }
  };

  return (
    <Segment basic loading={isCustomTreeValidating} style={{ padding: 0, margin: 0 }}>
      <Grid stackable celled="internally">
        <Grid.Row columns={2}>
          <Grid.Column style={{ padding: 0 }} width={6}>
            <Grid stackable celled="internally">
              <Grid.Row>
                <Grid.Column style={column}>
                  <Form.Field>
                    <FormSectionHeader
                      title="Model Type"
                      help="Select a bidding strategy."
                      group={strategyType.displayName}
                    />
                    <Controller
                      name="modelType"
                      control={control}
                      render={({ field }) => (
                        // @ts-ignore
                        <ObjectDropdown
                          name="modelType"
                          placeholder="Model type"
                          options={modelTypeEnumOptions}
                          keyFn={(m) => m.text}
                          fluid
                          selection
                          disabled={isCustomTreeValidating}
                          onChange={field.onChange}
                          {...field}
                        />
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={column}>
                  <Form.Field>
                    <FileUpload
                      control={control}
                      strategyType={strategyType}
                      disabled={isCustomTreeValidating || isCustomTreeGenerating}
                      csvValidationMsg={csvValidationMsg}
                      isCustomTreeGenerating={isCustomTreeGenerating}
                      isCustomTreeValidating={isCustomTreeValidating}
                      csvInputLength={csvInputLength}
                      dropzoneStyle={dropzoneStyle}
                      onDrop={onDrop}
                      onGenerateTreeText={onGenerateTreeText}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column style={{ ...column, paddingTop: '21px' }} width={6}>
            <TreeText
              control={control}
              strategyType={strategyType}
              isCustomTreeValidating={isCustomTreeValidating}
              cannotValidateTree={cannotValidateTree}
              isCustomTreeValid={isCustomTreeValid}
              validationOutput={validationOutput}
              onValidateCustomTreeClick={onValidateCustomTreeClick}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default CustomTree;
