import _ from 'lodash';
import React from 'react';
import { Controller } from 'react-hook-form';
import RCSlider from 'rc-slider';
import BAStyle from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/style';
import { UNDER_DELIVERY_COPY } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';

const marks = {
  0.5: {
    style: { textAlign: 'left', marginLeft: '-7px', marginTop: '10px' },
    label: <div>50%<br />Pace Slowly</div>,
  },
  1: {
    style: { marginTop: '10px' },
    label: <div>100%<br />Even Pacing</div>,
  },
  1.5: {
    style: { textAlign: 'right', marginLeft: '-8px', marginTop: '10px', left: '57%' },
    label: <div>150%<br />Pace Quickly</div>,
  },
};

// eslint-disable-next-line react/prop-types
const SliderField = ({ onChange, value, ...rest }) => (
  <>
    <RCSlider {...rest} onChange={(val) => onChange(val)} value={_.toNumber(value)} />
    {value < 1
      && (
        <div style={{ marginTop: '25px', display: 'inline-block' }}>
          <span style={BAStyle.alert}><strong>Under Delivery:</strong> {UNDER_DELIVERY_COPY}</span>
        </div>
      )}
  </>
);

// @ts-ignore RCSlider
const Handle = RCSlider.Handle;

const customHandleForSlider = (props) => {
  const { value, offset, ...rest } = props;
  const newRest = _.omit(rest, ['handleStyle', 'dragging']);
  const style = value >= 1 ? BAStyle.handleSlider : BAStyle.handleSliderOrange;
  return (
    <Handle
      handleStyle={{ ...style, left: `${offset - 4}%` }}
      {...newRest}
    >
      {Math.round(value * 100)}%
    </Handle>
  );
};

const PacingSlider = () => (
  <Controller
    name="dailyParentBudgetInflationRatio"
    render={({ field: { onChange, value = 1.0 } }) => (
      <SliderField
        type="range"
        included={false}
        style={{ margin: '40px 7px 40px', width: '95%' }}
        dots
        step={0.1}
        min={0.5}
        max={1.5}
        marks={marks}
        handle={customHandleForSlider}
        value={value}
        onChange={onChange}
      />
    )}
  />
);

export default PacingSlider;
