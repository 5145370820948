import _ from 'lodash';
import React, { Dispatch, SetStateAction } from 'react';
import { Button } from 'buildingBlocks';
import { PixelCategories } from '../types';
import { PIXEL_PICKER_STYLES } from '../styles';

const { categorySwitcherContainer, categoryBtnGroup, categoryBtn, activeCategoryBtn } = PIXEL_PICKER_STYLES;

type CategoryOptionButtonType = {
  option: PixelCategories
  count: number
  isSelected: boolean
  setPixelCategory: Dispatch<SetStateAction<PixelCategories>>
};

const CategoryOptionButton = (props: CategoryOptionButtonType) => {
  const { option, count, isSelected, setPixelCategory } = props;

  return (
    <Button
      style={{ ...categoryBtn, ...(isSelected && { ...activeCategoryBtn }) }}
      onClick={isSelected ? null : () => setPixelCategory(option)}
    >
      {_.capitalize(option)} · {count}
    </Button>
  );
};

type CategorySwitcherProps = {
  pixelCategory: PixelCategories
  setPixelCategory: Dispatch<SetStateAction<PixelCategories>>
  allCount: number
  selectedCount: number
};

const CategorySwitcher = (props: CategorySwitcherProps) => {
  const { pixelCategory, setPixelCategory, allCount, selectedCount } = props;

  return (
    <div style={categorySwitcherContainer}>
      <Button.Group style={categoryBtnGroup}>
        <CategoryOptionButton
          option={PixelCategories.all}
          count={allCount}
          isSelected={_.isEqual(pixelCategory, PixelCategories.all)}
          setPixelCategory={setPixelCategory}
        />
        <CategoryOptionButton
          option={PixelCategories.selected}
          count={selectedCount}
          isSelected={_.isEqual(pixelCategory, PixelCategories.selected)}
          setPixelCategory={setPixelCategory}
        />

      </Button.Group>
    </div>
  );
};

export default CategorySwitcher;
