import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Grid, Segment } from 'buildingBlocks';
import { GOAL_SECTION_STYLES } from 'containers/StrategyWizard/steps/GoalSelection/styles';
import { assertUnreachable } from 'utils/types';
import { PixelStates, PixelLoadingState, DspToPixel, DspToPixelToWeightsType } from './types';
import PixelsLoading from './PixelsLoading';
import PixelPickerModal from './PixelPickerModal';

type Props = {
  // the pixels available for selection keyed by DSP
  pixels: DspToPixel
  // DSP pixels to default weights - used when goal type is engagement score
  defaultDspToPixelToWeights: DspToPixelToWeightsType
  goalType: string
  defaultOpen: boolean
};

export const PixelPicker = ({
  pixels,
  defaultDspToPixelToWeights,
  goalType,
  defaultOpen,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(defaultOpen);

  const { control } = useFormContext();

  return (
    <Grid.Row style={{ width: '100%' }}>
      <Grid.Column>
        <Segment basic style={GOAL_SECTION_STYLES.pixelPickerSegment}>
          <Controller
            name="goal.impValueFilters"
            control={control}
            render={({ field: { onChange } }) => (
              <PixelPickerModal
                pixels={pixels}
                defaultDspToPixelToWeights={defaultDspToPixelToWeights}
                goalType={goalType}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onChange={onChange}
              />
            )}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

type PixelPickerWrapperProps = {
  state: PixelLoadingState
  goalType: string
  impValueFilters: DspToPixel
};

const PixelPickerWrapper = ({ state, goalType, impValueFilters }: PixelPickerWrapperProps) => {
  const reduxGoalObj = useSelector<GlobalState>((rootState) => rootState.strategyWizard.goalSelectionStep.goal) as { type: string, impValueFilters: DspToPixel };
  const { type: reduxGoalType, impValueFilters: reduxImpValFilters } = reduxGoalObj;
  // set defaultOpen based on if form values are empty or goal type is changed
  const defaultOpen = _.isEmpty(impValueFilters) && (_.isEmpty(reduxImpValFilters) || (reduxGoalType !== goalType));

  switch (state.kind) {
    case PixelStates.initial:
    case PixelStates.loading:
      return (
        // if create mode, automatically open
        <PixelsLoading defaultOpen={defaultOpen} goalType={goalType} />
      );
    case PixelStates.success:
      return (
        <PixelPicker
          pixels={state.pixels}
          defaultDspToPixelToWeights={state.defaultWeights}
          goalType={goalType}
          defaultOpen={defaultOpen}
        />
      );
    case PixelStates.unsupported:
      return <></>;
    default:
      return assertUnreachable(state);
  }
};

export default PixelPickerWrapper;
